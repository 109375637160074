import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { servicioPeticiones } from '@servicios/servicioPeticiones';
import { Router, ActivatedRoute } from '@angular/router';
import { funcionesSesion } from '@funcionesGenerales/funcionesSesion';
import { DatosGeneralesService } from '@servicios/datosGenerales.service';
import { Message } from 'primeng/components/common/api';
import {Validators,FormControl,FormGroup,FormBuilder} from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import {MessageService} from 'primeng/api';
import { Captcha, CaptchaModule } from 'primeng/primeng';
import { ReCaptchaModule } from 'angular2-recaptcha';
import { RecaptchaModule } from 'ng-recaptcha';



@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css'],
    providers: [MessageService]
})
export class LoginComponent implements OnInit {
    private llamado: any = '';      //Contendrá el json con los datos para enviarse al servidor
    private codrespuesta: string = '-1'; //Código de la respuesta
    public mensaje: string = '';   //Mensajes que se mostarán dependiendo de lo que nos devuelva el servidor(apirest) 
    private codigoAcceso: string = '0';    //Codigo Inicial de acceso al abrir la pagina
    private idAcceso: number = 0;   //Id del acceso cuando se solicita llave de acceso
    public tipo: string = '';   //En el caso que se maneejen tipos de formularios para el contrgol de divs
    private contrasena: string = '';   //Contraseña
    private contrasena2: string = '';   //Segunda contraseña que se utiliza en el momento en que se recupera la contraseña
    private recordar: string = 'N';  //Recordar: N o s dependiendo del valor de recordarme
    private recordarme: boolean = false;
    public correo: string = '';   //Correo electrónico para recuperar la contraseña
    private codigoRecuperacion: string;  //Llave de recuperacion que se recibe en el de abrir la url de recuperacion
    public identity: any;           //Variable que contiene los datos de la sesion iniciada
    private formValido: boolean = true;
    public tipoPeticion: string;
    public msgs: Message[] = [];
    public mostrarMensajeRecuperarSesion: boolean;
    public userform: FormGroup;
    public parametrosContrasena:any;
    public loading:boolean = false;
    public imagenes:any=[];
    public cambio:string;
    user: any;
    siteKey: string;
    public evento: string;
    public n : number = 0;
    //@ViewChild('captcha') 
    constructor(private messageService: MessageService,private _http: HttpClient, private _renderer: Renderer2,  private fb: FormBuilder,private _servicioPeticiones: servicioPeticiones, public _servicioDatosGenerales: DatosGeneralesService, private _router: Router, private _route: ActivatedRoute, private _funcionesSesion: funcionesSesion) {}

    ngOnInit() {

        let sistema = this._servicioDatosGenerales.getSistema();
        if(sistema==2){ // Safith
            this.imagenes = [{source:'assets/images/login/bgs1.jpg',title:''},{source:'assets/images/login/bgs2.jpg',title:''},{source:'assets/images/login/bgs3.jpg',title:''}];
        }else if(sistema==3){ // Datapagos
            this.imagenes = [{source:'assets/images/login/bgd1.jpg',title:''},{source:'assets/images/login/bgd2.jpg',title:''},{source:'assets/images/login/bgd3.jpg',title:''}];
        }else{ // Consolidado
            this.imagenes = [{source:'assets/images/login/bg1.jpg',title:''},{source:'assets/images/login/bg2.jpg',title:''},{source:'assets/images/login/bg3.jpg',title:''}];
        }
        this.userform = this.fb.group({
            'username': new FormControl('', Validators.compose([Validators.required, , Validators.pattern('^[_a-zA-Z0-9-]+(\.[_a-zA-Z0-9-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*')])),
            'password': new FormControl('', Validators.compose([Validators.required, Validators.minLength(6)])),
            'recordar': new FormControl(false),
            //'recap': new FormControl(false,Validators.requiredTrue)

        });
        //Tomo los parámetros si los hay
        this._route.params.subscribe(params => {
            this.codigoRecuperacion = params['codigo'];//El código de recuperación se utiliza cuando se recupera contraseña
            this.tipoPeticion = params['tipo'];//S cuando es cerrar sesiones, C recuperar
        });
        this.identity = this._servicioDatosGenerales.getIdentity();//Toma los datos de la sesión 
        //Si recibe un código de recuperación, quiere decir que está ingresando al sistema desde un enlace para recuperación de contraseña, enlace envíado al correo electrónico
        if (this.codigoRecuperacion) {
            if (this.codigoRecuperacion == '-1' || this.codigoRecuperacion == '-2') {//Indica que quiere cerrar sesion -1 Correctamente, -2 incorrectamente
                if (this.identity) {
                    this.cerrarSesion(this.identity.codigoSesion);
                }
            } else {
                if (this.tipoPeticion) {
                    if (this.tipoPeticion == 'S') {
                        this.validarAutorizacion();
                    } else {
                        this.verificarPeticionLlave();
                        this.tipo = 'formNuevaContrasena';
                    }
                } else {
                    this._servicioDatosGenerales.enviarMensaje('E', 'Solicitud inválida');
                }
            }
        } else {//Ingreso al sistema normal
            if (this.identity) {
                this._router.navigate(['/home']);
            } else {
                this.tipo = 'login';
                this.solicitarLlaveLogin();
            }
        }
    }

    reset(){
        this.formValido = this.userform.valid;
        if (!this.formValido) {
           grecaptcha.reset()
        }
    }


    // reset(){
    //     this.formValido = this.userform.valid;
    //     if (!this.userform.controls['password'].valid) {
    //        grecaptcha.reset()
    //     }else if (!this.userform.controls['username'].valid){
    //         grecaptcha.reset()
    //     }
    // }

//Metodo para validar la optención de la llave desde el recaptcha
    showResponse(event){
        //this.messageService.add({severity:'info', summary:'Succees', detail: 'User Responded', sticky: true});
        this.evento =event.response;
        let estado = false;
        if(event.response){
            estado= true;
        }
        this.userform.controls['recap'].setValue(estado);//si el recaptcha es correcto devuelve el estado true y permite el ingreso al formulario ingrear

    }

//Método que se ejecuta al ingresar al sistema y realiza la petición al servicio de autenticación solicitando una llave inicial de acceso
    solicitarLlaveLogin() {
        this.loading = true;
        this.llamado = { "accion": "inicio" }
        this._servicioPeticiones.enviarObjetoBus(this.llamado)
            .subscribe(//suscribe la peticion http para estar pendiente de la respuesta, funciona como un callback
                response => { //si la peticion fue exitosa ejecuta la funcion response
                    let respuesta = response[0];//Obtengo la respuesta del api rest
                    if (respuesta.valores) {
                        if (respuesta.valores.resultado == 0) {//Conexion sin inconvenientes
                            this.codigoAcceso = respuesta.valores.codigo_inicial_acceso;//Obtengo la llave privada
                            this.idAcceso = respuesta.valores.id;
                            let usuario = respuesta.valores.usuario;
                            if (usuario && usuario != '') {
                                this.userform.controls['username'].setValue(usuario);
                                this.userform.controls['recordar'].setValue(true)
                                this.recordar = 'S';
                            }
                        } else {
                            this._servicioDatosGenerales.enviarMensaje('E', respuesta.valores.mensaje);
                        }
                    } else {
                        this._servicioDatosGenerales.enviarMensaje('E', this._servicioDatosGenerales.getMensajeError());
                    }
                    this.loading = false;
                },
                error => {
                    this.codrespuesta = '-1';//Se muestra en -1 para que no muestre campos de inicio se sesion
                    this._servicioDatosGenerales.enviarMensaje('E', 'No se puede tener conexión, por favor recargue el sistema, si el error persiste contáctese con el administrador.');
                    this.loading = false;
                })
    }


    //Función para ingresar al sistema (Iniciar sesion) teniendo en cuenta las credenciales ingresadas
    ingresar(datosUsuario:any) {
        this.formValido = this.userform.valid;
        this.codrespuesta = '-1';
        this.mostrarMensajeRecuperarSesion = false;
        this.mensaje = '';
//this.showResponse(event)
        if (this.formValido) {
            let pass = datosUsuario.password;
            this.formValido = true;
            if (datosUsuario.recordar) { this.recordar = 'S'; } else { this.recordar = 'N'; }
            this.llamado = { "accion": "acceso", parametros: { "id": this.idAcceso, "usuario": datosUsuario.username, "password": this._funcionesSesion.encriptarRsa(pass, this.codigoAcceso), "recordar": this.recordar, "llavecaptcha": this.evento } };
            this._servicioPeticiones.enviarObjetoBus(this.llamado)
                .subscribe( //suscribe la peticion http para estar pendiente de la respuesta, funciona como un callback
                    response => { //si la peticion fue exitosa ejecuta la funcion response
                        let respuesta = response[0];
                        if (respuesta.valores != null) {
                            if (respuesta.valores.resultado == 0 && respuesta.valores.acceso == 'permitido') {
                                this._servicioDatosGenerales.setIdentity(respuesta.valores);//Guardamos la información del loggin en el localstorage
                                this._servicioDatosGenerales.setAyuda(false);
                                //this._servicioPeticiones.setBloqueo(false);//Cambia el estado de bloqueo de pantalla a No
                                // location.reload();
                                this._servicioDatosGenerales.limpiarMensajes();
                                this._router.navigate(['/home']);//Se pone loggin con el fin de que no se muestre la pantalla del dasboard sin animaciones                        
                            } else {
                                this.codigoAcceso = respuesta.valores.codigo_inicial_acceso;
                                this.idAcceso = respuesta.valores.id;
                                this.userform.controls['password'].setValue('');
                                this.mensaje = respuesta.valores.mensaje;
                                if (respuesta.valores.codigoError && respuesta.valores.codigoError == 'A') {//Tiene sesiones activas
                                    this.mostrarMensajeRecuperarSesion = true;
                                } else {
                                    this._servicioDatosGenerales.enviarMensaje('E', respuesta.valores.mensaje);
                                    this.reset();
                                }
                            }
                        } else {
                            this._servicioDatosGenerales.enviarMensaje('E', this._servicioDatosGenerales.getMensajeError());
                        }
                    },
                    error => {//si el API genero un error                    
                        this._servicioDatosGenerales.enviarMensaje('E', this._servicioDatosGenerales.getMensajeError());
                    })
        } 
    }

    //Función que envía la petición para el registro del cierre de sesión
    cerrarSesion(codigoSesion: string) {
        let correcta: boolean = (this.codigoRecuperacion == '-1' ? true : false);
        this.llamado = { "accion": "cerrar", parametros: { "codigoSesion": codigoSesion, "correcta": correcta } };
        this._servicioPeticiones.enviarObjetoBus(this.llamado)
            .subscribe(//suscribe la peticion http para estar pendiente de la respuesta, funciona como un callback
                response => { //si la peticion fue exitosa ejecuta la funcion response
                    let respuesta = response[0];//Obtengo la respuesta del api rest    
                    if (respuesta.valores.resultado != 0) {
                        this.mensaje = respuesta.valores.mensaje;
                    }
                    this._servicioDatosGenerales.deleteIdentity();
                    this._servicioDatosGenerales.deleteAyuda();
                    this.identity = null;
                    this._router.navigate(['/login']);
                },
                error => {
                    this._servicioDatosGenerales.enviarMensaje('E', 'No se puede tener conexión, por favor recargue el sistema, si el error persiste contáctese con el administrador.');
                }
            )
    }

    //Función que envía el correo electrónico al momento de realizar la petición de cambio de contraseña desde el formulario de inicio
    enviarCorreo() {
        this.codrespuesta = '-1';
        this.mensaje = '';
        let tipoCorreo: string;
        if (this.idAcceso != null && this.idAcceso != 0) {
            if (this.correo != '') {
                //Expresión para validar el correo electrónico
                if (this.tipo == 'formRecuperacion') {
                    tipoCorreo = 'C';
                } else {
                    tipoCorreo = 'S';
                }
                let expresion = new RegExp('^[_a-zA-Z0-9-]+(\.[_a-zA-Z0-9-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[A-Za-z]{2,3})$');
                if (expresion.test(this.correo)) {//Valida si segúm la expresión regular está bien escrito el correo
                    this.llamado = { "accion": "enviar_recuperacion", parametros: { "id": this.idAcceso, "correoElectronico": this.correo, tipo: tipoCorreo } };
                    this._servicioPeticiones.enviarObjetoBus(this.llamado)
                        .subscribe( //suscribe la peticion http para estar pendiente de la respuesta, funciona como un callback
                            response => { //si la peticion fue exitosa ejecuta la funcion response
                                let respuesta = response[0];
                                if(respuesta.respuesta=='0'){
                                    this.codrespuesta = respuesta.valores.id;
                                    this._servicioDatosGenerales.enviarMensaje('C', respuesta.valores.mensaje);  
                                }else{
                                    if(respuesta.valores){
                                        this._servicioDatosGenerales.enviarMensaje('A', respuesta.valores.mensaje);
                                    }else{
                                        this._servicioDatosGenerales.enviarMensaje('E', this._servicioDatosGenerales.getMensajeError());
                                    }
                                }
                            },
                            error => {//si el API genero un error   
                                this._servicioDatosGenerales.enviarMensaje('E', 'Error inesperado al enviar el mensaje, por favor contáctese con el administrador del sistema.');
                            }
                        )
                } else {
                    this._servicioDatosGenerales.enviarMensaje('E', 'Debe Ingresar un correo electrónico válido');
                }
            } else {
                this._servicioDatosGenerales.enviarMensaje('E', 'Debe Ingresar un correo electrónico');
            }
        } else {
            this._servicioDatosGenerales.enviarMensaje('E', 'No se puede tener conexión, por favor recargue el sistema, si el error persiste contáctese con el administrador.');
        }
    }

    //Método que se ejecuta al ingresar al sistema después de abrir el enlace del correo y verifica el estado de la solicitud de cambio de contraseña
    verificarPeticionLlave() {
        this.llamado = { "accion": "validar_recuperacion", parametros: { "llave": this.codigoRecuperacion } };
        this._servicioPeticiones.enviarObjetoBus(this.llamado)
            .subscribe(//suscribe la peticion http para estar pendiente de la respuesta, funciona como un callback
                response => { //si la peticion fue exitosa ejecuta la funcion response
                    let respuesta = response[0].valores;//Obtengo la respuesta del api rest
                    //Obtengo el código de respuesta
                    if (respuesta.resultado == 0) {
                        this.codrespuesta = '0';
                        this.mensaje = '';
                        this.codigoAcceso = respuesta.codigo_inicial_acceso;
                        this.idAcceso = respuesta.id;
                        this.parametrosContrasena = respuesta.parametros;
                    } else {//Si hay errores los muestra
                        this.codrespuesta = '-1';
                        this._servicioDatosGenerales.enviarMensaje('E', respuesta.mensaje);
                    }
                },
                error => {
                    this.codrespuesta = '-1';
                    this._servicioDatosGenerales.enviarMensaje('E', 'No se puede tener conexión, por favor recargue el sistema, si el error persiste contáctese con el administrador.');
                }
            )
    }

    //Función que envía la contraseña a cambiar de un usuario que realiza la petición de "olvido su contraseña"
    enviarNuevaContrasena() {
        this.formValido = true;
        this.codrespuesta = '-1';
        this.mensaje = '';
        if (this.contrasena != '' && this.contrasena2 != '') {
            if (this.contrasena == this.contrasena2) {
                this.llamado = { "accion": "recuperar", parametros: { "llave": this.codigoRecuperacion, "id": this.idAcceso, "password": this._funcionesSesion.encriptarRsa(this.contrasena, this.codigoAcceso), "passwordRep": this._funcionesSesion.encriptarRsa(this.contrasena2, this.codigoAcceso) } };
                this._servicioPeticiones.enviarObjetoBus(this.llamado)
                    .subscribe( //suscribe la peticion http para estar pendiente de la respuesta, funciona como un callback
                        response => { //si la peticion fue exitosa ejecuta la funcion response
                            let respuesta = response[0].valores;
                            if (respuesta['resultado'] == '0') {//Recuperación de conraseña sin inconvenientes
                                this.codrespuesta = '0';
                                this.contrasena = '';
                                this.tipo = 'login'
                                this._servicioDatosGenerales.enviarMensaje('C', 'Contraseña cambiada correctamente');
                                this._router.navigate(['/login', '']);
                            } else {
                                this.codigoAcceso = respuesta.codigo_inicial_acceso;
                                this.idAcceso = respuesta.id;
                                if(respuesta['mensaje']){
                                    this._servicioDatosGenerales.enviarMensaje('E', respuesta['mensaje']);
                                }else{
                                    this._servicioDatosGenerales.enviarMensaje('E', 'Ocurrió un error al intentar cambiar la contraseña');
                                }
                                this.codrespuesta = '0';
                            }
                        },
                        error => {
                            this._servicioDatosGenerales.enviarMensaje('E', 'Error inesperado con el inicio de sesion, por favor contáctese con el administrador del sistema');
                            this.codrespuesta = '0';
                        })
            } else {
                this._servicioDatosGenerales.enviarMensaje('E', 'Las contraseñas no coinciden.');
                this.codrespuesta = '0';
            }
        } else {
            this.formValido = false;
        }

    }

    volverInicio() {
        this._router.navigate(['/login', '']);
    }

    //Método que se ejecuta al ingresar al sistema después de abrir el enlace del correo y verifica el estado de la solicitud de cambio de contraseña
    validarAutorizacion() {
        this.llamado = { "accion": "validar_autorizacion", parametros: { "llave": this.codigoRecuperacion } };
        this._servicioPeticiones.enviarObjetoBus(this.llamado)
            .subscribe(//suscribe la peticion http para estar pendiente de la respuesta, funciona como un callback
                response => { //si la peticion fue exitosa ejecuta la funcion response
                    let respuesta = response[0].valores;//Obtengo la respuesta del api rest
                    //Obtengo el código de respuesta
                    if (respuesta && respuesta.resultado == 0) {
                        this.codrespuesta = '0';
                        this._servicioDatosGenerales.enviarMensaje('C', 'Proceso realizado correctamente');
                        this.tipo = 'login';
                        this.solicitarLlaveLogin();
                    } else {//Si hay errores los muestra
                        this.codrespuesta = '-1';
                        this._servicioDatosGenerales.enviarMensaje('E', (respuesta && respuesta.mensaje)? respuesta.mensaje:'Ocurrió un error al realizar el cambio de contraseña');
                    }
                },
                error => {
                    this.codrespuesta = '-1';
                    this._servicioDatosGenerales.enviarMensaje('E', 'No se puede tener conexión, por favor recargue el sistema, si el error persiste contáctese con el administrador.');
                }
            )
    }
}


