import { Component, OnInit, Output, EventEmitter } from '@angular/core';
// import { HomeComponent } from '../../home/home.component';
import { servicioPeticiones } from '@servicios/servicioPeticiones';
import { DatosGeneralesService } from '@servicios/datosGenerales.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
    public jsonmenu:any;//Variable que contendrá el menu completo (El que se genera desde que se inicia sesion)
    public menuAlterno:any;//Menú alterno para mantemer el original cuando se realiza una búsqueda
    public llamado: any; //Variable que contiene los json con el llamado al api-rest
    public mensaje:string = ''; //Mensaje que contiene las respuestas desde el api-rest
    public palabraBusqueda:string = '';//Variable que contiene la palabra que se busca desde el buscador de menu
    public busqueda:boolean = false;//Indica si el menú es resultado de una busqueda o no
    @Output() enviarDatosMenu = new EventEmitter();
  constructor(private _servicioPeticiones: servicioPeticiones, private _servicioDatosGenerales: DatosGeneralesService) { }

    ngOnInit() {
        this.busqueda = false;
        if(this._servicioDatosGenerales.getIdentity()){
            this.cargarJsonMenu();
        }
    }

    //Función encargada de hacer la petición al api-rest para traer el menú a cargar en la interfaz
    cargarJsonMenu(){
        this.llamado = {"accion":"obtenerMenu",parametros:{"codigoSesion":this._servicioDatosGenerales.getIdentity().codigoSesion}};
        this._servicioPeticiones.enviarObjetoBus(this.llamado)
            .subscribe( //suscribe la peticion http para estar pendiente de la respuesta, funciona como un callback
                response => { //si la peticion fue exitosa ejecuta la funcion response
                    let respuesta = response[0].valores;
                    if(respuesta!=null){
                        if(respuesta['resultado']==0){//Si No obtiene problemas al traer el menú
                            this.jsonmenu = respuesta['menu'];
                            this.menuAlterno = this.jsonmenu;
                        }else{
                            this.mensaje = respuesta['mensaje'];                    
                        }
                    }else{
                        this.mensaje = 'No se pude cargar el menú';
                    }                   
                },
                error => {
                    this.mensaje = 'Ocurrió un error inesperado al intentar cargar el menú';
                }
            )
    }

    //Función para traer el menú a como estaba creado inicialmente
    volverMenuInicial() {
        //Valida que la palabra en el  campo de búsqueda este vacío para poder poner el menú inicial
        if(this.palabraBusqueda==''){
            this.jsonmenu = this.menuAlterno;
            this.busqueda=false;
            this.mensaje ='';
        }
    }

    //Función que realiza una consulta a la base de datos de un menu or nombre
    obtenerMenuBusqueda() {
        if(this.palabraBusqueda!=''){
            this.mensaje = '';
            this.busqueda = true;
            this.llamado = {"accion":"buscarMenu",parametros:{"codigoSesion": this._servicioDatosGenerales.getIdentity().codigoSesion,"palabra_clave": this.palabraBusqueda}};
            this._servicioPeticiones.enviarObjetoBus(this.llamado)
            .subscribe( //suscribe la peticion http para estar pendiente de la respuesta, funciona como un callbackEAD
                response => { //si la peticion fue exitosa ejecuta la funcion response
                    let respuesta = response[0].valores;
                    if(respuesta!=null){
                        if(respuesta['resultado']=='0'){//Si encuentra menús con la palabr ingresada
                            this.jsonmenu = respuesta['menu'];         
                        }else{//Si no encuentra menús, genera el menu principal
                            this.mensaje = respuesta['mensaje'];
                            this.jsonmenu =null;
                        }   
                    }else{
                        this.mensaje = 'Ocurrió un error inesperado, por favor contáctese con el administrador del sistema';
                    }                           
                },
                error => {
                    this.mensaje= 'Ocurrió un error inesperado al realizar la búsqueda del menú';
                }
            )
        }

    }

    //Fyunción para obtener el id del menu enviado desde un menu hoja y que envia el valor al componente home
    tomarValorIdMenu(datosMenu){
        this.enviarDatosMenu.emit(datosMenu);
    }
}