import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { RegistroTabla } from '@modelos/registroMultiregistro';
import { campoFormulario } from '@modelos/campo';
import { servicioPeticiones } from '@servicios/servicioPeticiones';
import { DatosGeneralesService } from '@servicios/datosGenerales.service';
import { Message } from 'primeng/components/common/api';

@Component({
    selector: 'app-lista-agregacion-json',
    templateUrl: './lista-agregacion-json.component.html',
    styleUrls: ['./lista-agregacion-json.component.css']
})
export class ListaAgregacionJsonComponent implements OnInit, OnChanges {
    @Input() tablaEstructura: any;
    @Input() valores: any;
    @Input() idFormulario: string;
    @Input() nombreAgregacion: string;
    @Input() tipo: string;//R si es tabla multiregistro, L si es lista de agregación json
    @Input() tablaResultados: any; //Para tipo R
    @Input() camposMensajes: any;
    @Input() permisos: any;
    @Output() enviarValorJson = new EventEmitter();
    @Output() enviarInformacionRegistro = new EventEmitter();
    @Output() enviarSenalEliminacion = new EventEmitter();
    @Output() abrirInterfazFormulario = new EventEmitter();
    @Input() camposPadre: any;
    @Input() idRegistroPadre: string;
    @Input() referidosFormulario:any;// Utilizado en lista de agregación json para obtener datos de campos del formulario y poderlos utilizar en la lista ya sea para recargas, bloqueo de campos entre otros
    @Input() bloqueado:Boolean;
    public filaEditable: string = '-1';
    public abrirDialogoEliminacion: boolean = false;
    public idRegistro: string = '';//Es el mismo valor de filaEditable, pero se utiliza para que en el momento de eliminar, no se habilite el modo edición de la fila
    public ultimoid: number = 0;
    public registros: Array<any> = [];
    public copiaRegistros: string = '';
    public loading: boolean = false;
    public requeridoValido: boolean = true;//Validación de requeridos
    public validacionesCorrectas: boolean = true;
    public msgs: Message[] = [];
    public registro: any;
    public referidosRecarga: Array<string> = [];
    public referidosBloqueo: Array<string> = [];
    constructor(private _servicioPeticiones: servicioPeticiones, private _servicioDatosGenerales: DatosGeneralesService) { }

    ngOnInit() {
        if (this.tipo == 'L') { // Se puso en el init para que no adicione cosas cada que modifique la estructura
            this.obtenerDatosTabla();
        }
    }

    ngOnChanges() {
        if (this.tipo == 'R') { // Formulario tabla multi registro
            this.filaEditable = '-1';
            if (this.camposMensajes) {
                this.llenarMensajesCampos();
            } else {
                this.llenaListaRegistros();
            }
        }
    }

    nuevoRegistro() {
        if (this.tipo != 'R') {//Lista de agregación
            this.insertarRegistroListaAgregacion();
        } else {
            this.insertarRegistroTablaMultiregistro();
        }
    }

    insertarRegistroListaAgregacion() {
        let fila: Array<campoFormulario> = [];
        let referidos:any;
        let debeLlenarReferidos: boolean = (!this.referidosRecarga || this.referidosRecarga.length <= 0);
        let debeLlenarReferidosBloqueo: boolean = !this.referidosBloqueo || this.referidosBloqueo.length <= 0;
        let valorReferido:string='';
        for (let i = 0; i < this.tablaEstructura.campos.length; i++) {
            let campo = new campoFormulario(this.tablaEstructura.campos[i], '', this.tablaEstructura.defectos[i]? this.tablaEstructura.defectos[i]:'');
            //Verificar si se necesitan adicionar referidos
            if(this.tablaEstructura.configuracionRecarga){            
                for (let k = 0; k < this.tablaEstructura.configuracionRecarga.length; k++) {
                    let recarga = this.tablaEstructura.configuracionRecarga[k];
                    if (recarga.columna == i && recarga.referidos && recarga.referidos.length > 0) {
                        referidos = [];
                        for (let l = 0; l < recarga.referidos.length; l++) {
                            valorReferido = this.tieneValorDesdeFormularioHermano(recarga.referidos[l]);
                            referidos.push(new campoFormulario(recarga.referidos[l], '', valorReferido));
                            if (debeLlenarReferidos) {
                                this.referidosRecarga.push(recarga.referidos[l]);
                            }
                        }
                        campo.referidos = referidos;
                    }
                }
            }

            //Referidos de bloqueo
            if(this.tablaEstructura.configuracionBloquea){
                for (let k = 0; k < this.tablaEstructura.configuracionBloquea.length; k++) {
                    let bloquea = this.tablaEstructura.configuracionBloquea[k];
                    if (bloquea.columna == i && bloquea.referidos && bloquea.referidos.length > 0) {
                        referidos = [];
                        for (let l = 0; l < bloquea.referidos.length; l++) {
                            valorReferido = this.tieneValorDesdeFormularioHermano(bloquea.referidos[l]);
                            referidos.push(new campoFormulario(bloquea.referidos[l], '', valorReferido));
                            if (debeLlenarReferidosBloqueo) {
                                this.referidosBloqueo.push(bloquea.referidos[l]);
                            }
                        }
                        campo.referidosBloqueo = referidos;
                    }
                }
            }
            fila.push(campo);
        }        
        this.registro = new RegistroTabla(this.ultimoid, fila, '', null, null);
        this.registros.unshift(this.registro);
        this.filaEditable = '' + this.ultimoid;
        this.ultimoid++;
        // Se ejecuta la recarga al final ya para ejecutar recargas deben estar los campos renderizados
        for(let i = 0; i<this.registros[0].campos.length; i++){
            let campo = this.registros[0].campos[i];
            if(campo.valor){// Tiene un valor por defecto
                // this.ejecutarFuncionesCampos();
                this.ejecutarFuncionesCampos({recarga:this.tablaEstructura.recarga[i], bloquea:this.tablaEstructura.bloquea[i],campo:campo, idReg:this.filaEditable,idTabla:''});
            }
        }
        this.enviarValorJson.emit('-1');        
    }

    //Función para verificar si hay referidos que vienen desde el mismo formulario en el que se encuentra la lista 'Q'
    tieneValorDesdeFormularioHermano(campo: string) {
        let valor: string = '';
        if(this.referidosFormulario){
            let i = 0;
            while (i < this.referidosFormulario.length) {
                if (this.referidosFormulario[i].nombre == campo) {
                    valor = this.referidosFormulario[i].valor;
                    i = this.referidosFormulario.length;
                }
                i++;
            }
        }        
        return valor;
    }

    insertarRegistroTablaMultiregistro() {
        let fila: Array<campoFormulario> = [];
        let campo: campoFormulario;
        let referidos:any;
        let debeLlenarReferidos: boolean = (!this.referidosRecarga || this.referidosRecarga.length <= 0);
        let debeLlenarReferidosBloqueo: boolean = !this.referidosBloqueo || this.referidosBloqueo.length <= 0;
        let valorReferido: string;
        for (let i = 0; i < this.tablaEstructura.campos.length; i++) {//LLena los campos el registro
            let valorCampo = this.tablaEstructura.valoresDefecto[i];
            campo = new campoFormulario(this.tablaEstructura.campos[i], '', valorCampo); //Aca debo validar los valores por defecto
            for (let k = 0; k < this.tablaEstructura.configuracionRecarga.length; k++) {
                let recarga = this.tablaEstructura.configuracionRecarga[k];
                if (recarga.columna == i && recarga.referidos && recarga.referidos.length > 0) {
                    referidos = [];
                    for (let l = 0; l < recarga.referidos.length; l++) {
                        valorReferido = this.tieneValorDesdePadre(recarga.referidos[l]);
                        referidos.push(new campoFormulario(recarga.referidos[l], '', valorReferido));
                        if (debeLlenarReferidos) {
                            this.referidosRecarga.push(recarga.referidos[l]);
                        }
                    }
                    campo.referidos = referidos;
                }
            }
            //Referidos de bloqueo
            for (let k = 0; k < this.tablaEstructura.configuracionBloquea.length; k++) {
                let bloquea = this.tablaEstructura.configuracionBloquea[k];
                if (bloquea.columna == i && bloquea.referidos && bloquea.referidos.length > 0) {
                    referidos = [];
                    for (let l = 0; l < bloquea.referidos.length; l++) {
                        valorReferido = this.tieneValorDesdePadre(bloquea.referidos[l]);
                        referidos.push(new campoFormulario(bloquea.referidos[l], '', valorReferido));
                        if (debeLlenarReferidosBloqueo) {
                            this.referidosBloqueo.push(bloquea.referidos[l]);
                        }
                    }
                    campo.referidosBloqueo = referidos;
                }
            }
            fila.push(campo);
            // this.verificarFuncionesCampo({recarga:this.tablaEstructura.recarga[i], bloquea:this.tablaEstructura.bloquea[i],campo:campo, idTabla:''});
        }
        this.registro = new RegistroTabla(this.ultimoid, fila, '', null, null);
        this.registros.unshift(this.registro);
        this.filaEditable = '' + this.ultimoid;
        this.ultimoid++;
        //Recorro los campos para verificar las recargas. esto se hace después de insertar todos los campos ya que si no , no existirían campos para recargar
        for(let i = 0; i<this.registro.campos.length;i++){
            this.verificarFuncionesCampo({recarga:this.tablaEstructura.recarga[i], bloquea:this.tablaEstructura.bloquea[i],campo:campo, idTabla:''});
        }        
    }

    //Función para verificar si hay referidos que vienen desde el formulari padre
    tieneValorDesdePadre(campo: string) {
        let valor: string = '';
        let i = 0;
        while (i < this.camposPadre.length) {
            if (this.camposPadre[i].nombre == campo) {
                valor = this.camposPadre[i].valor;
                i = this.camposPadre.length;
            }
            i++;
        }
        return valor;
    }

    editarRegistroTabla(registro: any) {
        this.filaEditable = registro.id;
        if (this.tipo != 'R') { // Lista
            this.enviarValorJson.emit('-1');
            this.obtenerFuncionesListaAgregacionRegistro(registro);
        } else {
            this.registro = registro;
            this.verificarOperacionesRegistro(registro);
        }
    }

    obtenerFuncionesListaAgregacionRegistro(registro:any){
        for(let i = 0; i<registro.campos.length; i++){
            let campo = registro.campos[i];
            if(campo.valor){// Tiene un valor por defecto
                // this.ejecutarFuncionesCampos();
                this.ejecutarFuncionesCampos({recarga:this.tablaEstructura.recarga[i], bloquea:this.tablaEstructura.bloquea[i],campo:campo, idReg:this.filaEditable,idTabla:''});
            }
        }
    }

    //Función que verifica recargas o bloqueos de campo al editar un registro
    verificarOperacionesRegistro(registro) {
        this.loading = true;
        let llamado = { "accion": "busquedaRegistroUnico", parametros: { "codigoSesion": this._servicioDatosGenerales.getIdentity().codigoSesion, "formulario": this.idFormulario, "campos": registro.campos, "identificador": registro.idTabla, "identificadorPadre": this.idRegistroPadre } };
        this._servicioPeticiones.enviarObjetoBus(llamado)
            .subscribe( //suscribe la peticion http para estar pendiente de la respuesta, funciona como un callback
                response => { //si la peticion fue exitosa ejecuta la funcion response
                    let respuesta = response;
                    let codigoPaso;
                    let resultado;
                    for (let i = 0; i < respuesta.length; i++) {
                        resultado = respuesta[i].valores;
                        if (resultado && resultado.resultado == 0) {
                            codigoPaso = respuesta[i].codigPaso;
                            if (codigoPaso == 'I') {//Recargar campos

                            } else if (codigoPaso == '2') {//Recargar campos
                                if (resultado.campos && resultado.campos.length > 0) {
                                    this.asignarValoresRecarga(registro, resultado.campos, false);
                                }
                            } else if (codigoPaso == '3') {//Bloquear ocultar
                                if (resultado.campos && (resultado.campos.bloquear && resultado.campos.bloquear.length>0) || resultado .campos && resultado.campos.desbloquear && resultado.campos.desbloquear.length>0) {
                                    this.cambiarPropiedadesBloqueoOculto(resultado.campos, registro);
                                }
                            } else {
                                i = 2;
                                this._servicioDatosGenerales.enviarMensaje('E', resultado.mensaje);
                                this.loading = false;
                            }
                        } else {
                            this._servicioDatosGenerales.enviarMensaje('E', this._servicioDatosGenerales.getMensajeError());
                        }
                        this.loading = false;
                    }
                },
                error => {
                    this.loading = false;
                    this._servicioDatosGenerales.enviarMensaje('E', this._servicioDatosGenerales.getMensajeError());
                }
            )
    }

    eliminarRegistroJson(registro: any) {// Quité este parámetro ,pos:number al realizar tipo R
        if (this.tipo != 'R') {
            this.idRegistro = registro.id;
        } else {
            this.idRegistro = registro.idTabla;
        }
        this.abrirDialogoEliminacion = true;
    }

    eliminarRegistroConfirmado() {
        if (this.tipo != 'R') {
            for (let i = 0; i < this.registros.length; i++) {
                if (this.registros[i].id == this.idRegistro) {
                    this.registros.splice(i, 1);
                    this.copiaRegistros = JSON.stringify(this.registros);
                    this.construirJsonFinal();
                }
            }
        } else {
            this.enviarSenalEliminacion.emit(this.idRegistro);
        }
        this.abrirDialogoEliminacion = false;
    }

    cancelarEdicionRegistro() {
        if (this.copiaRegistros != '') {
            this.registros = JSON.parse(this.copiaRegistros);
        } else {
            this.registros = [];
        }
        this.filaEditable = '-1';
        if (this.tipo != 'R') {
            this.enviarValorJson.emit('');
        }
    }

    confirmarCambioRegistro() {
        if (this.validacionesCorrectas) {
            this.requeridoValido = true;
            this.validarDatosFila();
            if (this.requeridoValido) {
                if (this.tipo != 'R') {
                    this.filaEditable = '-1';
                    this.copiaRegistros = JSON.stringify(this.registros);
                    this.construirJsonFinal();
                } else {
                    this.camposMensajes = null;
                    this.enviarInformacionRegistro.emit(this.registro);//Se envía para realizar la inserción o actualizaicon del registro
                }
            } else {
                this._servicioDatosGenerales.enviarMensaje('A', 'Hay campos en la tabla que no se diligenciaron');
            }
        } else {
            this._servicioDatosGenerales.enviarMensaje('E', 'Tiene errores en la tabla por solucionar');
        }
    }

    validarDatosFila() {
        for (let i = 0; i < this.registros.length; i++) {
            if (this.registros[i].id == this.filaEditable) {
                for (let j = 0; j < this.registros[i].campos.length; j++) {
                    this.registros[i].campos[j].etiqueta = '';
                    if (this.tablaEstructura.requeridos[j] == 'S' && this.tablaEstructura.tipos[j] != 'H' && !this.registros[i].campos[j].valor && this.registros[i].campos[j].valor !== 0) { // toco valodar el cero con doble igual por que en numericos reconoce el creo como nulo
                        this.requeridoValido = false;
                        if (this.tipo == 'R' && this.tablaEstructura.mensajesValidacion && this.tablaEstructura.mensajesValidacion[j] != '') {
                            this.registros[i].campos[j].etiqueta = this.tablaEstructura.mensajesValidacion[j];
                        } else {
                            if (this.tablaEstructura.tipos[j] == 'S' || this.tablaEstructura.tipos[j] == 'C') {
                                this.registros[i].campos[j].etiqueta = 'Debe seleccionar una opción';
                            } else {
                                this.registros[i].campos[j].etiqueta = 'Debe Ingresar un valor para el campo';
                            }
                        }

                    }
                }
                i = this.registros.length;
            }
        }
    }

    //Método que construye el json que se almacena en la base de datos
    construirJsonFinal() {
        let json: Array<any> = new Array();
        for (let i = 0; i < this.registros.length; i++) {
            let fila: string = "{";
            for (let j = 0; j < this.registros[i].campos.length; j++) {
                // let llave:string = this.tablaEstructura.campos[j];
                fila += "\"" + this.tablaEstructura.campos[j] + "\":\"" + this.registros[i].campos[j].valor + "\"";
                if (j < this.registros[i].campos.length - 1) {
                    fila += ",";
                }
            }
            fila += "}"
            json.push(JSON.parse(fila));
        }
        if (this.tipo != 'R') {
            this.enviarValorJson.emit(JSON.stringify(json));
        }
    }

    //Función que Construye la estructura adecuada del array para poder renderizarla en la tabla
    obtenerDatosTabla() {
        let fila: Array<campoFormulario> = [];
        if (this.valores && this.valores != '') {
            this.valores = JSON.parse(this.valores);//Parseo a json 
            for (let i = 0; i < this.valores.length; i++) {
                for (let j = 0; j < this.tablaEstructura.campos.length; j++) {
                    fila.push(new campoFormulario(this.tablaEstructura.campos[j], '', this.valores[i][this.tablaEstructura.campos[j]]));
                }
                this.registros.push(new RegistroTabla(this.ultimoid, fila, '', null, null));
                this.ultimoid++;
                fila = [];
            }
        }
        this.copiaRegistros = JSON.stringify(this.registros);
    }

    //Función que recibe la señal de los campos para ejecutar acciones y validaciones
    ejecutarFuncionesCampos(datos: any) {
        if (this.tipo == 'R') {
            this.verificarFuncionesCampo(datos);
        } else { // Lista de agregación
            if (datos.recarga == 'S') {
                this.verificarFuncionCampoLista(datos);
                // this.enviarPeticionRecargaCampos(datos);
            }
        }
        this.validacionesCorrectas = true;
        if (datos.unico == 'S') {
            this.validarUnicosEstructura(datos);
        }
        if (Number.parseInt(datos.maximo) > 0 && this.validacionesCorrectas) {
            this.validarMaximos(datos);
        }
    }

    verificarFuncionesCampo(datos: any) {
        if (datos.campo.valor != '' && datos.recarga == 'S' && (!datos.campo.referidos || datos.campo.referidos.length < 1 || this.referidosRecargaLlenos(datos))) {
            this.recargarCamposFormulario(datos);
        }
        if (this.esReferido(datos.campo, this.referidosRecarga)) {
            let campos = this.asignarValorReferido(datos);
            for (let i = 0; i < campos.length; i++) {
                this.verificarFuncionesCampo(campos[i]);
            }
        }
        this.ejecutarFuncionesBloqueo(datos);
    }

    ejecutarFuncionesBloqueo(datos: any) {
        if (datos.bloquea == 'S' && (!datos.campo.referidosBloqueo || datos.campo.referidosBloqueo.length < 1 || this.referidosBloqueoLlenos(datos))) {
            this.cargarDatosBloqueoOcultar(datos);
        }
        if (this.esReferido(datos.campo, this.referidosBloqueo)) {
            let campos = this.asignarValorReferidoBloqueo(datos);
            for (let i = 0; i < campos.length; i++) {
                this.cargarDatosBloqueoOcultar(campos[i]);
            }
        }
    }

    referidosRecargaLlenos(datos: any) {
        let estanLlenos: boolean = true;
        let i = 0;
        while (estanLlenos && i < datos.campo.referidos.length) {
            if (datos.campo.referidos[i].nombre != 'idsesi' && datos.campo.referidos[i].nombre != 'idusua' && datos.campo.referidos[i].nombre != 'idempresa' && (!datos.campo.referidos[i].valor || datos.campo.referidos[i].valor == '')) {
                for (let j = 0; j < this.registros[datos.idReg].campos.length; j++) {
                    if (datos.campo.referidos[i].nombre == this.registros[datos.idReg].campos[j].nombre) {
                        if (this.registros[datos.idReg].campos[j].valor && this.registros[datos.idReg].campos[j].valor != '') {
                            datos.campo.referidos[i].valor = this.registros[datos.idReg].campos[j].valor;
                        } else {
                            estanLlenos = false;
                        }
                    }
                }
            }
            i++;
        }
        return estanLlenos;
    }

    referidosBloqueoLlenos(datos: any) {
        let estanLlenos: boolean = true;
        let i = 0;
        while (estanLlenos && i < datos.campo.referidosBloqueo.length) {
            if (datos.campo.referidosBloqueo[i].nombre != 'idsesi' && datos.campo.referidosBloqueo[i].nombre != 'idusua' && datos.campo.referidosBloqueo[i].nombre != 'idempresa' && (!datos.campo.referidosBloqueo[i].valor || datos.campo.referidosBloqueo[i].valor == '')) {
                for (let j = 0; j < this.registros[datos.idReg].campos.length; j++) {
                    if (datos.campo.referidosBloqueo[i].nombre == this.registros[datos.idReg].campos[j].nombre) {
                        if (this.registros[datos.idReg].campos[j].valor && this.registros[datos.idReg].campos[j].valor != '') {
                            datos.campo.referidosBloqueo[i].valor = this.registros[datos.idReg].campos[j].valor;
                        } else {
                            estanLlenos = false;
                        }
                    }
                }
            }
            i++;
        }
        return estanLlenos;
    }
    esReferido(campo: any, arrayReferidos: any) {
        let esReferido: boolean = false;
        if (arrayReferidos && arrayReferidos.length > 0) {
            let i = 0;
            while (i < arrayReferidos.length) {
                if (arrayReferidos[i] == campo.nombre) {
                    esReferido = true;
                    i = arrayReferidos.length;
                }
                i++;
            }
        }
        return esReferido;
    }

    asignarValorReferido(datos: any) {
        let llenos = 0;
        let listaCamposRecargar: Array<any> = [];
        for (let i = 0; i < this.registros[datos.idReg].campos.length; i++) {
            if (this.registros[datos.idReg].campos[i].referidos && this.registros[datos.idReg].campos[i].referidos.length > 0) {
                for (let j = 0; j < this.registros[datos.idReg].campos[i].referidos.length; j++) {
                    if (this.registros[datos.idReg].campos[i].referidos[j].nombre == datos.campo.nombre) {
                        this.registros[datos.idReg].campos[i].referidos[j].valor = datos.campo.valor;
                    }
                    if (this.registros[datos.idReg].campos[i].referidos[j].valor != '' || this.registros[datos.idReg].campos[i].referidos[j].nombre == 'idsesi' || this.registros[datos.idReg].campos[i].referidos[j].nombre == 'idusua' || this.registros[datos.idReg].campos[i].referidos[j].nombre == 'idempresa') {
                        llenos++;
                    }
                }
                if (this.registros[datos.idReg].campos[i].referidos.length == llenos) {
                    listaCamposRecargar.push({ campo: this.registros[datos.idReg].campos[i], recarga: this.tablaEstructura.recarga[i], bloquea: this.tablaEstructura.bloquea[i], posicion: i, idReg: datos.idReg, idTabla: datos.idTabla });
                }
            }
        }
        return listaCamposRecargar;
    }

    asignarValorReferidoBloqueo(datos: any) {
        let llenos: number = 0;
        let listaCamposBloquear: Array<any> = [];
        for (let i = 0; i < this.registros[datos.idReg].campos.length; i++) {
            if (this.registros[datos.idReg].campos[i].referidosBloqueo && this.registros[datos.idReg].campos[i].referidosBloqueo.length > 0) {
                for (let j = 0; j < this.registros[datos.idReg].campos[i].referidosBloqueo.length; j++) {
                    if (this.registros[datos.idReg].campos[i].referidosBloqueo[j].nombre == datos.campo.nombre) {
                        this.registros[datos.idReg].campos[i].referidosBloqueo[j].valor = datos.campo.valor;
                    }
                    if (this.registros[datos.idReg].campos[i].referidosBloqueo[j].valor != '' || this.registros[datos.idReg].campos[i].referidosBloqueo[j].nombre == 'idsesi' || this.registros[datos.idReg].campos[i].referidosBloqueo[j].nombre == 'idusua' || this.registros[datos.idReg].campos[i].referidosBloqueo[j].nombre == 'idempresa') {
                        llenos++;
                    }
                }
                if (this.registros[datos.idReg].campos[i].referidosBloqueo.length == llenos) {
                    listaCamposBloquear.push({ campo: this.registros[datos.idReg].campos[i], recarga: this.tablaEstructura.recarga[i], bloquea: this.tablaEstructura.bloquea[i], posicion: i, idReg: datos.idReg, idTabla: datos.idTabla });
                }
            }
        }
        return listaCamposBloquear;
    }


    verificarFuncionCampoLista(datos:any){
        if (datos.campo.valor != '' && datos.recarga == 'S' && (!datos.campo.referidos || datos.campo.referidos.length < 1 || this.referidosRecargaLlenos(datos))) {
            this.enviarPeticionRecargaCampos(datos);
        }
        if (this.esReferido(datos.campo, this.referidosRecarga)) {
            let campos = this.asignarValorReferido(datos);
            for (let i = 0; i < campos.length; i++) {
                this.verificarFuncionesCampo(campos[i]);
            }
        }
        // this.ejecutarFuncionesBloqueo(datos);
    }

    enviarPeticionRecargaCampos(datos: any) {
        this.loading = true;
        let llamado = { "accion": "recargarAgregacion", parametros: { "formulario": this.idFormulario, "codigoSesion": this._servicioDatosGenerales.getIdentity().codigoSesion, "nombreAgregacion": this.nombreAgregacion, "campo": datos.campo.nombre, "valor": datos.campo.valor, "referidos": datos.campo.referidos } };
        this._servicioPeticiones.enviarObjetoBus(llamado)
            .subscribe( //suscribe la peticion http para estar pendiente de la respuesta, funciona como un callback
                response => { //si la peticion fue exitosa ejecuta la funcion response
                    let respuesta = response[0].valores;
                    if (respuesta.resultado == 0) {//Si No obtiene problemas 
                        this.asignarValoresRecarga(datos, respuesta.campos, true);
                        this.loading = false;
                    } else {//Quiere decir que algún campo contiene errores
                        this._servicioDatosGenerales.enviarMensaje('E', respuesta.mensaje);
                        this.loading = false;
                    }
                },
                error => {
                    this.loading = false;
                    this._servicioDatosGenerales.enviarMensaje('E', 'Ocurrió un error al intentar recargar los datos, por favor contáctese con el administrador del sistema');
                }
            )
    }

    asignarValoresRecarga(datos: any, camposRecargar: any, debeEjecutarRecarga: boolean) {
        let i = 0;
        while (i < this.registros.length) {
            if ((this.tipo != 'R' && this.registros[i].id == datos.idReg) || (this.tipo == 'R' && this.registros[i].idTabla == datos.idTabla)) {
                for (let j = 0; j < camposRecargar.length; j++) {
                    for (let k = 0; k < this.registros[i].campos.length; k++) {
                        if (this.registros[i].campos[k].nombre == camposRecargar[j].nombre) {
                            if(camposRecargar[j].naturaleza.toUpperCase()=='OPCIONES'){
                                this.registros[i].campos[k].opciones = camposRecargar[j].opciones;
                            }else{
                                this.registros[i].campos[k].valor = camposRecargar[j].valor;
                                if(this.registros[i].campos[k].opciones && this.registros[i].campos[k].opciones.length>0){
                                    this.registros[i].campos[k].contenido = this.ponerValorContenidoCampo(this.registros[i].campos[k].opciones, this.registros[i].campos[k].valor); 
                                }
                                if(this.tipo=='L' && this.tablaEstructura.maximoTotal[k] && this.tablaEstructura.maximoTotal[k]>0){
                                    let campo = { maximo:this.tablaEstructura.maximoTotal[k],campo: this.registros[i].campos[k], posicion: k, idReg: this.registros[i].id, idTabla: this.registros[i].idTabla };
                                    this.validarMaximos(campo);
                                }
                            }
                            if (this.tipo == 'R' && debeEjecutarRecarga) {//Formulario tabla multiregistro
                                this.verificarFuncionesCampo({ recarga: this.tablaEstructura.recarga[k], bloquea: this.tablaEstructura.bloquea[k], unico: this.tablaEstructura.unicos[k], campo: this.registros[i].campos[k], posicion: k, idReg: this.registros[i].id, idTabla: this.registros[i].idTabla })
                            }
                        }else{
                            if ((camposRecargar[j].nombre== 'mensaje_error' || camposRecargar[j].nombre == 'mensaje_alerta' || camposRecargar[j].nombre == 'mensaje_exito' || camposRecargar[j].nombre == 'mensaje_informacion') && camposRecargar[j].valor && camposRecargar[j].valor != '') {
                                this._servicioDatosGenerales.mostrarMensajePersonalizado(camposRecargar[j].nombre, camposRecargar[j].valor);
                            }
                        }
                    }
                }
                i = this.registros.length;
            }
            i++;
        }
        this.loading = false;
    }

    /**Función que pone el contenido a los campos que manejen opciones; ejemplo selects */
    ponerValorContenidoCampo(opciones:any[], valor:string){
        let contenido:any = null;
        let i = 0;
        while(i<opciones.length){
            if(opciones[i].id == valor){
                contenido = opciones[i];
                i = opciones.length;
            }
            i++;    
        }
        return contenido;
    }

    validarUnicosEstructura(datos: any) {
        let pos = -1;//Indica la posición del registro que estoy modificando, para cuando estoy en un nivel mas arriba y me encuentro el error, no tenerme que recorrer de nuevo la estructura
        for (let i = 0; i < this.registros.length; i++) {
            this.registros[i].campos[datos.posicion].etiqueta = '';
            if (this.registros[i].id != datos.idReg) {
                if (this.validacionesCorrectas && this.registros[i].campos[datos.posicion].valor == datos.campo.valor) {
                    this.validacionesCorrectas = false;
                    if (pos >= 0) {
                        i = this.registros.length;
                        this.registros[pos].campos[datos.posicion].etiqueta = 'El valor ingresado para la columna ya se encuentra registrado en la tabla y debe ser único'
                    }
                }
            } else {
                pos = i;
                if (!this.validacionesCorrectas) {
                    i = this.registros.length;
                    this.registros[pos].campos[datos.posicion].etiqueta = 'El valor ingresado para la columna ya se encuentra registrado en la tabla y debe ser único'
                }
            }
        }
    }

    validarMaximos(datos: any) {
        let suma: number = 0;
        let maximo: number = Number.parseInt(datos.maximo);
        let pos: number = -1;
        for (let i = 0; i < this.registros.length; i++) {
            this.registros[i].campos[datos.posicion].etiqueta = '';
            if (this.registros[i].id == datos.idReg) {
                pos = i;
            }
            suma += Number.parseInt(this.registros[i].campos[datos.posicion].valor);
            if (suma > maximo) {
                this.validacionesCorrectas = false;
            }
        }
        if (!this.validacionesCorrectas) {
            this.registros[pos].campos[datos.posicion].etiqueta = 'El valor ingresado supera el total máximo permitido para la columna';
        }
    }

    llenaListaRegistros() {
        this.registros = [];
        this.copiaRegistros = '';
        let fila: Array<campoFormulario> = [];
        let idEnTabla: string;
        let campo: campoFormulario;
        let referidos: Array<campoFormulario>;
        let valorReferido: string;
        if (this.tablaResultados.registros) {
            for (let i = 0; i < this.tablaResultados.registros.length; i++) {
                fila.push(new campoFormulario(this.tablaEstructura.campos[0], '', this.tablaResultados.registros[i].id));// Importante, el campo que viene en la posición cero, debe ser el id del registro
                idEnTabla = this.tablaResultados.registros[i].id;
                for (let j = 0; j < this.tablaResultados.registros[i].datos.length; j++) {
                    campo = new campoFormulario(this.tablaEstructura.campos[j + 1], '', this.tablaResultados.registros[i].datos[j]);
                    for (let k = 0; k < this.tablaEstructura.configuracionRecarga.length; k++) {
                        let recarga = this.tablaEstructura.configuracionRecarga[k];
                        if (recarga.columna == j + 1 && recarga.referidos && recarga.referidos.length > 0) { // j+1 teniendo en cuenta que en la posicion 0 va el id
                            referidos = [];
                            for (let l = 0; l < recarga.referidos.length; l++) {
                                valorReferido = this.tieneValorDesdePadre(recarga.referidos[l]);
                                referidos.push(new campoFormulario(recarga.referidos[l], '', valorReferido));
                                if (i == 0) {
                                    this.referidosRecarga.push(recarga.referidos[l]);
                                }
                            }
                            campo.referidos = referidos;
                        }
                    }
                    for (let k = 0; k < this.tablaEstructura.configuracionBloquea.length; k++) {
                        let bloquea = this.tablaEstructura.configuracionBloquea[k];
                        if (bloquea.columna == j + 1 && bloquea.referidos && bloquea.referidos.length > 0) {
                            referidos = [];
                            for (let l = 0; l < bloquea.referidos.length; l++) {
                                valorReferido = this.tieneValorDesdePadre(bloquea.referidos[l]);
                                referidos.push(new campoFormulario(bloquea.referidos[l], '', valorReferido));
                                if (i == 0) {
                                    this.referidosBloqueo.push(bloquea.referidos[l]);
                                }
                            }
                            campo.referidosBloqueo = referidos;
                        }
                    }
                    campo.valorReferidoColumna = this.tablaResultados.registros[i].valorReferidoColumna[j];
                    fila.push(campo)
                }
                this.registros.push(new RegistroTabla(this.ultimoid, fila, idEnTabla, this.tablaResultados.registros[i].editable, this.tablaResultados.registros[i].eliminable));
                this.ultimoid++;
                fila = [];
            }
            this.copiaRegistros = JSON.stringify(this.registros);
        }
    }

    llenarMensajesCampos() {
        for (let i = 0; i < this.camposMensajes.length; i++) {
            for (let j = 0; j < this.registro.campos.length; j++) {
                if (this.camposMensajes[i].nombre == this.registro.campos[j].nombre) {
                    this.registro.campos[j].etiqueta = this.camposMensajes[i].observaciones;
                }
            }
        }
        this.filaEditable = this.registro.id;
    }

    abrirFormulario(registro: any) {
        this.abrirInterfazFormulario.emit(registro)
    }

    recargarCamposFormulario(parametro: any) {
        this.loading = true;
        let llamado = { "accion": "recargar", parametros: { "codigoSesion": this._servicioDatosGenerales.getIdentity().codigoSesion, "formulario": this.idFormulario, "campo": parametro.campo.nombre, "valor": parametro.campo.valor, "referidos": parametro.campo.referidos } };
        this._servicioPeticiones.enviarObjetoBus(llamado)
            .subscribe( //suscribe la peticion http para estar pendiente de la respuesta, funciona como un callback
                response => { //si la peticion fue exitosa ejecuta la funcion response
                    let respuesta = response[0].valores;
                    if (respuesta.resultado == 0) {//Si No obtiene problemas
                        if (respuesta.campos) {
                            this.asignarValoresRecarga(parametro, respuesta.campos, true);
                        }
                    }
                    this.loading = false;
                },
                error => {
                    this.loading = false;
                    this._servicioDatosGenerales.enviarMensaje('E', this._servicioDatosGenerales.getMensajeError());
                }
            )
    }

    //Petición para tomar los datos de los fist a ocultar y campos a bloquear
    cargarDatosBloqueoOcultar(datos: any) {
        // this.loading = true;
        let llamado = { "accion": "bloquearOcultar", parametros: { "codigoSesion": this._servicioDatosGenerales.getIdentity().codigoSesion, "formulario": this.idFormulario, "campo": datos.campo.nombre, "valor": datos.campo.valor, "referidos": datos.campo.referidosBloqueo } };
        this._servicioPeticiones.enviarObjetoBus(llamado)
            .subscribe( //suscribe la peticion http para estar pendiente de la respuesta, funciona como un callback
                response => { //si la peticion fue exitosa ejecuta la funcion response
                    let respuesta = response[0].valores;
                    if (respuesta.resultado == 0) {//Si No obtiene problemas al traer el menú
                        this.cambiarPropiedadesBloqueoOculto(respuesta.campos, datos)
                    }
                    // this.loading = false;
                },
                error => {
                    // this.loading = false;
                }
            )
    }

    cambiarPropiedadesBloqueoOculto(campos: any, datos: any) {
        let k = 0;
        while (k < this.registros.length) {
            if ((this.tipo != 'R' && this.registros[k].id == datos.idReg) || (this.tipo == 'R' && this.registros[k].idTabla == datos.idTabla)) {
                if (campos.bloquear && campos.bloquear.length > 0) {
                    for (let i = 0; i < campos.bloquear.length; i++) {
                        for (let j = 0; j < this.registros[k].campos.length; j++) {
                            if (campos.bloquear[i] == this.registros[k].campos[j].nombre) {
                                this.registros[k].campos[j].deshabilitado = true;
                            }
                        }
                    }
                }
                if (campos.desbloquear && campos.desbloquear.length > 0) {
                    for (let i = 0; i < campos.desbloquear.length; i++) {
                        for (let j = 0; j < this.registros[k].campos.length; j++) {
                            if (campos.desbloquear[i] == this.registros[k].campos[j].nombre) {
                                this.registros[k].campos[j].deshabilitado = false;
                            }
                        }
                    }
                }
                k = this.registros.length;
            }
            k++;
        }
    }
}

