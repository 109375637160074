import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { servicioPeticiones } from '@servicios/servicioPeticiones';
import { DatosGeneralesService } from '@servicios/datosGenerales.service';
import { OperacionesService } from '@servicios/operaciones.service';
import { campoFormulario } from '@modelos/campo';
import { Parametro } from '@modelos/parametro';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
    selector: 'app-formulario-tabla',
    templateUrl: './formulario-tabla.component.html',
    styleUrls: ['./formulario-tabla.component.css']
})

export class FormularioTablaComponent implements OnInit, OnChanges {
    @Input() jsonFormulario: any;//Json de la estructura del formulario que se utilizará al crear un registro o modificarlo
    @Input() idFormulario: string;//Id del formulario que se abre
    @Input() tablaResultados: any;//Tabla a pintar con sus registros
    @Input() idRegistroPadre: string;//Id del registro padre
    @Input() mensajeEntrada: string;
    @Input() camposPadre: any;//Campos con valores que vienen desde el formulario padre para tomar vsalores en los campos del hijo
    @Input() idmenu: string;
    @Input() origen: string;// Bandera que me indicará si se abre la tabla desde el dialogo(D) o normal, esto para quitar los divs con los filtros (MIRAR SI SE PUEDE QUITAR DESPUES CUANDO SE VALIDEN LOS ESPACIOS EN LOS DIVS DEPENDIENDO DE LA CONFIGURACIÓN)
    @Input() camposDialogo: campoFormulario[]; // Campos que vienen desde un dialogo cuando el origen es D (SIempre debe ir en los filtros de busqueda). 


    @Input() tabRelacionado:any;
    @Input() tabAbierto:any;
    @Output() enviarValoresCheck = new EventEmitter();
    @Output() enviarSenalCampoRecargaPadre = new EventEmitter();
    @Output() enviarActulizacionRegistros = new EventEmitter();
    public tablaResultadosForm: any;
    public abrirFormularioHijo: boolean = false;//Indica cuando se debe o no abrir el formulario Hijo
    public identificador: string;//Identificador del registro seleccionado
    public mensaje: string = '';
    public loading: boolean = false;
    public codigoAutorizacion: string;
    public registroFormulario: any = undefined;//Contiene el registro del formulario cuando edito uno de estos
    public abrirDialogoBusqueda: boolean = false;//Variable que abre el formulario de busqueda
    public camposFiltros: any = [];//Campos por los cuales se puede realizar una busqueda ocn filtros
    public refereridosRecarga: Array<string> = [];//Lista de referidos de los campos
    public camposValores: Array<campoFormulario> = [];//Campos con los valores ingresados para enviarlos a la busqueda
    public camposRecargar: any = null;
    public bloquearOcultar: any = null;
    public camposMensajes: any;// COntiene los errores de validación para el formulario tipo R
    public palabraBusqueda: string = '';//Palabra que se ingresa en el campo de busqueda de la tabla
    public pagina: number = 1;//Página en la que se encuentra el paginador
    public idRegistroPaginador: string = '0';//Identificador del registro en el paginador, ésto para cuando se vuelva a la tabla, poder definir la pagina de este
    public form: FormGroup;//Poner formgroup a los formularios de angular
    public group: any = {};
    public accion: string = '';//Acciones de formulario(Adicion, eliminacion)
    public abrirDialogoEliminar: boolean = false;
    public dialogoAdicional: string = '';
    public filtrosBusqueda: Array<campoFormulario> = [];
    public filtroPalabra: string = '';
    public tituloTabla: string = '';
    public idsSeleccionados: string[] = [];
    public cantidadRegistros: any = { 'id': 10, 'name': '10' };//Cantidad de registros a renderizar en la tabla
    private intervaloRecarga: any;
    private todosSeleccionados:boolean = false;
    public editable: string;
    public eliminable: string;
    public tabAbiertoHijo: string = '0';
    public idsesi: string;
    public idempr: string;
    public idusua: number;
    public parametrosEnviar: any; // Variable que contendrá parámetros que serán enviados a otro componente
    private idRegistroReferencia:any;
    constructor(private _servicioPeticiones: servicioPeticiones, private _servicioDatosGenerales: DatosGeneralesService, private _servicioOperaciones: OperacionesService) { }

    ngOnInit() {
        let identity = this._servicioDatosGenerales.getIdentity();
        this.idempr = identity.usuario.empresa;
        this.idsesi = identity.idSesion;
        this.idusua = identity.usuario.id;
        this.codigoAutorizacion = '';
        if (this.jsonFormulario) {
            this.llenarCamposFiltro();
            if (this.jsonFormulario.tipo == 'R' && this.jsonFormulario.estructuraMultiRegistro) {
                this.jsonFormulario.estructuraMultiRegistro.titulos = this.tablaResultados.titulos;
                this.jsonFormulario.estructuraMultiRegistro.estilos = this.tablaResultados.estilos;
            }
        }
        this.tablaResultadosForm = this.tablaResultados;
        if (this.tablaResultados && this.tablaResultados.contieneChecks && this.camposPadre) {
            this.asignarValorRegistrosSeleccionadosTablaChecks();
        }

        if (this.idRegistroPadre == undefined) {
            this.idRegistroPadre = null; //Se debe poner el identificador como null para no tener problemas en la peticion y el backend
        }
        if (this.mensajeEntrada && this.mensajeEntrada != '') {
            this._servicioDatosGenerales.enviarMensaje('E', this.mensajeEntrada);
        }
        this.definirValoresPaginaTabla();

        if (this.idmenu && this.jsonFormulario.recarga == 'A') {
            this.intervaloRecarga = setInterval(() => { this.cargarDatosFormulario(); }, 300000);//300000 - 5 minutos, 10000  10 segundos
            this._servicioDatosGenerales.insertarProcesoLista(this.idmenu, this.intervaloRecarga);
        }
        this.enviarSeleccionadosTabla
    }

    ngOnChanges(changes: SimpleChanges) {
        if(changes.tabAbierto){
            if(changes.tabAbierto.currentValue == this.tabRelacionado){
                if (this.jsonFormulario.espera == 'S' && this.idRegistroReferencia!=this.idRegistroPadre){
                    this.cargarDatosFormulario();
                    this.idRegistroReferencia=this.idRegistroPadre;
                }
            }
        }
    }

    enviarCambiosEnRegsitros() {
        this.enviarActulizacionRegistros.emit({
            datosRegistros: this.tablaResultadosForm,
            paginaActual: this.pagina,
            filtrosEmpleados: this.filtrosBusqueda
        });
    }

    asignarValorRegistrosSeleccionadosTablaChecks() {
        let i = 0;
        while (i < this.camposPadre.length) {
            if (this.camposPadre[i].nombre == this.tablaResultados.campoValorChecks && this.camposPadre[i].valor) {
                this.idsSeleccionados = this.camposPadre[i].valor.split(',');
                if (this.idsSeleccionados.length == this.tablaResultados.registros.length) {
                    this.todosSeleccionados = true;
                }
                i = this.camposPadre.length;
            }
            i++;
        }
    }

    abrirNuevoFormulario() {
        this.accion = 'A';
        this.tabAbiertoHijo = '0';
        this.abrirFormularioHijo = true;
        if (this.idmenu && this.jsonFormulario.recarga == 'A') {
            this._servicioDatosGenerales.eliminarProcesoListaProceso(this.intervaloRecarga);
            this.intervaloRecarga = null;
        }
    }

    //Formulario que envía la señal de cerrado para mostrar la tabla con los registros
    cerrarFormularioCampos(recargaPadre) {
        this.abrirFormularioHijo = false;
        this.registroFormulario = null;//Borro los campos que llene al editar un registro
        this.identificador = null;
        this.bloquearOcultar = null;
        this.camposRecargar = null;
        if (this.jsonFormulario.tipo == 'F' || this.jsonFormulario.tipo == 'T' || this.jsonFormulario.tipo == 'R' || this.jsonFormulario.tipo == 'M' || this.jsonFormulario.tipo == 'A' || this.jsonFormulario.tipo == 'B') {//REVISAR
            if (recargaPadre) {
                this.enviarSenalCampoRecargaPadre.emit();
            } else {
                this.cargarDatosFormulario();//true
                if (this.idmenu && this.jsonFormulario.recarga == 'A') {
                    this.intervaloRecarga = setInterval(() => { this.cargarDatosFormulario(); }, 300000);//300000 - 5 minutos
                    this._servicioDatosGenerales.insertarProcesoLista(this.idmenu, this.intervaloRecarga);
                }
            }
        }
    }

    editarRegistroTabla(registro: any) {
        this.editable = registro.editable;
        this.eliminable = registro.eliminable;
        if (this.jsonFormulario.tipo == 'T' || this.jsonFormulario.tipo == 'R' || this.jsonFormulario.tipo == 'A' || this.jsonFormulario.tipo == 'B') {
            this.loading = true;
            this.identificador = registro.id;
            this.accion = 'M';//Modificacion
            let llamado = { "accion": "busquedaRegistroUnico", parametros: { "codigoSesion": this._servicioDatosGenerales.getIdentity().codigoSesion, "formulario": this.idFormulario, "identificador": this.identificador, "identificadorPadre": this.idRegistroPadre, "campos": this.camposValores, "palabraClave": this.palabraBusqueda, "pagina": this.pagina } };
            this._servicioPeticiones.enviarObjetoBus(llamado)
                .subscribe( //suscribe la peticion http para estar pendiente de la respuesta, funciona como un callback
                    response => { //si la peticion fue exitosa ejecuta la funcion response
                        let respuesta = response;
                        let codigoPaso;
                        let resultado;
                        for (let i = 0; i < respuesta.length; i++) {
                            resultado = respuesta[i].valores;
                            if (resultado) {
                                if (resultado.resultado == 0) {
                                    codigoPaso = respuesta[i].codigPaso;
                                    if (codigoPaso == 'I') {//Busqueda de registro unico
                                        this.registroFormulario = resultado.registro;
                                        if (!this.registroFormulario) {
                                            this.loading = false;
                                            this._servicioDatosGenerales.enviarMensaje('E', 'Ocurrió un error inesperado al realizar la búsqueda, por favor contáctese con el administrador del sistema');
                                            i = 2;
                                        }
                                    } else if (codigoPaso == '2') {//Recargar campos de formulario
                                        if (resultado.campos && resultado.campos.length > 0) {
                                            this.camposRecargar = resultado.campos;
                                        }
                                    } else if (codigoPaso == '3') {//Bloqueo de campos del formulario
                                        if (resultado.campos || resultado.fist) {
                                            this.bloquearOcultar = resultado;
                                        } else {
                                            this.bloquearOcultar = null;
                                        }
                                        //Recarga automática
                                        if (this.idmenu && this.jsonFormulario.recarga == 'A') {
                                            this._servicioDatosGenerales.eliminarProcesoListaProceso(this.intervaloRecarga);
                                            this.intervaloRecarga = null;
                                        }
                                        this.abrirFormularioHijo = true;//Solo abre el formulario hijo cuando no hay problemas
                                        if (registro.tabAbierto) {
                                            this.tabAbiertoHijo = registro.tabAbierto;
                                        } else {
                                            this.tabAbiertoHijo = '0';
                                        }
                                    }
                                } else {
                                    i = 2;
                                    this._servicioDatosGenerales.enviarMensaje('E', resultado.mensaje);
                                    this.loading = false;
                                    this.registroFormulario = undefined;
                                    this.identificador = '';
                                }
                            } else {
                                this._servicioDatosGenerales.enviarMensaje('E', this._servicioDatosGenerales.getMensajeError());
                            }
                        }
                        this.loading = false;
                    },
                    error => {
                        this.loading = false;
                        this.identificador = '';
                        this._servicioDatosGenerales.enviarMensaje('E', this._servicioDatosGenerales.getMensajeError());
                    }
                )
        } else {
            this.abrirFormularioHijo = true;
            this.registroFormulario = null;//Borro los campos que llene al editar un registro
            this.identificador = registro.id;
            this.accion = 'A';
        }
    }

    abrirInterzarDesdeMultiRegistro(registro: any) {
        registro.id = registro.idTabla;
        this.editarRegistroTabla(registro);
    }

    //Función que obtiene los datos del colbus en el formulario
    cargarDatosFormulario() {
        if (!this._servicioDatosGenerales.getFormularioBloqueado()) {
            let idRegistroPadreEnviar: string = this.verificarIdentificadorPadre();
            this.loading = true;
            const camposFiltros = this.camposDialogo && this.camposDialogo.length ? this.camposDialogo.concat(this.camposValores) : this.camposValores;
            let llamado = { "accion": "busqueda", parametros: { "codigoSesion": this._servicioDatosGenerales.getIdentity().codigoSesion, "formulario": this.idFormulario, "identificador": this.identificador, "identificadorPadre": idRegistroPadreEnviar, "campos": camposFiltros, "palabraClave": this.palabraBusqueda, "pagina": this.pagina, "cantidadRegistros": this.cantidadRegistros.id } };
            this._servicioPeticiones.enviarObjetoBus(llamado)
                .subscribe( //suscribe la peticion http para estar pendiente de la respuesta, funciona como un callback
                    response => { //si la peticion fue exitosa ejecuta la funcion response
                        let respuesta = response[0].valores;
                        if (respuesta) {
                            if (respuesta['resultado'] == '0') {//Si No obtiene problemas al traer el menú
                                this.registroFormulario = respuesta.registro;
                                if (respuesta.tablaResultados != null) {
                                    this.tablaResultadosForm = respuesta.tablaResultados;
                                    this.tablaResultados = respuesta.tablaResultados;
                                    this.definirValoresPaginaTabla();
                                    this.enviarCambiosEnRegsitros();
                                }
                                this.loading = false;
                            } else {
                                this._servicioDatosGenerales.enviarMensaje('E', respuesta['mensaje']);
                                this.registroFormulario = undefined;
                                this.loading = false;
                            }
                        } else {
                            this.loading = false;
                            this._servicioDatosGenerales.enviarMensaje('E', this._servicioDatosGenerales.getMensajeError());
                        }
                    },
                    error => {
                        this.loading = false;
                        this._servicioDatosGenerales.enviarMensaje('E', this._servicioDatosGenerales.getMensajeError());
                    }
                )
        } else { // Para limpiar los intervalos ya que cuando se desbloquee correrá otro intervalo distinto
            if (this.intervaloRecarga) {
                if (this.idmenu) {
                    this._servicioDatosGenerales.eliminarProcesoListaMenu(this.idmenu);
                } else {
                    this._servicioDatosGenerales.eliminarProcesoListaProceso(this.intervaloRecarga);
                }
                this.intervaloRecarga = null;
            }
        }
    }

    verificarIdentificadorPadre() {
        let idRegistroPadreEnviar: string = null;
        if (this.jsonFormulario.lineal == 'S') {
            idRegistroPadreEnviar = this.idRegistroPadre;
        } else {
            if (this.camposPadre) {
                let campoLinealidad = this.jsonFormulario.lineal == 'G' ? 'secuencia' : this.jsonFormulario.campoLineal;// Para hijos de generalizada envía el campo con valor de secuencia que viene desde el padre
                idRegistroPadreEnviar = this._servicioOperaciones.tomarValorIdLineal(campoLinealidad, this.camposPadre);
            }
        }
        return idRegistroPadreEnviar;
    }

    abrirConfirmacionEliminacion(identificador: string) {
        if (this.jsonFormulario.permisos.eliminar == 'S') {
            this.abrirDialogoEliminar = true;
        } else {
            this.dialogoAdicional = 'confirmacionEliminacionUsuario';
        }
        this.identificador = identificador;
    }

    //Función para adicionar o modificar un registro 
    eliminarRegistro(forzar: string) {
        this.abrirDialogoEliminar = false;
        this.loading = true;
        let llamado = { "accion": "eliminar", parametros: { "formulario": this.idFormulario, "codigoSesion": this._servicioDatosGenerales.getIdentity().codigoSesion, "codigoAutorizacion": this.codigoAutorizacion, "identificador": this.identificador, "forzar": forzar } };
        this._servicioPeticiones.enviarObjetoBus(llamado)
            .subscribe( //suscribe la peticion http para estar pendiente de la respuesta, funciona como un callback
                response => { //si la peticion fue exitosa ejecuta la funcion response
                    let respuesta = response[0].valores;
                    if (respuesta) {
                        if (respuesta.resultado == 0) {//Si No obtiene problemas 
                            if (respuesta.respuestaEliminacion == 'F') {
                                this.mensaje = respuesta.mensaje;
                                this.abrirDialogoEliminar = true;
                            } else {
                                this._servicioDatosGenerales.enviarMensaje('C', 'Se eliminó el registro correctamente');
                                this.mensaje = '';
                                this.identificador = '0';
                                if (this.jsonFormulario.recargaPadre) {
                                    this.enviarSenalCampoRecargaPadre.emit('E');
                                } else {
                                    this.cargarDatosFormulario();//true
                                }
                            }
                            this.loading = false;
                        } else {//Quiere decir que algún campo contiene errores
                            this.identificador = '0';
                            this._servicioDatosGenerales.enviarMensaje('E', respuesta.mensaje);
                            this.loading = false;
                        }
                    } else {
                        this._servicioDatosGenerales.enviarMensaje('E', 'No se puede eliminar el registro, por favor contáctese con el administrador del sistema');
                        this.loading = false;
                    }

                },
                error => {
                    this.loading = false;
                    this._servicioDatosGenerales.enviarMensaje('E', this._servicioDatosGenerales.getMensajeError());
                }
            )
    }

    //Funcipon que se recibe desde el formulario estructura cuando se quiere eliminar un registro
    eliminarRegistroFormulario(datos: any) {
        this.identificador = datos.id;
        this.codigoAutorizacion = datos.codigoAutorizacion;
        this.eliminarRegistro('N');
        this.abrirFormularioHijo = false;
    }

    eliminarRegistroTablaMultiregistro(idRegistro: string) {
        this.identificador = idRegistro;
        this.eliminarRegistro('N');
    }

    //Función que recorre el json y llena un array con campos por los cuales se puede realizar una búqueda 
    llenarCamposFiltro() {
        let campo: any;
        if (this.jsonFormulario.regiones) {
            for (let i = 0; i < this.jsonFormulario.regiones.length; i++) {
                if (this.jsonFormulario.regiones[i].divisiones) {
                    for (let j = 0; j < this.jsonFormulario.regiones[i].divisiones.length; j++) {
                        for (let k = 0; k < this.jsonFormulario.regiones[i].divisiones[j].parametros.length; k++) {
                            campo = this.jsonFormulario.regiones[i].divisiones[j].parametros[k];
                            if (campo.busqueda == 'S') {//Adiciono los campos de busqueda
                                this.camposFiltros.push(new Parametro(campo));
                                this.group[campo.nombre] = new FormControl(campo.nombre || '');
                                if (campo.referidos != null && campo.referidos.length >= 1) {
                                    for (let l = 0; l < campo.referidos.length; l++) {
                                        this.refereridosRecarga.push(campo.referidos[l]);
                                    }
                                }
                            }
                        }
                    }
                }
            }
            this.form = new FormGroup(this.group);
        }
    }

    //Función que se ejecuta el ejecutar la búsqueda avanzada(Campos)
    buscarRegistroCampos() {
        this.palabraBusqueda = '';
        this.filtroPalabra = '';
        this.filtrosBusqueda = [];
        this.identificador = '0';//Pongo el identificador del registro en cero
        this.idRegistroPaginador = '0';//Reinicio el registro del paginador para que ponga los prumeros registros
        this.camposValores = [];
        this.pagina = 1;
        let valor: string = '';
        let campo: any;
        for (let i = 0; i < this.camposFiltros.length; i++) {
            if (this.camposFiltros[i].valor !== '' && this.camposFiltros[i].valor !== null) {
                campo = this.camposFiltros[i];
                if (campo.tipoObjeto == 'S' || campo.tipoObjeto == 'N' || campo.tipoObjeto == 'R' || campo.tipoObjeto == 'I' || this.camposFiltros[i].tipoObjeto == 'C') {
                    valor = campo.contenido.name;
                } else if (campo.tipoObjeto == 'F' || campo.tipoDato == 'F') {
                    let mes = campo.valor.getMonth() + 1;
                    mes = mes > 9 ? mes : '0' + mes;
                    valor = campo.valor.getFullYear() + '/' + mes + '/' + (campo.valor.getDate() > 9 ? campo.valor.getDate() : '0' + campo.valor.getDate());
                } else {
                    valor = campo.valor;
                }
                this.filtrosBusqueda.push(new campoFormulario(campo.nombre, campo.etiqueta, valor));
                this.camposValores.push(new campoFormulario(campo.nombre, '', campo.valor))
            }
        }
        this.cargarDatosFormulario();
        this.abrirDialogoBusqueda = false;
    }

    eliminarFiltroBusqueda(campo: string) {
        for (let i = 0; i < this.camposFiltros.length; i++) {
            if (this.camposFiltros[i].nombre == campo) {
                this.camposFiltros[i].valor = '';
                if (this.camposFiltros[i].tipoObjeto == 'S' || this.camposFiltros[i].tipoObjeto == 'N' || this.camposFiltros[i].tipoObjeto == 'C') {
                    this.camposFiltros[i].contenido = this.camposFiltros[i].opciones[0];
                    if (this.camposFiltros[i].contenido.id != '') {
                        this.camposFiltros[i].contenido = { 'id': '', 'name': 'Seleccione' };
                    }
                }
                i = this.camposFiltros.length;
                this.buscarRegistroCampos();
            }
        }
    }

    //Función que limpia todos los filtros realizados
    cancelarBusqueda() {
        this.tablaResultadosForm = this.tablaResultados;
        this.idRegistroPaginador = '0';//Para que el paginador empiece en la primer pagina
        this.definirValoresPaginaTabla();
        this.palabraBusqueda = '';
        this.filtrosBusqueda = [];
        this.filtroPalabra = '';
        this.camposValores = [];
        for (let i = 0; i < this.camposFiltros.length; i++) {
            this.camposFiltros[i].valor = '';
            if (this.camposFiltros[i].tipoObjeto == 'S' || this.camposFiltros[i].tipoObjeto == 'N' || this.camposFiltros[i].tipoObjeto == 'C') {
                this.camposFiltros[i].contenido = this.camposFiltros[i].opciones[0];
                if (this.camposFiltros[i].contenido.id != '') {
                    this.camposFiltros[i].contenido = { 'id': '', 'name': 'Seleccione' };
                }
            }
        }
        this.cargarDatosFormulario();
        this.abrirDialogoBusqueda = false;

    }

    //Función para invocar al método de búsqueda por medio de una palabra ingresada
    buscarRegistroPalabra() {
        this.identificador = '0';//Pongo el identificador en cero del registro ya que noe s busqueda de registro unico
        this.idRegistroPaginador = '0';//Reinicio el poaginador
        if (this.palabraBusqueda != '') {
            this.filtroPalabra = this.palabraBusqueda + '';
            this.pagina = 1;
            this.cargarDatosFormulario();//true
        }
    }

    //Consulta la tabla quitándole el filtro de palabra
    volverSinFiltroPalabra() {
        if (this.palabraBusqueda == '') {
            this.filtroPalabra = ''
            this.buscarRegistroCampos();
        }
    }

    cambiarPagina(valor) {
        this.idRegistroPaginador = valor.first;
        this.pagina = valor.page + 1;
        this.cargarDatosFormulario();//true
        this.definirValoresPaginaTabla();
    }

    enviarPeticionesPagina() {
        this.idRegistroPaginador = '0';
        this.pagina = 1;
        this.cargarDatosFormulario();
    }

    definirValoresPaginaTabla() {
        let limit: number = this.cantidadRegistros.id;
        let inicial: number;
        let final: number;
        let total: number;
        if (this.tablaResultadosForm && this.tablaResultadosForm.totalRegistros >= 1) {
            if (this.tablaResultados.manejoPaginacion) {
                total = this.tablaResultadosForm.totalRegistros;
                inicial = ((this.pagina - 1) * limit) + 1;
                this.tituloTabla = 'Registros ' + inicial + ' a ';
                if (total <= limit) {
                    final = total;
                } else {
                    final = ((this.pagina - 1) * limit) + limit
                    if (final > total) {
                        final = total;
                    }
                }
                this.tituloTabla += final + ' de ' + total;
            } else {
                this.tituloTabla = 'Registros: ' + this.tablaResultadosForm.totalRegistros;
            }
        } else {
            this.tituloTabla = 'No se encuentran registros';
        }
    }

    //Exporta los datos que se encuentran en el colbus en un excel
    exportarExcel() {
        let myForm = <HTMLFormElement>document.getElementById('form' + this.idFormulario);
        let inputIdentificadorRegistro = <HTMLInputElement>document.createElement('input');
        inputIdentificadorRegistro.name = 'idRegistroPadre';
        inputIdentificadorRegistro.value = this.verificarIdentificadorPadre();
        inputIdentificadorRegistro.hidden = true;
        myForm.appendChild(inputIdentificadorRegistro);
        myForm.action = this._servicioDatosGenerales.getUrlExcelColbus();
        myForm.submit();
    }

    enviarSeleccionadosTabla() {
        this.enviarValoresCheck.emit({ seleccionados: this.idsSeleccionados, campo: this.tablaResultados.campoValorChecks });
    }

    //Realiza la petición al dar click en seleccionar todos(Tablas con checks) para que e back 
    //Busque todos los identificadores de la tabla y se puedan seleccionar, incluyendo todas las páginas
    seleccionarTodosChecks() {
        if (this.todosSeleccionados) {
            this.loading = true;
            let idRegistroPadreEnviar: string = this.verificarIdentificadorPadre();
            let llamado = { "accion": "buscarTodos", parametros: { "codigoSesion": this._servicioDatosGenerales.getIdentity().codigoSesion, "formulario": this.idFormulario, "identificadorPadre": idRegistroPadreEnviar, "campos": this.camposValores, "palabraClave": this.palabraBusqueda } };
            this._servicioPeticiones.enviarObjetoBus(llamado)
                .subscribe( //suscribe la peticion http para estar pendiente de la respuesta, funciona como un callback
                    response => { //si la peticion fue exitosa ejecuta la funcion response
                        let respuesta = response[0].valores;
                        if (respuesta != null) {
                            if (respuesta['resultado'] == '0') {//Si No obtiene problemas al traer el menú
                                this.idsSeleccionados = respuesta.registros;
                                this.enviarValoresCheck.emit({ seleccionados: this.idsSeleccionados, campo: this.tablaResultados.campoValorChecks });
                            } else {
                                this._servicioDatosGenerales.enviarMensaje('E', respuesta['mensaje']);
                            }
                            this.loading = false;
                        } else {
                            this.loading = false;
                            this._servicioDatosGenerales.enviarMensaje('E', this._servicioDatosGenerales.getMensajeError());
                        }
                    },
                    error => {
                        this.loading = false;
                        this._servicioDatosGenerales.enviarMensaje('E', this._servicioDatosGenerales.getMensajeError());
                    }
                )
        } else {
            this.idsSeleccionados = [];
            this.enviarValoresCheck.emit({ seleccionados: this.idsSeleccionados, campo: this.tablaResultados.campoValorChecks });
        }
    }

    //Esta funcion es para reargar los datos ya en el padre
    recargarFormularioCompleto(valores) {
        this.abrirFormularioHijo = false;
        if (valores.accion == 'E') {
            this._servicioDatosGenerales.enviarMensaje('C', 'Se eliminó el registro correctamente');
        }
        this.editarRegistroTabla({ id: valores.id, tabAbierto: valores.tabAbierto });
    }

    ejecutarRecargaCampos(parametro: any) {
        if (parametro.valor != '') {//Recarga de objeto
            if ((parametro.recargaObjeto == 'S' && (parametro.referidos == null || parametro.referidos.length < 1)) || (parametro.recargaObjeto == 'S' && parametro.valor != '' && this.referidosLlenos(parametro.referidos))) {
                this.recargarCamposFormulario(parametro);
            }
            //Debo verificar si recarga pero también si es referido de otro campo
            if (this.esReferido(parametro) != null) {
                let campo = this.asignarValorReferido(parametro);//Retorna el campo que tiene los referidos
                if (campo != null && campo.valor != '') {
                    this.recargarCamposFormulario(campo);
                }
            }
        }
    }

    //Función que verifica 
    referidosLlenos(referidos: any) {
        if (referidos != '') {
            for (let i = 0; i < referidos.length; i++) {
                if (referidos[i].nombre != 'idsesi' && referidos[i].nombre != 'idusua' && referidos[i].nombre != 'idempresa' && referidos[i].valor == '') {
                    return false;
                }
            }
        }
        return true;
    }

    //Función que indica si un campo es referido por otro 
    esReferido(parametro: any) {
        let campo: string;
        if (this.refereridosRecarga != undefined && this.refereridosRecarga != null && this.refereridosRecarga.length >= 1) {
            for (let i = 0; i < this.refereridosRecarga.length; i++) {
                campo = this.refereridosRecarga[i];
                if (campo == parametro.nombre) {
                    return campo;
                }
            }
        }
        return null;
    }

    //Función que llena la lista de referidos y de estar llena, retorna el campo que los contiene
    asignarValorReferido(parametro: any) {
        let campo: any;
        let llenos: number = 0;
        for (let i = 0; i < this.camposFiltros.length; i++) {//Recorro las regiones ->Grobs
            campo = this.camposFiltros[i];
            if (campo.recargaObjeto == 'S' && campo.referidos != null && campo.referidos.length >= 1) {
                for (let l = 0; l < campo.referidos.length; l++) {
                    if (campo.referidos[l].nombre == parametro.nombre) {
                        this.camposFiltros[i].referidos[l].valor = parametro.valor;
                    }
                    if (campo.referidos[l].valor != '') {//Cuento los referidos para saber si ya se puede realizar la petición de recarga
                        llenos++;
                    }
                }
                if (llenos == campo.referidos.length) {
                    return campo;
                }
            }
        }
        return null;
    }

    //Función que realiza la recarga de los demás campos del formulario cuando un campo es referido o tiene recarga
    recargarCamposFormulario(parametro: any) {
        let llamado = { "accion": "recargar", parametros: { "codigoSesion": this._servicioDatosGenerales.getIdentity().codigoSesion, "formulario": this.idFormulario, "campo": parametro.nombre, "valor": parametro.valor, "referidos": parametro.referidos } };
        this._servicioPeticiones.enviarObjetoBus(llamado)
            .subscribe( //suscribe la peticion http para estar pendiente de la respuesta, funciona como un callback
                response => { //si la peticion fue exitosa ejecuta la funcion response
                    let respuesta = response[0].valores;
                    if (respuesta.resultado == 0) {//Si No obtiene problemas al traer el menú
                        if (respuesta.campos) {
                            this.llenarDatosRecargar(respuesta.campos);
                        }
                    } else {
                        this._servicioDatosGenerales.enviarMensaje('E', respuesta['mensaje']);
                    }
                },
                error => {
                    this._servicioDatosGenerales.enviarMensaje('E', this._servicioDatosGenerales.getMensajeError());
                }
            )
    }

    //Función que toma los valores de los campos a recargar y les asigna la información 
    llenarDatosRecargar(campos: any) {
        let campo: any;
        for (let i = 0; i < campos.length; i++) {
            if (campos[i].nombre == 'mensaje_error' && campos[i].valor != '' && campos[i].valor != null) {
                this._servicioDatosGenerales.enviarMensaje('E', campos[i].valor);
            } else if (campos[i].nombre == 'mensaje_alerta' && campos[i].valor != '' && campos[i].valor != null) {
                this._servicioDatosGenerales.enviarMensaje('A', campos[i].valor);
            } else if (campos[i].nombre == 'mensaje_exito' && campos[i].valor != '' && campos[i].valor != null) {
                this._servicioDatosGenerales.enviarMensaje('C', campos[i].valor);
            } else {
                for (let j = 0; j < this.camposFiltros.length; j++) {
                    campo = this.camposFiltros[j];
                    if (campo.nombre == campos[i].nombre) {
                        if (campo.tipoObjeto == 'S' || campo.tipoObjeto == 'N') {//Selects numerico y de texto    
                            this.camposFiltros[j].opciones = campos[i].opciones;
                        } else if (campo.tipoObjeto == 'I') {//Autocompletar
                            this.camposFiltros[j].opcionesAutocompletar = campos[i].opciones;
                        } else if (campo.tipoObjeto == '1') {//Lista de selección avanzada
                            this.camposFiltros[j].opcionesListaAvanzada = campos[i].opciones;
                        } else if (campo.tipoObjeto == 'C') {//Checks
                            this.camposFiltros[j].opcionesCheck = campos[i].opciones;
                        } else {//Otros campos del formulario
                            this.camposFiltros[j].valor = campos[i].valor;
                        }
                        j = this.camposFiltros.length;
                    }
                }

            }
        }
    }

    getUrlDescarga(url: string) {
        return this._servicioDatosGenerales.getUrlDescarga() + url;
    }

    ejecutarFuncionBotonCondicional(tipo: string, accion: string, constantes: any, valores: any, modo: number) {
        if (tipo == 'R') {
            if (accion && accion != '') {
                let myForm = <HTMLFormElement>document.getElementById('impresionTabla' + this.jsonFormulario.id);
                // creamos un elemento input para que se envie el campo con el identificador de registro    
                let inputIdentificadorRegistro = <HTMLInputElement>document.createElement('input');
                inputIdentificadorRegistro.name = 'idRegistro';
                inputIdentificadorRegistro.value = valores.id;
                inputIdentificadorRegistro.hidden = true;
                myForm.appendChild(inputIdentificadorRegistro);

                let ruta = this._servicioDatosGenerales.getUrlBase() + accion;
                ruta = ruta.replace('&id&', valores.id);
                myForm.action = ruta;
                myForm.submit();
            } else {
                this._servicioDatosGenerales.enviarMensaje('E', 'No se encuentra reporte para generar');
            }
        } else if (tipo == 'F') {
            this.dialogoAdicional = 'dialogoTabla';
            this.parametrosEnviar = { constantes: constantes, id: valores.id, valores: valores.datos, modo: modo };
        }
    }

    realizarOperacionRegistroListaAgregacion(registro: any) {
        let idRegistroPadreEnviar: string = this.jsonFormulario.lineal == 'S' ? this.idRegistroPadre : this._servicioOperaciones.tomarValorIdLineal(this.jsonFormulario.campoLineal, this.camposPadre);
        this.loading = true;
        let llamado = { "accion": "adicionarActualizarTablaMultiregistro", "parametros": { "formulario": this.idFormulario, "codigoSesion": this._servicioDatosGenerales.getIdentity().codigoSesion, "region": "0", "campos": registro.campos, "identificadorPadre": idRegistroPadreEnviar } };
        this._servicioPeticiones.enviarObjetoBus(llamado)
            .subscribe(
                response => {
                    let respuesta = response;
                    let codigoPaso: string;
                    let resultado: any;
                    for (let i = 0; i < respuesta.length; i++) {
                        resultado = respuesta[i];
                        codigoPaso = resultado.codigPaso;
                        if (codigoPaso == 'I') {//Inicial, proceso de validacion
                            if (resultado.valores.resultado != '0') {
                                if (!resultado.valores.datos) {
                                    this.tablaResultadosForm = this.tablaResultados;
                                    this._servicioDatosGenerales.enviarMensaje('E', resultado.valores.mensaje);
                                } else if (resultado.valores.datos.campos) {
                                    this.camposMensajes = resultado.valores.datos.campos;
                                    this._servicioDatosGenerales.enviarMensaje('E', 'Se encuentran errores en los datos del formulario ó de validación');
                                } else if (resultado.valores.datos.procedimiento && resultado.valores.datos.procedimiento.mensaje) {
                                    let mensaje = resultado.valores.datos.procedimiento.mensaje;
                                    let tipo = resultado.valores.datos.procedimiento.tipo;
                                    if (tipo && tipo != '') {
                                        this._servicioDatosGenerales.mostrarMensajePersonalizado(tipo, mensaje);
                                    } else {
                                        this._servicioDatosGenerales.enviarMensaje('A', mensaje);
                                    }
                                    this.tablaResultadosForm = this.tablaResultados;
                                }
                                i = 2;
                            }
                        } else if (codigoPaso == '2') {//Operacion(Inserción o actualización)
                            if (resultado.valores.resultado == '0') {
                                this.accion = 'M';
                                this._servicioDatosGenerales.enviarMensaje('C', 'Proceso realizado correctamente');
                                if (this.jsonFormulario.recargaPadre) {
                                    this.enviarSenalCampoRecargaPadre.emit('M');
                                } else {
                                    this.cargarDatosFormulario();//No se realiza busqueda por bus ya que el campo que se envía como nuevo o edicion de registro lo utiliza como filtro 
                                }
                            } else {
                                i = 3;
                                this.tablaResultadosForm = this.tablaResultados;
                                this._servicioDatosGenerales.enviarMensaje('E', resultado.valores.mensaje);
                            }
                        }
                    }
                    this.loading = false;
                },
                error => {
                    this._servicioDatosGenerales.enviarMensaje('E', this._servicioDatosGenerales.getMensajeError());
                    this.loading = false;
                }
            )
    }
}