declare var JSEncrypt: any;

export class funcionesSesion {
    private encrypt = new JSEncrypt();
   
    //Función que encripta en RSA haciendo uso de la librería /assets/js/jsEncryp.js
    encriptarRsa(pass:string,codigoAcceso:string){
        this.encrypt.setPublicKey(codigoAcceso);
        return this.encrypt.encrypt(pass);
    }
}