export const MEMBERCHARTOPTIONS = { responsive: true, legend: { display: true, labels: { display: true } } };

export const MEMBERCHARTOPTIONSFORMAT = {
    responsive: true, legend: { display: true, position: 'left', labels: { display: true } },
    tooltips: {
        callbacks: {
            label: function (tooltipItem, data) {
                var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                let label = data.labels[tooltipItem.index];
                if (parseInt(value) >= 1000) { return label + ': ' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); } else { return label + ': ' + value; }
            }
        }
    },
    plugins: {
        labels: {
            render: function (args) {
                if (parseInt(args.value) >= 1000) { return args.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); } else { return args.value; }
            },
            fontColor: 'white',
            //  position: 'border'
        }
    }
};

export const BARCHARTIOPTIONS = {
    scaleShowVerticalLines: false, responsive: true,
    legend: { display: false, labels: { display: true, visible: true } },
    scales: { yAxes: [{ display: true }], xAxes: [{ display: false }] },
};

export const BARCHARTIOPTIONSFORMAT = {
    scaleShowVerticalLines: false, responsive: true,
    legend: { display: false, labels: { display: true, visible: true } },
    layout: {
        padding: {
            top: 20
        }
    },
    scales: {
        yAxes: [{
            display: true,
            ticks: {
                beginAtZero: true,
                callback: function (value) {
                    if (parseInt(value) >= 1000) { return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); } else { return value; }
                }// beginAtZero: true - min:0 // Para que se vean siempre los valores que son mayores que cero, ya que cuando hay varios datos, no muestra en gráficaelmenov valor (Y enmpieza enel menor)
            }
        }],
        xAxes: [{ display: true }]
    },
    tooltips: {
        callbacks: {
            label: function (tooltipItem, data) {
                var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                let label = data.datasets[tooltipItem.datasetIndex].label;
                if (parseInt(value) >= 1000) { return label + ': ' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); } else { return label + ': ' + value; }
            }
        }
    },
    plugins: {
        labels: {
            render: function (args) {
                if (parseInt(args.value) >= 1000) { return args.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); } else { return args.value; }
            }
        }
    }
}

export const BARCHARTIOPTIONSFORMATSERIES = { ...BARCHARTIOPTIONSFORMAT, legend: { ...BARCHARTIOPTIONSFORMAT.legend, display: true } }