import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ViewEncapsulation } from '@angular/core';
import { servicioPeticiones } from '@servicios/servicioPeticiones';
import { DatosGeneralesService } from '@servicios/datosGenerales.service';
import { OperacionesService } from '@servicios/operaciones.service';
import { campoFormulario } from '@modelos/campo';
import { Formulario } from '@modelos/formulario';
import { FormControl, FormGroup } from '@angular/forms';
import { FileUpload } from 'primeng/primeng';
import { saveAs } from 'file-saver';
import { FormularioRecarga, ParametrosDialogo } from '@src/app/interfaces/estructurasGenerales';

@Component({
    selector: 'app-formulario-estructura',
    templateUrl: './formulario-estructura.component.html',
    styleUrls: ['./formulario-estructura.component.css'],
    encapsulation: ViewEncapsulation.None
})

/**
 * Éste componente contiene la estructura de un formulario con sus campos
 */
export class FormularioEstructuraComponent implements OnInit {
    @Input() jsonFormulario: any;//Json con el formulario para renderizar y armar el objeto con los modelos, Se recibe por input ya que se va renderizando recursivamente
    @Input() idFormulario: string;//Id del formulario a renderizar
    @Input() registroFormulario: any;//Registro en el caso que se esté modificando en formularios de registro
    @Input() camposRecargar: any;//Campos que dependen de una recarga con sus valores (Al realizar búsqueda, también en la estrategia se llama a recargar todos los campos del formulario, éstos campos se deben asociar en el formulario)
    @Input() bloquearOcultar: any;//Campos que se deben bloquear en la busqueda de registro unico o fist a ocultar
    @Input() idRegistroFormulario: string;//Id del registro que se está modificando
    @Input() idRegistroPadre: string; // Identificador del registro padre cuando el formulario es un hijo
    @Input() accion: string;//Si se realiza una adicion o una eliminacion, ésto se creó ya que se ve la necesidad de saber cual es la acción para realizar algunas operaciones
    @Input() mensajeEntrada: string; // Mensaje de entrada que se debe mostrar al abrir un formulario
    @Input() camposPadre: any; // Campos del formulario padre con sus valores.
    @Input() editable: string; // Bandera para manejar el editable de un formulario (sirve para saber si puede o no habilitar el botón de guardar)
    @Input() eliminable: string; // Bandera para manejar el eliminable de un formulario (Sirva para saber si puede o no eliminar un registro)
    @Input() tabAbierto; // Bandera que se utiliza cuando se guarda información de un formulario y el formulario se debe cerrar para recargar la información en el padre y volverse abrir quedando en el tab correspondiente
    @Output() cerrarFormulario = new EventEmitter(); // Variable para enviar la acción de cerrado de formulario quedando en el colbus
    // @Output() realizarBusquedaRegistro = new EventEmitter(); Eliminado ya que al parecer  no se utiliza
    @Output() eliminarReg = new EventEmitter(); // Evento para enviar la señal de eliminar al componente de tabla (formulario colbus) ya que desde allí es que se realizan las eliminaciones
    @Output() enviarSenalRecargarFormulario = new EventEmitter(); // Evento para que se recargue la información del formulario (se cierre y se realice busqueda ) cuando se requiere ya sea por que una recarga lo requiere o por que un hijo requiere que su padre refresque su información
    @ViewChild('archivos', { static: false }) fileInput: FileUpload;//Funciona para enlazar datos del DOM con la propiedad #NOMBRECAMPO. En este caso se utiliza para formulario de tipo Importación haciendo que sea fácil llamar a la importación cuando se requiera
    @ViewChild('archivosCampo', { static: false }) fileInput2: FileUpload;//Funciona para enlazar datos del DOM con la propiedad #NOMBRECAMPO. En este caso se utiliza para los archivos de tipo carga de archivos de manera que cuando un formulario tenga un campo de carga de archivos, al realizar el guardado(inserción o actualización), se pueda llamar a la función que carga los archivos

    public listaCamposReferidos: Array<string> = [];//Referidos encontrados en el formulario. Se llenan en una lista para saber sin tener que recorrer toda la estructura de un formulario si es o no referido
    public listaCamposReferidosBloqueo: Array<string> = [];//Referidos de campos del formulario que son para bloqueo. Se llenan en una lista para saber sin tener que recorrer toda la estructura de un formulario si es o no referido
    public listaReferidosReporte: Array<string> = [];//Referidos de campos del formulario y del padre que son necesarios para envio de valores a los reportes. Se llenan en una lista para saber sin tener que recorrer toda la estructura de un formulario si es o no referido
    public formularioValido: boolean;//Bandera que me indicará si el formulario es válido para poder enviar los datos al backend. La validación es manual por la diversidad de tipos de objetos y campos.
    public campos: Array<campoFormulario> = [];//Array que contendrá los campos del formulario que pueden tener valores y serán enviados al backend en cualquier petición, ejemplo guardar registro (Campo, Valor).
    public codigoAutorizacion: string; //Utilizado al momento de realizar operaciones con permiso de usuario y/o administrador ya que para validar credenciales se necesita un código para la validación
    public loading: boolean = false; // Bandera que me indica si se debe mostrar el indicador de cargando cuando se hacen las peticiones al backend (Loading con el logo de datasoft).
    public formulario: any;//Contiene la estructura del formulario a renderizar en este componente (Puede venir de la construcción directamente o desde un campo tipo J)
    public tablasHijos: any;//Contiene los datos de las tablas hijas (colbus); se le asocia un valor cuando se realiza busqueda en un formulario
    public form: FormGroup;//Utilizado para poder agrupar los campos en el html haciendo uso de formgroup que es una tecnología de angular para manejo de formularios reactivos. Se encpntró la necesidad de agruparlos de esta manera ya que los reportes actualmente se generan desde un <form>. Sería bueno generar reportes desde httpclient así como se realizan las perticiones al backend
    public valorCampoAdicional: any = [];//Contiene el tipo de reporte seleccionado (para formulario de consulta) (Utilizado para varias operaciones)
    public tiposReporte: any = [];//Contiene la informacion para los tipos de reporte que se muestra en el formulario de consulta
    public camposFiltros: Array<campoFormulario> = [];//Array en donde se almacenarán los Campos con sus valores para realizar busquedas en fomularios de consulta
    public tablaResultados: Array<any> = []; // EStructura que contiene los registros y parámetros de una tabla, ya sea cuando hay un error en la importación de formularios de importación, en la tabla de consulta directa, entre otros
    public urlCarga: string = '';//Campo que contiene la ruta del backend en el que se realiza la peticion de la carga de archivo
    public tieneCargaArchivo: boolean = false; // Bandera que me indica en un formulario si hay algun campo que sea de carga de archivos; ésto con el fin de que cuando se realice una petición de guardado, se sepa que también debe realizar una carga de archivos
    public campoCarga: any = '';//Campo que contendrá la información del campo que sea idobje 'carga de archivos', teniendo en cuenta que para poder cargar los archivos después de realizar la operación de adicionarActualizar, se debe desde éste componente, tomar la información de la carga y enviar la petición (No se tiene ibobje carga en el componente de campos-formulario)
    public cargaDeshabilitado: boolean = false;//Bandera que me indica cuando el campo de carga de archivos deba estar deshabilitado, por ejemplo cuando se selecciona un archivo; ésto para que no lo carguen sin realizar la operación de guardado del formulario.
    public abrirDialogoEliminar: boolean = false; // Variable que maneja el dialogo de confirmación de eliminación de un registro;cuando es verdadero, muestra el dialogo
    public dialogoAdicional: string = ''; //Variable que me indica si se debe mostrar un dialogo de confirmación; ejemplo el dialogo de usuario y contraseña cuando se realiza alguna operación en un formulario
    public rutaReporte: string; //LIQUIDACION //Variable que se creó a manera temporal para que se pudiera generar un reporte en un formulario de consulta hijo, se debe verificar si es necesario tenerla en el momento en que se trabaje lo de liquidación
    public listaCamposDefecto: Array<any> = []; // Array en el que se tendrán los campos que tienen valores por defecto, esto para que cuando se realice una recarga en un nuevo registro o en un formulario que no es de texto, se pueda verificar en el array de valores por defecto si hay referidos sin necesidad de recorrer toda la estructura del formulario
    public idusuario: string; // Contiene el valor del id del usuario que se loggeó (Para enviar a reportes)
    public idempr: string; // Contiene el valor del id de empresa del usuario que se loggeó (Para enviar a reportes)
    public codigoSesion: string; // Código de la sesión actual del sistema
    public idsesi: string; // Id de la sesión actual (Para enviar a reportes)
    public paso: number; // Identifica el paso del wizard en el que está un formulario wizard
    public ordenGrob: string; //Utilizado en wizard ya que la petición de adicionarActualizar lo necesita pasra saber que parte del formulario está guardando
    public stepClass: string; // Clase del wizard para saber como distribuir el espacio dependiendo de la cantidad de pasos
    public cantidadRegistros: any = { 'id': 10, 'name': '10' };//Variable que contiene la Cantidad de registros a renderizar en la tabla que se necesite, por defecto se le asigna 10
    public idRegistroPaginador: string = '0' // Indice que me indica en que página está el paginador de la tabla (caso de consulta, importación, etc)
    public delimitador: any = { 'id': ';', 'name': 'Punto y coma' };// Contiene el delimitador en formulario de consulta, por defecto el punto y coma
    public imprimeTitulos: boolean = true; // Bandera que me indica si se imprimen títulos en la primer fila del archivo plano cuando se realiza una consulta
    public nombreCampoPrimario: string; // Se crea esta variable para que tenga el nombre del campo pk del formulario para el manejo de paso atrás en el wizard
    public parametrosEnviarFormularioDialogo: ParametrosDialogo;

    constructor(private _servicioPeticiones: servicioPeticiones, private _servicioDatosGenerales: DatosGeneralesService, private _servicioOperaciones: OperacionesService) { }

    ngOnInit() {
        let identity = this._servicioDatosGenerales.getIdentity();
        this.codigoAutorizacion = '';
        this.idusuario = identity.usuario.id;
        this.idempr = identity.usuario.empresa;
        this.idsesi = identity.idSesion;
        this.codigoSesion = identity.codigoSesion;
        this.formulario = new Formulario(this.jsonFormulario); // Se almacena en el objeto de formulario, toda la estructura dependiendo de lo que nos devuelve el servicio de construcción, cabe aclarar que en el modelo, se pueden personalizar algunas características del formulario por defecto

        if (this.formulario.tipo == 'A' || this.formulario.tipo == 'B') { // this.accion == 'A' // Cuando es un wizard, se verifica que espacio se debe utilizar
            this.prepararWizard();
        }
        if (this.registroFormulario) { // Cuando hay un registro (se hizo busqueda de registro único por ejemplo), se debe asociar los parámetros de formulario, los valores que llegan desde el servicio
            this.tablasHijos = this.registroFormulario.datosHijos;
            this.llenarDatosFormulario(this.registroFormulario.campos, false, false);
        }
        if (this.camposRecargar) { // Cuando el bus nos retorna que existen campos que se deben recargar ya que algun campo tiene un valor por defecto y ejecuta recarga, se asocia el valor en los parametros del objeto formulario
            this.llenarDatosRecargar(this.camposRecargar, false);
        }
        if (this.bloquearOcultar != null) { // Cuando el bus nos retorna que existen campos que se deben bloquear o fist que se deben ocultar, se cambian estas propiedades en el objeto de formulario
            this.cambiarPropiedadesBloqueoOculto(this.bloquearOcultar);
        }
        if (this.formulario.tipo == 'C') {//Cuando es formulario de consulta lleno los datos del select de tipo de salida dependiendo de la configuración (chtm salida)
            this.llenarDatosFormularioTipoConsulta();
        } else if (this.formulario.tipo == 'I') { // Cuando es un formulario de importación, obtengo la url del servicio de carga de archivos para utilizarla cuando sea necesario
            this.valorCampoAdicional = false;
            this.urlCarga = this._servicioDatosGenerales.getUrlCarga();
        }
        if (this.mensajeEntrada != '' && this.mensajeEntrada != null) { // Cuando hay un mensaje de entrada que viene desde otro componente, pero al reemplazarlo se debe mostrar en este
            if (this.accion == 'A') {
                this._servicioDatosGenerales.enviarMensaje('E', this.mensajeEntrada);
            } else {
                this._servicioDatosGenerales.enviarMensaje('C', this.mensajeEntrada);
            }
        }
        //Funcionalidad para tomar los valores en los reportes,(Se utilizó reactive forms) y poner a los campos del formularios atributos necesarios
        if (this.accion != 'B') {//Ésto se hizo para que no saque el error de formgroup
            this.form = this.asociarDatosCampos();
        }
    }

    //Función que agrupa al formulario que está en varios divs y sub componentes, ésto para poder hacer uso del submit del formulario a la hora de imprimir datos del formulario en un pdf o excel. Al no hacer esto, al hacer submit en el formulario, no llegaban los parámetros por request en los reportes
    asociarDatosCampos() {
        let group: any = {}; // Esta variable lo que hace es agrupar los campos del formulario
        for (let j = 0; this.formulario.regiones && j < this.formulario.regiones.length; j++) { // Se recorren las regiones
            for (let k = 0; this.formulario.regiones[j].divisiones && k < this.formulario.regiones[j].divisiones.length; k++) { // Se recorren las divisiones
                if (this.formulario.regiones[j].divisiones[k].parametros) {
                    this.formulario.regiones[j].divisiones[k].parametros.forEach(campo => {
                        // campo.tipoObjeto=='C' ||// Lo quite por que para blocam no estaba bloqueando los campos, verificar en reportes
                        if ((campo.tipoObjeto == 'T' && campo.tipoDato != 'F') || campo.tipoObjeto == 'S' || campo.tipoObjeto == 'N' || campo.tipoObjeto == 'A' || campo.tipoObjeto == 'H' || campo.tipoObjeto == 'W' || campo.tipoObjeto == 'E' || campo.tipoObjeto == '2' || campo.tipoObjeto == 'I' || campo.tipoObjeto == '1' || campo.tipoObjeto == 'C') { //T,S,N
                            group[campo.nombre] = new FormControl(campo.nombre || ''); // Hacer el parámetro parte del grupo de formulario
                        }
                        if (campo.referidos && campo.referidos.length >= 1) {//adiciono los campos referidos de un formulario
                            for (let l = 0; l < campo.referidos.length; l++) {
                                //Verifico si dentro de los referidos hay campos del formulario padre
                                if (this.camposPadre) {
                                    for (let i = 0; i < this.camposPadre.length; i++) {
                                        if (campo.referidos[l].nombre == this.camposPadre[i].nombre) {
                                            campo.referidos[l].valor = this.camposPadre[i].valor;
                                        }
                                    }
                                }
                                if (campo.referidos[l].valor == '') {//Al estar vacio quoere decir que no viene desde el padre y es necesario ingresarlo en la lista de referidos
                                    this.listaCamposReferidos.push(campo.referidos[l]['nombre']);
                                    if (this.accion == 'M' && this.registroFormulario) {
                                        let i: number = 0;
                                        while (i < this.registroFormulario.campos.length) {
                                            if (campo.referidos[l].nombre == this.registroFormulario.campos[i].nombre) {
                                                campo.referidos[l].valor = this.registroFormulario.campos[i].valor;
                                                i = this.registroFormulario.campos.length;
                                            }
                                            i++;
                                        }
                                    }
                                }
                            }
                        }
                        //Se hacen dos condicionales separadas ya que en pocos casos un referido es de recarga y de bloqueo
                        if (campo.referidosBloqueo != null && campo.referidosBloqueo.length >= 1) {//adiciono los campos referidos de un formulario
                            for (let l = 0; l < campo.referidosBloqueo.length; l++) {
                                if (this.camposPadre) {
                                    for (let i = 0; i < this.camposPadre.length; i++) {
                                        if (campo.referidosBloqueo[l].nombre == this.camposPadre[i].nombre) {
                                            campo.referidosBloqueo[l].valor = this.camposPadre[i].valor;
                                        }
                                    }
                                }
                                if (campo.referidosBloqueo[l].valor == '') {//Al estar vacio quoere decir que no viene desde el padre y es necesario ingresarlo en la lista de referidos
                                    this.listaCamposReferidosBloqueo.push(campo.referidosBloqueo[l]['nombre']);
                                    if (this.accion == 'M' && this.registroFormulario) {
                                        let i: number = 0;
                                        while (i < this.registroFormulario.campos.length) {
                                            if (campo.referidosBloqueo[l].nombre == this.registroFormulario.campos[i].nombre) {
                                                campo.referidosBloqueo[l].valor = this.registroFormulario.campos[i].valor;
                                                i = this.registroFormulario.campos.length;
                                            }
                                            i++;
                                        }
                                    }
                                }
                            }
                        }
                        //Se hacen dos condicionales separadas ya que en pocos casos un referido es de recarga y de bloqueo
                        if (campo.referidosReporte && campo.referidosReporte.length >= 1) {//adiciono los campos referidos de un formulario
                            for (let l = 0; l < campo.referidosReporte.length; l++) {
                                if (this.camposPadre) {
                                    for (let i = 0; i < this.camposPadre.length; i++) {
                                        if (campo.referidosReporte[l].nombre == this.camposPadre[i].nombre) {
                                            campo.referidosReporte[l].valor = this.camposPadre[i].valor;
                                        }
                                    }
                                }
                                if (campo.referidosReporte[l].valor == '') {//Al estar vacio quiere decir que no viene desde el padre y es necesario ingresarlo en la lista de referidos
                                    this.listaReferidosReporte.push(campo.referidosReporte[l]['nombre']);
                                    if (this.accion == 'M' && this.registroFormulario) {
                                        let i: number = 0;
                                        while (i < this.registroFormulario.campos.length) {
                                            if (campo.referidosReporte[l].nombre == this.registroFormulario.campos[i].nombre) {
                                                campo.referidosReporte[l].valor = this.registroFormulario.campos[i].valor;
                                                i = this.registroFormulario.campos.length;
                                            }
                                            i++;
                                        }
                                    }
                                }
                            }
                        }
                        if (campo.tipoObjeto == 'U' || campo.tipoObjeto == 'V' || campo.tipoObjeto == 'K') {
                            if (campo.tipoObjeto == 'V') {//Archivos multiple
                                if (campo.valor && campo.valor != '') {
                                    campo.contenido = campo.valor.split(",");
                                }
                                let peso: number = this._servicioDatosGenerales.getIdentity().configuracion.tamañoArchivo;//Tomo el peso del archivo
                                campo.longitud = peso * 1000000;//Hago la conversión de megas a bytes
                            }
                            this.tieneCargaArchivo = true;
                            this.campoCarga = campo;
                            this.urlCarga = this._servicioDatosGenerales.getUrlCarga();
                        }
                        if (this.accion == 'A') { // Adición (Cuando no existe registro)
                            if (this.camposPadre && campo.campoReferencia) {
                                for (let i = 0; i < this.camposPadre.length; i++) {
                                    if (campo.campoReferencia == this.camposPadre[i].nombre) {
                                        campo.valor = this.camposPadre[i].valor;
                                    }
                                }
                            }
                            if (campo.tipoObjeto != 'B' && campo.tipoObjeto != '4' && campo.defecto && campo.defecto != '') { // Ejecuta las recargas cuando es nuevo registro excepto en los casos de botones
                                campo.valor = campo.defecto;
                                this.verificarFuncionesCampo(campo);
                                this.listaCamposDefecto.push({ 'nombre': campo.nombre, 'valor': campo.valor });
                            }
                            if (this.formulario.tipo == 'A' && campo.nombre == this.formulario.campoPaso) {// para wizard
                                campo.valor = this.paso + 1;
                            }
                        }
                        if (this.idRegistroFormulario && campo.campoReferencia == 'identificadorRegistro') {//LIQUIDACION
                            campo.valor = this.idRegistroFormulario;
                        }
                        if (campo.primario === 'S') {
                            this.nombreCampoPrimario = campo.nombre;
                        }
                    });
                }
            }
        }
        return new FormGroup(group);
    }

    llenarDatosFormularioTipoConsulta() {
        switch (this.formulario.parametrosReporte.tipoImpresion) {
            case 'S': { this.tiposReporte = [{ id: 'D', name: 'Directa' }, { id: 'E', name: 'Excel' }, { id: 'P', name: 'Pdf' }]; break; }
            case 'T': { this.tiposReporte = [{ id: 'A', name: 'Archivo plano' }, { id: 'D', name: 'Directa' }, { id: 'E', name: 'Excel' }, { id: 'P', name: 'Pdf' }]; break; }
            case 'D': { this.tiposReporte = [{ id: 'D', name: 'Directa' }]; this.valorCampoAdicional = { id: 'D', name: 'Directa' }; break; }//LIQUIDACION
            case 'X': { this.tiposReporte = [{ id: 'E', name: 'Excel' }]; this.valorCampoAdicional = { id: 'E', name: 'Excel' }; break; }//LIQUIDACION
            case 'P': { this.tiposReporte = [{ id: 'P', name: 'Pdf' }]; this.valorCampoAdicional = { id: 'P', name: 'Pdf' }; break; }  //LIQUIDACION
            case 'A': { this.tiposReporte = [{ id: 'A', name: 'Archivo plano' }]; this.valorCampoAdicional = { id: 'A', name: 'Archivo plano' }; break; }
            case 'E': { this.tiposReporte = [{ id: 'D', name: 'Directa' }, { id: 'E', name: 'Excel' }]; break; }
            case 'F': { this.tiposReporte = [{ id: 'P', name: 'Pdf' }, { id: 'D', name: 'Directa' }]; break; }
            case 'C': { this.tiposReporte = [{ id: 'E', name: 'Excel' }, { id: 'P', name: 'Pdf' }]; break; }
        };
    }

    prepararWizard() {
        this.stepClass = this.formulario.tipo == 'A' ? 'steps-custom' : 'steps-custom-click';
        this.paso = 0;
        this.ordenGrob = this.formulario.regiones[0].orden;
        let tamano: number = this.formulario.regiones.length;
        if (tamano == 1) {
            this.stepClass += ' tamano1'
        } else if (tamano == 2) {
            this.stepClass += ' tamano2'
        } else if (tamano == 3) {
            this.stepClass += ' tamano3'
        } else if (tamano == 4) {
            this.stepClass += ' tamano4'
        } else if (tamano == 5) {
            this.stepClass += ' tamano5'
        } else if (tamano == 6) {
            this.stepClass += ' tamano6'
        } else if (tamano == 7) {
            this.stepClass += ' tamano7'
        } else if (tamano == 8) {
            this.stepClass += ' tamano8'
        } else {
            this.stepClass += ' tamanoOtros'
        }
    }

    //Cierra el formulario
    cerrarEstructuraFormulario() {
        this.cerrarFormulario.emit(this.formulario.recargaPadre);
    }

    //Función que realiza la petición para obtener los datos de recarga de campos que dependen de otro de los elementos del formulario
    verificarFuncionesCampo(parametro: any) {
        if (parametro.tipoObjeto == 'B') {// Por petición del área de requerimientos, no se deben validar los referidos para ejecutar la recarga (08/02/2021)
            this.actualizarValorReferidos(parametro.referidos); //Como un botón puede tener referidos con valores por defecto, se llama a una función que tome el valor de los referidos(A. Gómez 08/07/2021)
            this.recargarCamposFormulario(parametro);
        } if (parametro.tipoObjeto == '4') {
            this.actualizarValorReferidos(parametro.referidosReporte); //Como un botón puede tener referidosReporte con valores por defecto, se llama a una función que tome el valor de los referidosReporte(A. Gómez 08/07/2021)
            this.enviarPeticionBotonReportePdf(parametro);
        } else {
            // Se tomó la decisión de quitar la validación de valor vacío ya que la recarga en vacío debería limpiar los que recargó (28/07/2020)
            if (parametro.recargaObjeto == 'S' && (!parametro.referidos || parametro.referidos.length < 1 || this.referidosLlenos(parametro.referidos))) {
                if (this.jsonFormulario.tipo == 'G' && this.formulario.campoProcesa == parametro.nombre) {//Formulario de consulta generalizada
                    this.enviarPeticionProcesoGeneralizada(parametro);
                } else {//Demás formularios
                    this.recargarCamposFormulario(parametro);
                }
            }
            if (this.esReferido(parametro, this.listaCamposReferidos)) {
                let campos = this.asignarValorReferido(parametro);//Retorna los campos que tienen los referidos
                for (let i = 0; i < campos.length; i++) {
                    if (parametro.nombre != campos[i].nombre) {
                        this.verificarFuncionesCampo(campos[i]);//Pruebas
                    }
                }
            }
            if (parametro.bloquea_campos == 'S' && (!parametro.referidosBloqueo || parametro.referidosBloqueo.length < 1 || this.referidosBloqueoLlenos(parametro.referidosBloqueo))) {
                this.cargarDatosBloqueoOcultar(parametro)
            }
            // if(this.esReferidoBloqueo(parametro,this.listaCamposReferidosBloqueo)){
            if (this.esReferido(parametro, this.listaCamposReferidosBloqueo)) {
                let campos = this.asignarValorReferidoBloqueo(parametro);//Retorna el campo que tiene los referidos
                for (let i = 0; i < campos.length; i++) {
                    this.cargarDatosBloqueoOcultar(campos[i]);
                }
            }
            // if(this.esReferidoReporte(parametro)){
            if (this.esReferido(parametro, this.listaReferidosReporte)) {
                this.asignarValorReferidoReporte(parametro);
            }
            if (parametro.evento != null && parametro.evento.comando != null && parametro.evento.campos != null) {//Verifico si maneja evento
                this.llenarCamposEvento(parametro.evento.campos, parametro.evento.comando);
            }
        }
    }

    /**
     * Método que actualiza el valor de los referidos recibidos.
     * @author Alejo Gómez
     * @param referidos  - List<>
     * @returns referidos
     */
    actualizarValorReferidos(referidos: any) {
        let campo: any;
        if (referidos && referidos.length > 0) {
            for (let i = 0; this.formulario.regiones && i < this.formulario.regiones.length; i++) {//Recorro las regiones ->Grobs
                for (let j = 0; this.formulario.regiones[i].divisiones && j < this.formulario.regiones[i].divisiones.length; j++) {//Divisiones -> fists
                    for (let k = 0; this.formulario.regiones[i].divisiones[j].parametros && k < this.formulario.regiones[i].divisiones[j].parametros.length; k++) {//Parámetros -> dhtms
                        campo = this.formulario.regiones[i].divisiones[j].parametros[k];
                        let l = 0;
                        while (l < referidos.length) {
                            if (referidos[l].nombre == campo.nombre) {
                                referidos[l].valor = campo.valor;
                                l = referidos.length;
                            }
                            l++;
                        }
                    }
                }
            }
        }
        return referidos;
    }

    //Función que verifica
    referidosLlenos(referidos: any) {
        let estanLlenos: boolean = true;
        let i = 0;
        while (estanLlenos && i < referidos.length) {
            if (referidos[i].nombre != 'idsesi' && referidos[i].nombre != 'idusua' && referidos[i].nombre != 'idempresa' && (!referidos[i].valor || referidos[i].valor == '')) {
                if (this.accion == 'A') {//Solo verifica valores por defecto cuando es una adición o nuevo registro
                    for (let j = 0; j < this.listaCamposDefecto.length; j++) {//Verifico que los referidos no estén en el array de campos que tienen un valor por defecto
                        if (this.listaCamposDefecto[j].nombre == referidos[i].nombre) {
                            referidos[i].valor = this.listaCamposDefecto[j].valor;
                            j = this.listaCamposDefecto.length;
                        }
                    }
                    if (!referidos[i].valor || referidos[i].valor == '') {
                        estanLlenos = false;
                    }
                } else {
                    estanLlenos = false;
                }
            }
            i++;
        }
        return estanLlenos;
    }

    referidosBloqueoLlenos(referidosBloqueo: any) {
        let estanLlenos: boolean = true;
        let i = 0;
        while (estanLlenos && i < referidosBloqueo.length) {
            if (referidosBloqueo[i].nombre != 'idsesi' && referidosBloqueo[i].nombre != 'idusua' && referidosBloqueo[i].nombre != 'idempresa' && referidosBloqueo[i].valor == '') {
                estanLlenos = false;
            }
            i++
        }
        return estanLlenos;
    }

    //Función que indica si un campo es referido por otro
    esReferido(parametro: any, arrayCampos: any) {
        let referido: boolean = false;
        if (arrayCampos && arrayCampos.length >= 1) {
            let i = 0;
            while (!referido && i < arrayCampos.length) {
                if (arrayCampos[i] == parametro.nombre) {
                    referido = true;
                }
                i++;
            }
        }
        return referido;
    }

    // ejecutarRecargaCampos(parametro: any, arrayCampos: any) {
    //     if (parametro.valor != '') {//Recarga de objeto
    //         if ((parametro.recargaObjeto == 'S' && (parametro.referidos == null || parametro.referidos.length < 1)) || (parametro.recargaObjeto == 'S' && parametro.valor != '' && this.referidosLlenos(parametro.referidos))) {
    //             this.recargarCamposFormulario(parametro);
    //         }
    //         //Debo verificar si recarga pero también si es referido de otro campo
    //         if (this.esReferido(parametro,arrayCampos) != null) {
    //             let campo = this.asignarValorReferido(parametro);//Retorna el campo que tiene los referidos
    //             if (campo != null && campo.valor != '') {
    //                 this.recargarCamposFormulario(campo);
    //             }
    //         }
    //     }
    // }

    //Función que llena la lista de referidos y de estar llena, retorna el campo que los contiene
    asignarValorReferido(parametro: any) {
        let campo: any;
        let llenos: number = 0;
        let listaCamposRecargar: Array<any> = [];
        let puedeEjecutarRecarga: boolean;//Bandera que indica si puede estar en la lista que recarga, esto con el fin de evitar que se ejecuten recargas de campos que ya tenian los referidos llenos y no necesariamente estan implicados en esta recarga
        for (let i = 0; this.formulario.regiones && i < this.formulario.regiones.length; i++) {//Recorro las regiones ->Grobs
            for (let j = 0; this.formulario.regiones[i].divisiones && j < this.formulario.regiones[i].divisiones.length; j++) {//Divisiones -> fists
                for (let k = 0; this.formulario.regiones[i].divisiones[j].parametros && k < this.formulario.regiones[i].divisiones[j].parametros.length; k++) {//Parámetros -> dhtms
                    campo = this.formulario.regiones[i].divisiones[j].parametros[k];
                    if ((campo.recargaObjeto == 'S' || campo.tipoObjeto == 'B' || campo.tipoObjeto == '4') && campo.referidos && campo.referidos.length >= 1) {
                        llenos = 0;
                        puedeEjecutarRecarga = false;
                        for (let l = 0; l < campo.referidos.length; l++) {
                            if (campo.referidos[l].nombre == parametro.nombre) {
                                this.formulario.regiones[i].divisiones[j].parametros[k].referidos[l].valor = parametro.valor;
                                puedeEjecutarRecarga = true;
                            }
                            if (campo.referidos[l].nombre == 'idsesi' || campo.referidos[l].nombre == 'idusua' || campo.referidos[l].nombre == 'idempresa' || campo.referidos[l].valor != '') {//Cuento los referidos para saber si ya se puede realizar la petición de recarga
                                llenos++;
                            }
                        }
                        if (campo.valor && puedeEjecutarRecarga && llenos == campo.referidos.length) { // Verifico que el parametro de referidos tenga un valor para que pueda ejecutar la recarga
                            listaCamposRecargar.push(campo);//Solo retorno los campos que tienen los referidos llenos
                        }
                    }
                }
            }
        }
        return listaCamposRecargar;
    }

    //Función que llena la lista de referidos y de estar llena, retorna el campo que los contiene
    asignarValorReferidoBloqueo(parametro: any) {
        let campo: any;
        let llenos: number = 0;
        let listaCampos: Array<any> = [];
        let puedeEjecutarBloqueo: boolean;//Bandera que indica si puede estar en la lista de camposs que bloqquean otros campos en el momento de que los llena un parametro que es referio en otros
        for (let i = 0; this.formulario.regiones && i < this.formulario.regiones.length; i++) {//Recorro las regiones ->Grobs
            for (let j = 0; this.formulario.regiones[i].divisiones && j < this.formulario.regiones[i].divisiones.length; j++) {//Divisiones -> fists
                for (let k = 0; this.formulario.regiones[i].divisiones[j].parametros && k < this.formulario.regiones[i].divisiones[j].parametros.length; k++) {//Parámetros -> dhtms
                    campo = this.formulario.regiones[i].divisiones[j].parametros[k];
                    if (campo.bloquea_campos == 'S' && campo.referidosBloqueo != null && campo.referidosBloqueo.length >= 1) {
                        llenos = 0;
                        puedeEjecutarBloqueo = false;
                        for (let l = 0; l < campo.referidosBloqueo.length; l++) {
                            if (campo.referidosBloqueo[l].nombre == parametro.nombre) {
                                this.formulario.regiones[i].divisiones[j].parametros[k].referidosBloqueo[l].valor = parametro.valor;
                                puedeEjecutarBloqueo = true;
                            }
                            if (campo.referidosBloqueo[l].valor != '') {
                                llenos++;
                            }
                        }
                        if (puedeEjecutarBloqueo && llenos == campo.referidosBloqueo.length) {
                            listaCampos.push(campo);
                        }
                    }
                }
            }
        }
        return listaCampos;
    }

    asignarValorReferidoReporte(parametro: any) {
        let campo: any;
        for (let i = 0; i < this.formulario.regiones.length; i++) {//Recorro las regiones ->Grobs
            for (let j = 0; j < this.formulario.regiones[i].divisiones.length; j++) {//Divisiones -> fists
                for (let k = 0; k < this.formulario.regiones[i].divisiones[j].parametros.length; k++) {//Parámetros -> dhtms
                    campo = this.formulario.regiones[i].divisiones[j].parametros[k];
                    if (campo.referidosReporte && campo.referidosReporte.length >= 1) {
                        for (let l = 0; l < campo.referidosReporte.length; l++) {
                            if (campo.referidosReporte[l].nombre == parametro.nombre) {
                                this.formulario.regiones[i].divisiones[j].parametros[k].referidosReporte[l].valor = parametro.valor;
                                l = campo.referidosReporte.length;
                            }
                        }
                    }
                }
            }
        }
    }





    //Función que realiza la recarga de los demás campos del formulario cuando un campo es referido o tiene recarga
    recargarCamposFormulario(parametro: any) {
        this.loading = true;
        let llamado = { "accion": "recargar", parametros: { "codigoSesion": this.codigoSesion, "formulario": this.idFormulario, "campo": parametro.nombre, "valor": parametro.valor, "referidos": parametro.referidos } };
        this._servicioPeticiones.enviarObjetoBus(llamado)
            .subscribe( //suscribe la peticion http para estar pendiente de la respuesta, funciona como un callback
                response => { //si la peticion fue exitosa ejecuta la funcion response
                    let respuesta = response[0].valores;
                    if (respuesta) {
                        if (respuesta.resultado == 0) {//Si No obtiene problemas
                            if (respuesta.campos) {
                                this.llenarDatosRecargar(respuesta.campos, true);
                            }
                            if (respuesta.formularios) {
                                this.abrirFormulariosRecarga(respuesta.formularios);
                            }
                            if (parametro.tipoObjeto == 'B' && parametro.recargaPadre) {
                                setTimeout(() => {
                                    this.enviarSenalRecargar(null);
                                }, 100);
                            }
                        } else {
                            this._servicioDatosGenerales.enviarMensaje('E', respuesta['mensaje']);
                        }
                    } else {
                        this._servicioDatosGenerales.enviarMensaje('E', 'Ocurrió un error inesperado, por favor contáctese con el administrador');
                    }
                    this.loading = false;
                },
                error => {
                    this.loading = false;
                    this._servicioDatosGenerales.enviarMensaje('E', this._servicioDatosGenerales.getMensajeError());
                }
            )
    }

    public camposFormulario: Array<campoFormulario> = new Array();
    /**
     * Función que se ejecuta en la recarga cuando se necesita que se abra un formulario desde un dhtm
     * @param formularios Información suficiente para preparar la estructura que se necesita para abrir un diálogo
     */
    abrirFormulariosRecarga(formularios: FormularioRecarga[]) {
        this.camposFormulario = this.crearArregloCamposFormulario();
        // TODO Revisar a futuro si es necesario que muestre varios dialogos y realizar la implementación
        for (let i = 0; i < formularios.length; i++) {
            this.parametrosEnviarFormularioDialogo = { constantes: formularios[i].parametros, id: null, valores: [], modo: 1, chtmDirecto: true, idchtm: formularios[i].idchtm, titulo: formularios[i].titulo };
            this.dialogoAdicional = 'dialogoTabla';
        }
    }


    crearArregloCamposFormulario() {
        let campos: Array<campoFormulario> = new Array();
        for (let i = 0; this.formulario.regiones && i < this.formulario.regiones.length; i++) {//Recorro las regiones ->Grobs
            for (let j = 0; this.formulario.regiones[i].divisiones && j < this.formulario.regiones[i].divisiones.length; j++) {//Divisiones -> fists
                for (let k = 0; this.formulario.regiones[i].divisiones[j].parametros && k < this.formulario.regiones[i].divisiones[j].parametros.length; k++) {//Parámetros -> dhtms
                    campos.push(this.formulario.regiones[i].divisiones[j].parametros[k]);
                }
            }
        }
        return campos;
    }

    //-----------------------    LIQUIDACION
    //Función que realiza las peticiones desde un campo para los formularios de consulta generalizada
    enviarPeticionProcesoGeneralizada(parametro: any) {
        this.loading = true;
        let llamado = { "accion": "recargar", parametros: { "codigoSesion": this.codigoSesion, "formulario": this.idFormulario, "campo": parametro.nombre, "valor": parametro.valor, "referidos": parametro.referidos } };
        this._servicioPeticiones.enviarObjetoBus(llamado)
            .subscribe( //suscribe la peticion http para estar pendiente de la respuesta, funciona como un callback
                response => { //si la peticion fue exitosa ejecuta la funcion response
                    let respuesta = response[0].valores;
                    if (respuesta.resultado == 0) {//Si No obtiene problemas al traer el menú
                        let campos: any = respuesta.campos;
                        let valorAuxiliar: string = '';
                        let resultadoProceso: string = '';
                        this.idRegistroFormulario = '0';

                        this.loading = false;
                        for (let i = 0; i < campos.length; i++) {
                            if (campos[i].nombre == 'id') {
                                this.idRegistroFormulario = campos[i].valor;
                            } else if (campos[i].nombre == 'aux1') {//Utiliza el campo auxiliar para realizar la consulta de los hijos no lineales
                                valorAuxiliar = campos[i].valor;
                            } else if (campos[i].nombre == ':dato' || campos[i].nombre == 'resultado') {
                                this.registroFormulario = null;
                                this.tablasHijos = null;
                                // this.formulario = new Formulario(this.jsonFormulario)
                                this.accion = 'A';
                                resultadoProceso = campos[i].valor;
                            } else if ((campos[i].nombre == 'mensaje_error' || campos[i].nombre == 'mensaje_alerta' || campos[i].nombre == 'mensaje_informacion' || campos[i].nombre == 'mensaje_exito') && campos[i].valor && campos[i].valor != '') {
                                this.mostrarMensajePersonalizado(campos[i].nombre, campos[i].valor);
                            }
                        }
                        if (this.idRegistroFormulario != '0' && valorAuxiliar != '' && resultadoProceso == 'S') {
                            this.busquedaProcesoGeneralizado(this.idRegistroFormulario, valorAuxiliar);
                        } else {
                            this.limpiarCamposFormulario(parametro);
                        }
                    } else {
                        this._servicioDatosGenerales.enviarMensaje('E', respuesta['mensaje']);
                        this.loading = false;
                    }
                },
                error => {
                    this.loading = false;
                    this._servicioDatosGenerales.enviarMensaje('E', this._servicioDatosGenerales.getMensajeError());
                }
            )
    }

    limpiarCamposFormulario(parametro: any) {
        for (let i = 0; i < this.formulario.regiones.length; i++) {
            for (let j = 0; j < this.formulario.regiones[i].divisiones.length; j++) {
                for (let k = 0; k < this.formulario.regiones[i].divisiones[j].parametros.length; k++) {
                    if (this.formulario.regiones[i].divisiones[j].parametros[k].nombre != parametro.nombre) {
                        let l = 0;
                        let esReferido: boolean = false;
                        while (l < parametro.referidos.length) {
                            if (parametro.referidos[l].nombre == this.formulario.regiones[i].divisiones[j].parametros[k].nombre) {
                                esReferido = true;
                                l = parametro.referidos.length;
                            }
                            l++;
                        }
                        if (!esReferido) {
                            this.formulario.regiones[i].divisiones[j].parametros[k].valor = "";
                        }
                    }
                }
            }
        }
    }

    busquedaProcesoGeneralizado(id: string, valorAuxiliar: string) {
        this.loading = true;
        this.idRegistroFormulario = id;
        let llamado = { "accion": "busqueda", parametros: { "codigoSesion": this.codigoSesion, "formulario": this.idFormulario, "identificador": this.idRegistroFormulario, "valorAuxiliar": valorAuxiliar, "identificadorPadre": this.idRegistroPadre, "campos": [], "palabraClave": '', "pagina": '0' } };
        this._servicioPeticiones.enviarObjetoBus(llamado)
            .subscribe( //suscribe la peticion http para estar pendiente de la respuesta, funciona como un callback
                response => { //si la peticion fue exitosa ejecuta la funcion response
                    let respuesta = response;
                    let resultado: any;
                    for (let i = 0; i < respuesta.length; i++) {
                        if (respuesta[i].codigPaso == 'I') {
                            resultado = respuesta[i].valores;
                            if (resultado.resultado == 0) {
                                this.registroFormulario = resultado.registro;
                                if (this.registroFormulario) {
                                    this.tablasHijos = this.registroFormulario.datosHijos;
                                    this.registroFormulario.campos.push({ 'nombre': 'secuencia', 'valor': valorAuxiliar })//Adiciono la secuancia en los campos, se debe crear un dhtm llamado secuencia si quiero que el formlario lo tenga en un dhtm
                                    this.registroFormulario.campos.push({ 'nombre': 'identificador', 'valor': this.idRegistroFormulario });
                                    this.llenarDatosFormulario(this.registroFormulario.campos, true, false);
                                    this.accion = 'M';//Modificacion
                                }
                            } else {
                                this._servicioDatosGenerales.enviarMensaje('E', 'Ocurrió un error inesperado al intentar cargar los datos del formulario');
                            }
                        }
                    }
                    this.loading = false;
                },
                error => {
                    this.loading = false;
                    this.idRegistroFormulario = '';
                    // this.identificador = '';
                    this._servicioDatosGenerales.enviarMensaje('E', this._servicioDatosGenerales.getMensajeError());
                }
            )
    }
    //LIQUIDACION-------------
    //Función que toma los valores de los campos a recargar y les asigna la información
    //Recibe el parametro debeRecargar, ésto se realiza debido a que cuando es busqueda de registro unico el realiza todas las recargas necesarias, en este caso no debería ejecutar recargas nuevamente
    llenarDatosRecargar(campos: any, debeRecargar: boolean) {
        let campo: any;
        let imprimio = false; //Bandera que se creó con el fin de que no se impriman varias veces el mismo recibo
        let mostroMensaje = false; // Bandera que se creó para que no se muestren mensajes varias veces los mensajes;
        if (this.formulario.regiones) {
            for (let j = 0; j < this.formulario.regiones.length; j++) {//Recorro las regiones ->Grobs
                if (this.formulario.regiones[j].divisiones) {
                    for (let k = 0; k < this.formulario.regiones[j].divisiones.length; k++) {//Divisiones -> fists
                        if (this.formulario.regiones[j].divisiones[k].parametros) {
                            for (let l = 0; l < this.formulario.regiones[j].divisiones[k].parametros.length; l++) {//Parámetros -> dhtms
                                campo = this.formulario.regiones[j].divisiones[k].parametros[l];
                                for (let i = 0; i < campos.length; i++) {
                                    // if(j==0 && k==0  && l == 0){ // Para verificar una sola vez por recarga los mensajes y reportes
                                    if (!imprimio && (campos[i].nombre == 'mensaje_error' || campos[i].nombre == 'mensaje_alerta' || campos[i].nombre == 'mensaje_exito' || campos[i].nombre == 'mensaje_informacion') && campos[i].valor && campos[i].valor != '') {
                                        this.mostrarMensajePersonalizado(campos[i].nombre, campos[i].valor);
                                        mostroMensaje = true;
                                    } else if (!mostroMensaje && campos[i].nombre == 'rutaReporte' && campos[i].valor && campos[i].valor != '') {
                                        let ruta = this._servicioDatosGenerales.getUrlBase() + campos[i].valor;
                                        setTimeout(() => { // Para esperar la construcción
                                            this.enviarPeticionReporte(ruta); imprimio = true;
                                        }, 100);
                                    } else if (campo.nombre == campos[i].nombre) {
                                        if (campo.tipoObjeto == 'S' || campo.tipoObjeto == 'N' || campo.tipoObjeto == 'L' || campo.tipoObjeto == 'P' || campo.tipoObjeto == 'C') {//Selects numerico y de texto
                                            //Este if fué una decision que se tomó debido a que al ser una recarga de valor no debería limpiar las opciones a menos que pongan a recargar opciones (que no es el deber ser) al tiempo 30/06/2020
                                            if (campos[i].naturaleza.toUpperCase() == 'OPCIONES') {
                                                this.formulario.regiones[j].divisiones[k].parametros[l].opciones = campos[i].opciones ? campos[i].opciones : [];
                                                if (campo.tipoObjeto == 'N') {
                                                    this.formulario.regiones[j].divisiones[k].parametros[l].opciones.unshift({ "id": "0", "name": 'Seleccione' });
                                                } else if (campo.tipoObjeto == 'S') {
                                                    this.formulario.regiones[j].divisiones[k].parametros[l].opciones.unshift({ "id": "", "name": "Seleccione" });
                                                }
                                            }
                                            //Esta validación se realiza ya que a un select solo se le asignará valor en recargas normales, NÓ en búsqueda de registro ya que en este caso solo se pondrá el valor que venga de la consulta
                                            if (debeRecargar) {
                                                if (campos[i].naturaleza.toUpperCase() == 'VALOR') {
                                                    this.formulario.regiones[j].divisiones[k].parametros[l].valor = campos[i].valor
                                                } else { // Se limpia el valor en el caso de ser recarga de opciones
                                                    if (campo.tipoObjeto == 'N') {
                                                        this.formulario.regiones[j].divisiones[k].parametros[l].valor = '0';
                                                        if (this.formulario.regiones[j].divisiones[k].parametros[l].opciones[0] == '0') {
                                                            this.formulario.regiones[j].divisiones[k].parametros[l].contenido = this.formulario.regiones[j].divisiones[k].parametros[l].opciones[0];
                                                        } else {
                                                            this.formulario.regiones[j].divisiones[k].parametros[l].contenido = null;
                                                        }
                                                    } else {
                                                        this.formulario.regiones[j].divisiones[k].parametros[l].valor = '';
                                                        if (this.formulario.regiones[j].divisiones[k].parametros[l].opciones[0] == '') {
                                                            this.formulario.regiones[j].divisiones[k].parametros[l].contenido = this.formulario.regiones[j].divisiones[k].parametros[l].opciones[0];
                                                        } else {
                                                            this.formulario.regiones[j].divisiones[k].parametros[l].contenido = null;
                                                        }
                                                    }
                                                }
                                                this.verificarFuncionesCampo(this.formulario.regiones[j].divisiones[k].parametros[l]);//Al recargar el campo, se debe verificar las funciones que éste tiene para ejecutarlas con el nuevo valor
                                                if (this.formulario.regiones[j].divisiones[k].parametros[l].opciones && campos[i].valor && ((campo.tipoObjeto != 'N' && campos[i].valor != '') || (campo.tipoObjeto == 'N' && campos[i].valor != '0'))) {//campos[i].opciones
                                                    for (let m = 0; m < this.formulario.regiones[j].divisiones[k].parametros[l].opciones.length; m++) {
                                                        if (this.formulario.regiones[j].divisiones[k].parametros[l].opciones[m].id == campos[i].valor) {
                                                            this.formulario.regiones[j].divisiones[k].parametros[l].contenido = this.formulario.regiones[j].divisiones[k].parametros[l].opciones[m];
                                                        }
                                                    }
                                                } else {
                                                    this.formulario.regiones[j].divisiones[k].parametros[l].contenido = null; // Verificar si esto es necesario
                                                }
                                            }
                                        } else if (campo.tipoObjeto == 'I') {//Autocompletar
                                            if (campos[i].naturaleza.toUpperCase() == 'OPCIONES') {
                                                this.formulario.regiones[j].divisiones[k].parametros[l].opcionesAutocompletar = campos[i].opcionesAutocompletar;
                                            } else {
                                                this.formulario.regiones[j].divisiones[k].parametros[l].valor = campos[i].valor;
                                            }
                                        } else if (campo.tipoObjeto == '1') {//Lista de selección avanzada
                                            this.formulario.regiones[j].divisiones[k].parametros[l].opcionesListaAvanzada = campos[i].opciones;
                                        } else if (campo.tipoObjeto == 'C') {//Checks
                                            this.formulario.regiones[j].divisiones[k].parametros[l].opcionesCheck = campos[i].opciones;
                                        } else {//Otros campos del formulario
                                            this.formulario.regiones[j].divisiones[k].parametros[l].valor = campos[i].valor;
                                            if (debeRecargar) {
                                                this.verificarFuncionesCampo(this.formulario.regiones[j].divisiones[k].parametros[l]);//Al recargar el campo, se debe verificar las funciones que éste tiene para ejecutarlas con el nuevo valor
                                            }
                                            i = campos.length;// Se pone acá ya que los otros tipos de campos pueden hacer recarga de opciones y valor en una sola
                                        }
                                        // i = campos.length;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    //Petición para tomar los datos de los fist a ocultar y campos a bloquear
    cargarDatosBloqueoOcultar(parametro: any) {
        this.loading = true;
        let llamado = { "accion": "bloquearOcultar", parametros: { "codigoSesion": this.codigoSesion, "formulario": this.idFormulario, "campo": parametro.nombre, "valor": parametro.valor, "referidos": parametro.referidosBloqueo } };
        this._servicioPeticiones.enviarObjetoBus(llamado)
            .subscribe( //suscribe la peticion http para estar pendiente de la respuesta, funciona como un callback
                response => { //si la peticion fue exitosa ejecuta la funcion response
                    let respuesta = response[0].valores;
                    if (respuesta.resultado == 0) {//Si No obtiene problemas al traer el menú
                        this.cambiarPropiedadesBloqueoOculto(respuesta)
                    } else {
                        this._servicioDatosGenerales.enviarMensaje('E', respuesta['mensaje']);
                    }
                    this.loading = false;
                },
                error => {
                    this.loading = false;
                    this._servicioDatosGenerales.enviarMensaje('E', this._servicioDatosGenerales.getMensajeError());
                }
            )
    }

    //Función que oculta los fist o bloquea los campos del formulario
    cambiarPropiedadesBloqueoOculto(valores: any) {
        let bandera: number = 0;
        for (let i = 0; i < this.formulario.regiones.length; i++) {//Recorro las regiones ->Grobs
            for (let j = 0; this.formulario.regiones[i].divisiones && j < this.formulario.regiones[i].divisiones.length; j++) {//Divisiones -> fists
                if (valores.fist != null) {
                    if (valores.fist.mostrar != null && valores.fist.mostrar.length >= 1 && valores.fist.mostrar[0] != '') {
                        for (let l = 0; l < valores.fist.mostrar.length; l++) {
                            if (valores.fist.mostrar[l] == this.formulario.regiones[i].divisiones[j].id) {
                                this.formulario.regiones[i].divisiones[j].oculto = false;
                                l = valores.fist.mostrar.length;
                            }
                        }
                    }
                    if (valores.fist.ocultar != null && valores.fist.ocultar.length >= 1 && valores.fist.ocultar[0] != '') {
                        for (let l = 0; l < valores.fist.ocultar.length; l++) {
                            if (valores.fist.ocultar[l] == this.formulario.regiones[i].divisiones[j].id) {
                                this.formulario.regiones[i].divisiones[j].oculto = true;
                                l = valores.fist.ocultar.length;
                            }
                        }
                    }
                }
                if (valores.campos != null) {
                    if ((valores.campos.bloquear != null && valores.campos.bloquear.length >= 1) || (valores.campos.desbloquear != null && valores.campos.desbloquear.length >= 1)) {
                        for (let k = 0; k < this.formulario.regiones[i].divisiones[j].parametros.length; k++) {//Parámetros -> dhtms
                            if (valores.campos.bloquear != null && (valores.campos.bloquear.length > 1 || (valores.campos.bloquear.length == 1 && valores.campos.bloquear[0] != ''))) {
                                for (let l = 0; l < valores.campos.bloquear.length; l++) {
                                    if (valores.campos.bloquear[l] == this.formulario.regiones[i].divisiones[j].parametros[k].nombre) {
                                        this.formulario.regiones[i].divisiones[j].parametros[k].bloqueado = true;
                                        l = valores.campos.bloquear.length;
                                        bandera = 1;
                                    }
                                }
                            }
                            if (bandera == 0 && valores.campos.desbloquear != null && (valores.campos.desbloquear.length > 1 || (valores.campos.desbloquear.length == 1 && valores.campos.desbloquear[0] != ''))) {
                                for (let l = 0; l < valores.campos.desbloquear.length; l++) {
                                    if (valores.campos.desbloquear[l] == this.formulario.regiones[i].divisiones[j].parametros[k].nombre) {
                                        this.formulario.regiones[i].divisiones[j].parametros[k].bloqueado = false;
                                        l = valores.campos.desbloquear.length;
                                    }
                                }
                            }
                            bandera = 0;
                        }
                    }
                }
            }
        }
    }

    //Función que realiza la recarga de los demás campos del formulario cuando un campo es referido o tiene recarga
    enviarPeticionBotonReportePdf(parametro: any) {
        this.loading = true;
        let llamado = { "accion": "obtenerRutaReporteBoton", parametros: { "codigoSesion": this.codigoSesion, "formulario": this.idFormulario, "campo": parametro.nombre, "valor": parametro.valor, "referidos": parametro.referidosReporte } };
        this._servicioPeticiones.enviarObjetoBus(llamado)
            .subscribe( //suscribe la peticion http para estar pendiente de la respuesta, funciona como un callback
                response => { //si la peticion fue exitosa ejecuta la funcion response
                    let respuesta = response[0].valores;
                    if (respuesta.resultado == 0) {//Si No obtiene problemas al traer el menú
                        if (respuesta.ruta != null) {
                            let ruta = this._servicioDatosGenerales.getUrlBase();
                            if (this.formulario.tipo == 'A') {//Si el formulario es Asistente(Wizard) se debe llamar el reporte de una manera diferente.
                                this.enviarPeticionReporteWizard(ruta + respuesta.ruta);
                            } else {
                                this.enviarPeticionReporte(ruta + respuesta.ruta);
                            }
                        } else if (respuesta.mensaje_exito != null) {
                            this._servicioDatosGenerales.enviarMensaje('C', respuesta.mensaje_exito);
                        } else if (respuesta.mensaje_error != null) {
                            this._servicioDatosGenerales.enviarMensaje('E', respuesta.mensaje_error);
                        } else if (respuesta.mensaje_alerta != null) {
                            this._servicioDatosGenerales.enviarMensaje('A', respuesta.mensaje_alerta);
                        } else if (respuesta.mensaje_informacion) {
                            this._servicioDatosGenerales.enviarMensaje('I', respuesta.mensaje_informacion);
                        }
                    } else {
                        this._servicioDatosGenerales.enviarMensaje('E', respuesta['mensaje']);
                    }
                    this.loading = false;
                },
                error => {
                    this.loading = false;
                    this._servicioDatosGenerales.enviarMensaje('E', this._servicioDatosGenerales.getMensajeError());
                }
            )
    }

    llenarCamposEvento(campos: any, evento: string) {
        let campo: any;
        this.campos = [];
        for (let i = 0; i < this.formulario.regiones.length; i++) {//Recorro las regiones ->Grobs
            for (let j = 0; j < this.formulario.regiones[i].divisiones.length; j++) {//Divisiones -> fists
                for (let k = 0; k < this.formulario.regiones[i].divisiones[j].parametros.length; k++) {//Parámetros -> dhtms
                    campo = this.formulario.regiones[i].divisiones[j].parametros[k];
                    for (let l = 0; l < campos.length; l++) {
                        if (campo.nombre == campos[l]) {
                            this.campos.push(new campoFormulario(this.formulario.regiones[i].divisiones[j].parametros[k].nombre, '',
                                this.formulario.regiones[i].divisiones[j].parametros[k].valor))
                        }
                    }
                }
            }
        }
        this.enviarPeticionEvento(evento);
    }

    enviarPeticionEvento(evento: string) {
        this.loading = true;
        let llamado = { "accion": evento, parametros: { "codigoSesion": this.codigoSesion, "formulario": this.idFormulario, "campos": this.campos } };
        this._servicioPeticiones.enviarObjetoBus(llamado)
            .subscribe( //suscribe la peticion http para estar pendiente de la respuesta, funciona como un callback
                response => { //si la peticion fue exitosa ejecuta la funcion response
                    this.loading = false;
                },
                error => {
                    this.loading = false;
                    this._servicioDatosGenerales.enviarMensaje('E', this._servicioDatosGenerales.getMensajeError());
                }
            )
    }

    //Función que envía el json con los campos llenos y validados en front-end para validarlos en el servicio
    enviarJsonFormularioValidacionImportacion() {
        this.validarDatosCampos();
        if (this.formularioValido) {
            if (this.campos.length >= 1) {
                this.loading = true;
                let llamado = { accion: "validar", parametros: { "formulario": this.idFormulario, "region": "0", "codigoSesion": this.codigoSesion, "campos": this.campos } };
                this._servicioPeticiones.enviarObjetoBus(llamado)
                    .subscribe( //suscribe la peticion http para estar pendiente de la respuesta, funciona como un callback
                        response => { //si la peticion fue exitosa ejecuta la funcion response
                            let respuesta = response[0].valores;
                            this.loading = false;
                            if (respuesta.resultado == 0) {//Si No obtiene problemas
                                this.importarArchivoCargado();
                            } else {//Quiere decir que algun campo contiene errores
                                if (respuesta.datos) {
                                    if (respuesta.datos.campos) {
                                        this.obtenerMensajesErrorCampos(respuesta.datos.campos);
                                    }
                                    let tipo = respuesta.datos.procedimiento.tipo;
                                    let mensaje = respuesta.datos.procedimiento.mensaje;
                                    if (tipo && tipo != '') {
                                        this.mostrarMensajePersonalizado(tipo, mensaje);
                                    } else {
                                        this._servicioDatosGenerales.enviarMensaje('A', 'Se encuentran errores en los datos del formulario ó de validación');
                                    }
                                } else {
                                    this._servicioDatosGenerales.enviarMensaje('A', 'Se encuentran errores en los datos del formulario ó de validación');
                                }
                            }
                        },
                        error => {
                            this._servicioDatosGenerales.enviarMensaje('E', this._servicioDatosGenerales.getMensajeError());
                            this.loading = false;
                        }
                    )
            } else {
                this.importarArchivoCargado();
            }
        } else {
            this._servicioDatosGenerales.enviarMensaje('A', 'Hay campos del formulario que no se diligenciaron de manera adecuada');
        }
    }

    //Función que valida los datos antes de envíarlos y prepara la estructura de envío
    validarDatosCampos() {
        this.rutaReporte = '';//LIQUIDACION
        this.formularioValido = true;
        this.campos = [];//Limpio el array de campos
        let pattern: any;//Variable que contendrá la expresion regular para la validacion de los campos
        for (let i = 0; i < this.formulario.regiones.length; i++) {//Recorro las regiones ->Grobs
            for (let j = 0; this.formulario.regiones[i].divisiones && j < this.formulario.regiones[i].divisiones.length; j++) {//Divisiones -> fists
                if (!this.formulario.regiones[i].divisiones[j].oculto) {//No valido los fist ocultos
                    for (let k = 0; this.formulario.regiones[i].divisiones[j].parametros && k < this.formulario.regiones[i].divisiones[j].parametros.length; k++) {//Parámetros -> dhtms
                        let campo = this.formulario.regiones[i].divisiones[j].parametros[k];
                        this.formulario.regiones[i].divisiones[j].parametros[k].mensaje = '';//Lo vaceo por si ya esta validado pero resuelto
                        if (this.formulario.tipo != 'A' && this.formulario.tipo != 'B' && campo.tipoObjeto != 'H' && campo.tipoObjeto != 'C' && campo.tipoObjeto != 'J' && campo.tipoObjeto != 'D' && campo.tipoObjeto != 'M' && campo.tipoObjeto != 'U' && campo.tipoObjeto != 'K' && campo.tipoDato != 'S') {//No valido campos hidden ni para cheks ya que éste riene un valor por defecto
                            if (campo.requerido == 'S' && !campo.manejaFase && campo.valor != '0' && (!campo.valor || campo.valor == '')) {//Validacion de campos requeridos // || (!campo.valor || campo.valor=='' || (campo.tipoObjeto=='N' && campo.valor=='0'))
                                if (campo.mensajeValidacion) { // Pongo mensaje de validación configurado
                                    campo.mensaje = campo.mensajeValidacion;
                                } else { // Pone mensaje por defecto en caso que no tenga mensaje configurado
                                    if (campo.tipoObjeto == 'S' || campo.tipoObjeto == 'N' || campo.tipoObjeto == 'R' || campo.tipoObjeto == 'I') {
                                        campo.mensaje = 'Debe seleccionar un valor';
                                    } else if (campo.tipoObjeto == 'P' || campo.tipoObjeto == 'L') {//Listas
                                        campo.mensaje = 'Debe seleccionar mínimo una opción';
                                    } else {
                                        campo.mensaje = 'Debe ingresar un valor para el campo';
                                    }
                                }
                                this.formularioValido = false;
                            } else if (campo.tipoObjeto == 'T') {//Realiza las demás validaciones
                                if (campo.valor != '' && campo.valor) {
                                    if (campo.tipoDato == 'N' || campo.tipoDato == 'X') {//Valores numéricos y texto con formato numerico
                                        pattern = new RegExp('^[0-9]+([,][0-9]+)?$') //Número con decimales
                                        if (!pattern.test(campo.valor)) {//Realiza la validación utlizando la expresion regular
                                            this.formulario.regiones[i].divisiones[j].parametros[k].mensaje = 'Debe ingresar un valor numérico válido';
                                            this.formularioValido = false;
                                        }
                                    } else if (campo.tipoDato == 'E') {//Correo electrónico
                                        pattern = new RegExp('^[_a-zA-Z0-9-]+(.[_a-zA-Z0-9-]+)*@[a-zA-Z0-9-]+(.[a-zA-Z0-9-]+)*(.[a-zA-Z]{2,4})$');
                                        if (!pattern.test(campo.valor)) {//Realiza la validación utlizando la expresion regular
                                            this.formulario.regiones[i].divisiones[j].parametros[k].mensaje = 'Debe ingresar un correo electrónico válido';
                                            this.formularioValido = false;
                                        }
                                    }
                                }
                            } else if (campo.tipoObjeto == 'Q' && campo.contenido == '-1') {
                                this.formulario.regiones[i].divisiones[j].parametros[k].mensaje = 'Debe confirmar los cambios en la lista';
                                this.formularioValido = false;
                            }

                        } else if (this.formulario.tipo == 'A' && campo.tipoObjeto == 'Q' && campo.contenido == '-1') {
                            this.formulario.regiones[i].divisiones[j].parametros[k].mensaje = 'Debe confirmar los cambios en la lista';
                            this.formularioValido = false;
                        }
                        //Wizard
                        if (this.formulario.tipo == 'A' && this.formulario.regiones[i].divisiones[j].parametros[k].nombre == this.formulario.campoPaso) {
                            this.formulario.regiones[i].divisiones[j].parametros[k].valor = this.paso + 1;
                        }

                        //Lleno la estructura si el formulario es válido
                        if (this.formularioValido && campo.tipoObjeto != 'J' && campo.tipoObjeto != 'D' && campo.tipoObjeto != 'M' && campo.tipoObjeto != 'B' && campo.tipoObjeto != 'Z') {  //in ['J','D','M','B','Z'])
                            //Adiciono los campos del formulario en la estrucutura de salida
                            this.campos.push(
                                new campoFormulario(this.formulario.regiones[i].divisiones[j].parametros[k].nombre, '',
                                    this.formulario.regiones[i].divisiones[j].parametros[k].valor));
                            if (this.idRegistroFormulario && this.jsonFormulario.tipo == 'C') {//LIQUIDACION
                                this.rutaReporte += '&' + campo.nombre + '=' + campo.valor;
                            }
                        }
                    }
                }
            }
        }
    }

    verificarOperacionRegistro() {
        // Tener en cuanta cuando es WIZARD que la función de validar campos se aprovecha de una vez para asociar el siguiente valor al dhtm de 'paso' correspondiente a
        this.validarDatosCampos();
        if (this.tieneCargaArchivo == true && this.accion == 'A' && this.campoCarga.requerido == 'S' && this.fileInput2.files.length <= 0) {
            if (this.campoCarga.mensajeValidacion) {
                this.campoCarga.mensaje = this.campoCarga.mensajeValidacion;
            } else {
                this.campoCarga.mensaje = 'Debe seleccionar un archivo';
            }
            this.formularioValido = false;
        }
        if (this.formularioValido) {
            if ((this.accion == 'A' && this.formulario.permisos.nuevo == 'S') || (this.accion == 'M' && this.formulario.permisos.editar == 'S')) {
                this.realizarOperacionRegistro();
            } else if ((this.accion == 'A' && (this.formulario.permisos.nuevo == 'A' || this.formulario.permisos.nuevo == 'U')) || (this.accion == 'M' && (this.formulario.permisos.editar == 'A' || this.formulario.permisos.editar == 'U'))) {
                this.dialogoAdicional = 'confirmacionUsuario';
            }
        } else {
            this._servicioDatosGenerales.enviarMensaje('A', 'Hay campos del formulario que no se diligenciaron de manera adecuada');
        }
    }

    //Función para adicionar o modificar un registro
    realizarOperacionRegistro() {
        let accionAnterior: string = '';
        let idRegistroPadreEnviar: string
        let orden: string = (this.formulario.tipo == 'A' || this.formulario.tipo == 'B') ? this.ordenGrob : '0';
        if (this.idRegistroPadre) {
            idRegistroPadreEnviar = this.jsonFormulario.lineal == 'S' ? this.idRegistroPadre : this._servicioOperaciones.tomarValorIdLineal(this.jsonFormulario.campoLineal, this.camposPadre);
        }
        this.loading = true;
        let llamado = { "accion": "adicionarActualizar", "parametros": { "formulario": this.idFormulario, "codigoSesion": this.codigoSesion, "region": orden, "campos": this.campos, "identificadorPadre": idRegistroPadreEnviar, "codigoAutorizacion": this.codigoAutorizacion } };
        this._servicioPeticiones.enviarObjetoBus(llamado)
            .subscribe( //suscribe la peticion http para estar pendiente de la respuesta, funciona como un callback
                response => { //si la peticion fue exitosa ejecuta la funcion response
                    let respuesta = response;
                    let codigoPaso: string;
                    let resultado: any;
                    for (let i = 0; i < respuesta.length; i++) {
                        resultado = respuesta[i];
                        codigoPaso = resultado.codigPaso;
                        if (codigoPaso == 'I') {//Inicial, proceso de validacion
                            if (resultado.valores && resultado.valores.resultado != '0') {
                                //verificar validacion alerta
                                if (!resultado.valores.datos) {
                                    this._servicioDatosGenerales.enviarMensaje('A', resultado.valores.mensaje);
                                } else if (resultado.valores.datos.campos) {
                                    i = 3;
                                    this.obtenerMensajesErrorCampos(resultado.valores.datos.campos);
                                    this._servicioDatosGenerales.enviarMensaje('A', 'Se encuentran errores en los datos del formulario ó de validación');
                                } else if (resultado.valores.datos.procedimiento && resultado.valores.datos.procedimiento.mensaje) {
                                    let mensaje = resultado.valores.datos.procedimiento.mensaje;
                                    let tipo = resultado.valores.datos.procedimiento.tipo;
                                    if (tipo && tipo != '') {
                                        this.mostrarMensajePersonalizado(tipo, mensaje);
                                    } else {
                                        this._servicioDatosGenerales.enviarMensaje('A', mensaje);
                                    }
                                }
                            }
                        } else if (codigoPaso == '2') {//Operacion(Inserción o actualización)
                            if (resultado.valores.resultado == '0') {
                                accionAnterior = this.accion;
                                this.accion = 'M';
                                this.idRegistroFormulario = resultado.valores.identificadorRegistro;
                                if (this.formulario.tipo != 'A' || this.paso >= this.formulario.regiones.length - 1) {
                                    this._servicioDatosGenerales.enviarMensaje('C', 'Proceso realizado correctamente');
                                }
                                if (this.tieneCargaArchivo && this.fileInput2.files.length > 0) { // Si tiene campo de carga pero adicional seleccionó algun archivo
                                    this.importarArchivoCargadoCampo();
                                } else if (this.formulario.cerrarPersistir == 'S') {
                                    this.cerrarEstructuraFormulario();
                                }
                            } else {
                                i = 3;
                                this._servicioDatosGenerales.enviarMensaje('E', resultado.valores.mensaje);
                            }
                            if (this.formulario.tipo == 'A' && this.paso == this.formulario.regiones.length - 1) {
                                this.cerrarEstructuraFormulario();
                            }
                        } else if (codigoPaso == '3') {//Busqueda avanzada
                            if (resultado.valores.resultado == '0') {
                                this.registroFormulario = resultado.valores.registro;
                                if (this.registroFormulario && this.registroFormulario != undefined) {
                                    this.tablasHijos = this.registroFormulario.datosHijos;
                                    this.llenarDatosFormulario(this.registroFormulario.campos, false, accionAnterior == 'A');
                                }
                            } else {
                                this._servicioDatosGenerales.enviarMensaje('E', resultado.valores.mensaje);
                            }
                        }
                    }
                    if (!this.tieneCargaArchivo || this.fileInput2.files.length <= 0 || codigoPaso != '3') {//Si contiene error asi contenga carga de archivo
                        this.loading = false;
                    }
                },
                error => {
                    this.loading = false;
                    this._servicioDatosGenerales.enviarMensaje('E', this._servicioDatosGenerales.getMensajeError());
                }
            )
    }

    realizarProcesamiento() {
        this.validarDatosCampos();
        if (this.formularioValido) {
            this.loading = true;
            let llamado = { "accion": "procesarFormulario", "parametros": { "formulario": this.idFormulario, "codigoSesion": this.codigoSesion, "region": "0", "campos": this.campos } };
            this._servicioPeticiones.enviarObjetoBus(llamado)
                .subscribe( //suscribe la peticion http para estar pendiente de la respuesta, funciona como un callback
                    response => { //si la peticion fue exitosa ejecuta la funcion response
                        let respuesta = response;
                        let codigoPaso: string;
                        let resultado: any;
                        for (let i = 0; i < respuesta.length; i++) {
                            resultado = respuesta[i];
                            codigoPaso = resultado.codigPaso;
                            if (codigoPaso == 'I') {//Inicial, proceso de validacion
                                if (resultado.valores.resultado != '0') {
                                    i = 3;
                                    this.obtenerMensajesErrorCampos(resultado.valores.datos.campos);
                                    this._servicioDatosGenerales.enviarMensaje('A', 'Hay campos del formulario que no se diligenciaron de manera adecuada');
                                }
                            } else if (codigoPaso == '2') {//Procesamiento
                                if (resultado.valores.tipo && resultado.valores.tipo != '') {
                                    let tipo = resultado.valores.tipo;
                                    let mensaje = resultado.valores.mensaje;
                                    this.mostrarMensajePersonalizado(tipo, mensaje);
                                } else {
                                    if (resultado.valores.resultado == 0) {//Si No obtiene problemas
                                        this._servicioDatosGenerales.enviarMensaje('C', resultado.valores.mensaje);
                                    } else {
                                        this._servicioDatosGenerales.enviarMensaje('E', resultado.valores.mensaje);
                                    }
                                }
                            } else if (codigoPaso == '3') {//Genera un Reporte cuando imprim sea sí
                                if (resultado.valores && resultado.valores.resultado == 0) {
                                    if (resultado.valores.ruta && resultado.valores.ruta != '') {
                                        let ruta = this._servicioDatosGenerales.getUrlBase() + resultado.valores.ruta;
                                        this.enviarPeticionReporte(ruta);
                                    } else {
                                        this._servicioDatosGenerales.enviarMensaje('E', 'No se encuentra reporte a generar');
                                    }
                                } else {
                                    this._servicioDatosGenerales.enviarMensaje('E', resultado.valores.mensaje ? resultado.valores.mensaje : 'No se puede generar reporte');
                                }
                            }
                        }
                        this.loading = false;
                    },
                    error => {
                        this.loading = false;
                        this._servicioDatosGenerales.enviarMensaje('E', this._servicioDatosGenerales.getMensajeError());
                    }
                )
        } else {
            this._servicioDatosGenerales.enviarMensaje('A', 'Hay campos del formulario que no se diligenciaron de manera adecuada');
        }
    }

    verificarPermisosEliminacion() {
        if (this.formulario.permisos.eliminar == 'S') {
            this.abrirDialogoEliminar = true;
        } else {
            this.dialogoAdicional = 'confirmacionEliminacionUsuario';
        }
    }

    //Función que asigna los mensajes de error encontrados en el formulario
    obtenerMensajesErrorCampos(campos: any) {
        for (let i = 0; this.formulario.regiones && i < this.formulario.regiones.length; i++) {
            for (let j = 0; this.formulario.regiones[i].divisiones && j < this.formulario.regiones[i].divisiones.length; j++) {
                for (let k = 0; this.formulario.regiones[i].divisiones[j].parametros && k < this.formulario.regiones[i].divisiones[j].parametros.length; k++) {
                    for (let l = 0; l < campos.length; l++) {
                        if (this.formulario.regiones[i].divisiones[j].parametros[k].nombre == campos[l].nombre) {
                            this.formulario.regiones[i].divisiones[j].parametros[k].mensaje = campos[l].observaciones;
                            l = campos.length;
                        }
                    }
                }
            }
        }
    }

    //En el caso que se esté editando un registro, toma los valores y los asocia a los campos,Además llena los valores de la estrutura del colbus y el identificador del registro padre
    //Si es utilizado en consulta generalizada , debe verificar las funciones de los campos(Llenar referidos, ejecutar recargas, etc)
    llenarDatosFormulario(campos: any, debeVerificaFuncionesCampos: boolean, debeVerificarReferidos: boolean) {
        if (campos && this.formulario.regiones) {
            for (let i = 0; i < this.formulario.regiones.length; i++) {
                for (let j = 0; this.formulario.regiones[i].divisiones && j < this.formulario.regiones[i].divisiones.length; j++) {
                    for (let k = 0; k < this.formulario.regiones[i].divisiones[j].parametros.length; k++) {
                        if (this.formulario.regiones[i].divisiones[j].parametros[k].tipoObjeto == 'J') {
                            this.formulario.regiones[i].divisiones[j].parametros[k].identificadorPadre = this.idRegistroFormulario;
                            if(this.tablasHijos){
                                for (let m = 0; m < this.tablasHijos.length; m++) {
                                    if (this.formulario.regiones[i].divisiones[j].parametros[k].nombre == this.tablasHijos[m].nombreEstructura) {
                                        this.formulario.regiones[i].divisiones[j].parametros[k].valoresEstructura = this.tablasHijos[m];
                                        // this.formulario.regiones[i].divisiones[j].parametros[k].identificadorPadre = this.jsonFormulario.tipo!='G' ? this.idRegistroFormulario: this.identificador;//En busqueda de registro
                                        m = this.tablasHijos.length; //Revisar si funciona bien
                                    }
                                }
                            }
                        } else {
                            for (let l = 0; l < campos.length; l++) {
                                if (this.formulario.regiones[i].divisiones[j].parametros[k].nombre == campos[l].nombre) {
                                    this.formulario.regiones[i].divisiones[j].parametros[k].valor = campos[l].valor;
                                    if (debeVerificaFuncionesCampos && this.formulario.campoProcesa != campos[l].nombre) {//Utilizado en consulta de formulario generalizada validando que no sea el mismo para no quedar en un ciclo
                                        this.verificarFuncionesCampo(this.formulario.regiones[i].divisiones[j].parametros[k]);
                                    }
                                    if (this.formulario.regiones[i].divisiones[j].parametros[k].tipoObjeto != '1' || (this.formulario.regiones[i].divisiones[j].parametros[k].tipoObjeto == '1' && this.formulario.regiones[i].divisiones[j].parametros[k].contenido != null)) {
                                        l = campos.length;
                                    }
                                } else if (this.formulario.regiones[i].divisiones[j].parametros[k].nombre + '_texto' == campos[l].nombre) {
                                    this.formulario.regiones[i].divisiones[j].parametros[k].contenido = campos[l].valor;
                                } else if (debeVerificarReferidos && this.formulario.regiones[i].divisiones[j].parametros[k].referidos && this.formulario.regiones[i].divisiones[j].parametros[k].referidos.length > 0) { // Esta operación se realizó ya que al guadar un nuevo registro, no estaba asociando el idetificador nuevo a los referidos //Liqudiacion de estampillas
                                    // se utiliza cuando se guarda un refistro desde cero
                                    let m: number = 0;
                                    while (m < this.formulario.regiones[i].divisiones[j].parametros[k].referidos.length) {
                                        if (campos[l].nombre == this.formulario.regiones[i].divisiones[j].parametros[k].referidos[m].nombre) {
                                            this.formulario.regiones[i].divisiones[j].parametros[k].referidos[m].valor = campos[l].valor;
                                            m = this.registroFormulario.campos.length;
                                        }
                                        m++;
                                    }
                                }
                                if (this.formulario.regiones[i].divisiones[j].parametros[k].inmodificable == 'S') {//Bloqueo los campos inmodificables
                                    this.formulario.regiones[i].divisiones[j].parametros[k].bloqueado = true;
                                }
                            }
                        }
                        if (this.accion == 'M' && this.formulario.tipo == 'A' && this.formulario.regiones[i].divisiones[j].parametros[k].nombre == this.formulario.campoPaso) {
                            this.paso = Number(this.formulario.regiones[i].divisiones[j].parametros[k].valor);
                            if (this.paso >= this.formulario.regiones.length) {
                                this.paso = 0;
                                this.ordenGrob = this.formulario.regiones[0].orden;
                            } else {
                                this.ordenGrob = this.formulario.regiones[i].orden;
                            }
                        }
                    }
                }
            }
        }
    }

    enviarPeticionConsultaReporte() {
        let ruta: string;
        this.tablaResultados = null;
        if (this.valorCampoAdicional && this.valorCampoAdicional.id) {//Consulta directa
            this.validarDatosCampos();
            if (this.formularioValido) {
                this.camposFiltros = null;
                this.llenarCamposBusqueda();
                if (this.valorCampoAdicional.id == 'P') {//pdf
                    ruta = this._servicioDatosGenerales.getUrlBase();//+this.formulario.parametrosReporte.salidaDocumento
                    let campo: any = this.tomarCampoFormulario(this.formulario.referidoReporte);
                    this.enviarPeticionConsultaReportePhp(campo, ruta);
                } else if (this.valorCampoAdicional.id == 'E') {//Excel
                    if (this.jsonFormulario.aexcel == 'S') {
                        ruta = this._servicioDatosGenerales.getUrlExcelConsulta();
                    } else if (this.jsonFormulario.aexcel == 'H') {//Con cabeceras
                        ruta = this._servicioDatosGenerales.getUrlExcelConsultaCabecera();
                    } else if (this.jsonFormulario.aexcel == 'E') {
                        ruta = this._servicioDatosGenerales.getUrlExcelConsultaMultihoja();
                    } else {
                        this._servicioDatosGenerales.enviarMensaje('E', 'Problemas en salida del reporte tipo excel');
                    }
                    // this.enviarPeticionReporte(ruta);
                    this.validarDatosReporteExcel(ruta);
                } else if (this.valorCampoAdicional.id == 'D') {//Consulta directa
                    this.cargarDatosBusqueda('1');
                } else if (this.valorCampoAdicional.id == 'A') {// Archivo plano
                    this.cargarDatosArchivoPlano();
                }
            } else {
                this._servicioDatosGenerales.enviarMensaje('A', 'Debe diligenciar los campos requeridos del formulario');
            }
        } else {
            this._servicioDatosGenerales.enviarMensaje('A', 'Debe seleccionar un tipo de salida');
        }
    }

    enviarPeticionConsultaReportePhp(campo: any, ruta: string) {
        let campoValor: string = campo ? campo.valor : null;
        this.loading = true;
        let llamado = { "accion": "obtenerRutaReporte", "parametros": { "codigoSesion": this.codigoSesion, "formulario": this.idFormulario, "valorReferido": campoValor, "campos": this.camposFiltros, "region": "0" } };
        this._servicioPeticiones.enviarObjetoBus(llamado)
            .subscribe( //suscribe la peticion http para estar pendiente de la respuesta, funciona como un callback
                response => { //si la peticion fue exitosa ejecuta la funcion response
                    let respuesta = response;
                    let codigoPaso: string;
                    let resultado: any;
                    for (let i = 0; i < respuesta.length; i++) {
                        resultado = respuesta[i];
                        codigoPaso = resultado.codigPaso;
                        if (codigoPaso == 'I') {//Inicial, proceso de validacion
                            // if(resultado.valores.resultado != '0'){
                            // i = 3;
                            //verificar validacion alerta
                            if (resultado.valores.datos) {
                                if (resultado.valores.datos.campos) {
                                    this.obtenerMensajesErrorCampos(resultado.valores.datos.campos);
                                } else if (resultado.valores.datos.procedimiento && resultado.valores.datos.procedimiento.mensaje != '') {
                                    let tipo = resultado.valores.datos.procedimiento.tipo;
                                    let mensaje = resultado.valores.datos.procedimiento.mensaje;
                                    if (tipo && tipo != '') {
                                        this.mostrarMensajePersonalizado(tipo, mensaje);
                                    } else {
                                        this._servicioDatosGenerales.enviarMensaje('E', resultado.valores.datos.procedimiento.mensaje);
                                    }
                                }
                            } else {
                                this._servicioDatosGenerales.enviarMensaje(resultado.valores.resultado == 1 ? 'E' : 'A', (resultado.valores.mensaje && resultado.valores.mensaje != '') ? resultado.valores.mensaje : 'Se encuentran errores en los datos del formulario ó de validación');
                            }
                            // }
                        } else if (codigoPaso == '2') {//Ruta reporte
                            if (resultado.valores.resultado == 0) {//Si No obtiene problemas al traer el menú
                                this.enviarPeticionReporte(ruta + resultado.valores.ruta);
                            } else {
                                this._servicioDatosGenerales.enviarMensaje('E', resultado.valores.mensaje);
                            }
                            this.loading = false;
                        }
                    }
                    this.loading = false;
                },
                error => {
                    this.loading = false;
                    this._servicioDatosGenerales.enviarMensaje('E', this._servicioDatosGenerales.getMensajeError());
                }
            )
    }

    validarDatosReporteExcel(ruta: string) {
        this.loading = true;
        let llamado = { accion: "validar", parametros: { "formulario": this.idFormulario, "region": "0", "codigoSesion": this.codigoSesion, "campos": this.camposFiltros } };
        this._servicioPeticiones.enviarObjetoBus(llamado)
            .subscribe( //suscribe la peticion http para estar pendiente de la respuesta, funciona como un callback
                response => { //si la peticion fue exitosa ejecuta la funcion response
                    let respuesta = response[0].valores;
                    this.loading = false;
                    if (respuesta && respuesta.resultado == 0) {//Si No obtiene problemas
                        this.enviarPeticionReporte(ruta);
                    } else {//Quiere decir que algun campo contiene errores
                        if (respuesta.datos) {
                            if (respuesta.datos.campos) {
                                this.obtenerMensajesErrorCampos(respuesta.datos.campos);
                            }
                            let tipo = respuesta.datos.procedimiento.tipo;
                            let mensaje = respuesta.datos.procedimiento.mensaje;
                            if (tipo && tipo != '') {
                                this.mostrarMensajePersonalizado(tipo, mensaje);
                            } else {
                                this._servicioDatosGenerales.enviarMensaje('A', 'Se encuentran errores en los datos del formulario ó de validación');
                            }
                        } else {
                            this._servicioDatosGenerales.enviarMensaje('A', 'Se encuentran errores en los datos del formulario ó de validación');
                        }
                    }
                },
                error => {
                    this._servicioDatosGenerales.enviarMensaje('E', this._servicioDatosGenerales.getMensajeError());
                    this.loading = false;
                }
            )
    }

    llenarCamposBusqueda() {
        let campo: any;
        this.camposFiltros = [];
        for (let i = 0; i < this.formulario.regiones.length; i++) {
            for (let j = 0; j < this.formulario.regiones[i].divisiones.length; j++) {
                for (let k = 0; k < this.formulario.regiones[i].divisiones[j].parametros.length; k++) {
                    campo = this.formulario.regiones[i].divisiones[j].parametros[k];
                    if (campo.valor != '') {
                        this.camposFiltros.push(new campoFormulario(campo.nombre, '', campo.valor));
                    }

                }
            }
        }
    }

    cambiarPagina(valor) {
        this.idRegistroPaginador = valor.first;
        let id = valor.page + 1;
        this.cargarDatosBusqueda(id);//true
    }

    enviarPeticionesPagina() {
        this.idRegistroPaginador = '0';
        this.cargarDatosBusqueda('1');
    }

    //Función que obtiene los datos de la busqueda directa
    cargarDatosBusqueda(pagina: string) {
        this.loading = true;
        let llamado = { "accion": "directa", "parametros": { "codigoSesion": this.codigoSesion, "formulario": this.idFormulario, "campos": this.camposFiltros, "pagina": pagina, "identificador": null, "identificadorPadre": null, "region": "0", "cantidadRegistros": this.cantidadRegistros.id } };
        this._servicioPeticiones.enviarObjetoBus(llamado)
            .subscribe( //suscribe la peticion http para estar pendiente de la respuesta, funciona como un callback
                response => { //si la peticion fue exitosa ejecuta la funcion response
                    let respuesta = response;
                    let codigoPaso: string;
                    let resultado: any;
                    for (let i = 0; i < respuesta.length; i++) {
                        resultado = respuesta[i];
                        codigoPaso = resultado.codigPaso;
                        if (codigoPaso == 'I') {//Inicial, proceso de validacion
                            if (resultado.valores.resultado != '0') {
                                i = 3;
                                //verificar validacion alerta
                                if (resultado.valores.datos) {
                                    if (resultado.valores.datos.campos) {
                                        this.obtenerMensajesErrorCampos(resultado.valores.datos.campos);
                                    } else if (resultado.valores.datos.procedimiento && resultado.valores.datos.procedimiento.mensaje != '') {
                                        if (resultado.valores.datos.procedimiento.tipo && resultado.valores.datos.procedimiento.tipo != '') {
                                            this.mostrarMensajePersonalizado(resultado.valores.datos.procedimiento.tipo, resultado.valores.datos.procedimiento.mensaje);
                                        } else {
                                            this._servicioDatosGenerales.enviarMensaje('A', resultado.valores.datos.procedimiento.mensaje);
                                        }
                                    }
                                } else if (resultado.valores.mensaje != '') {
                                    this._servicioDatosGenerales.enviarMensaje('E', resultado.valores.mensaje);
                                } else {
                                    this._servicioDatosGenerales.enviarMensaje('A', 'Se encuentran errores en los datos del formulario ó de validación');
                                }
                            }
                        } else if (codigoPaso == '2') {//Procesamiento
                            if (resultado.valores.resultado == 0) {//Si No obtiene problemas
                                this.tablaResultados = resultado.valores.tablaResultados;
                                let registros = resultado.valores.tablaResultados.registros;
                                if (registros == null) {
                                    this._servicioDatosGenerales.enviarMensaje('A', 'No se encontraron registros con los filtros indicados');
                                }
                            } else {
                                this.tablaResultados = null;
                                this._servicioDatosGenerales.enviarMensaje('E', resultado.valores.mensaje);
                            }
                        }
                    }
                    this.loading = false;
                },
                error => {
                    this.loading = false;
                    this._servicioDatosGenerales.enviarMensaje('E', this._servicioDatosGenerales.getMensajeError());
                }
            )
    }

    //Función que obtiene los datos de la busqueda directa
    cargarDatosArchivoPlano() {
        this.loading = true;
        let llamado = { "accion": "plano", "parametros": { "codigoSesion": this.codigoSesion, "formulario": this.idFormulario, "campos": this.camposFiltros, "region": "0", "delimitador": this.delimitador.id } };
        this._servicioPeticiones.enviarObjetoBus(llamado)
            .subscribe( //suscribe la peticion http para estar pendiente de la respuesta, funciona como un callback
                response => { //si la peticion fue exitosa ejecuta la funcion response
                    let respuesta = response;
                    let codigoPaso: string;
                    let resultado: any;
                    for (let i = 0; i < respuesta.length; i++) {
                        resultado = respuesta[i];
                        codigoPaso = resultado.codigPaso;
                        if (codigoPaso == 'I') {//Inicial, proceso de validacion
                            if (resultado.valores.resultado != '0') {
                                i = 3;
                                //verificar validacion alerta
                                if (resultado.valores.datos) {
                                    if (resultado.valores.datos.campos) {
                                        this.obtenerMensajesErrorCampos(resultado.valores.datos.campos);
                                    } else if (resultado.valores.datos.procedimiento && resultado.valores.datos.procedimiento.mensaje != '') {
                                        if (resultado.valores.datos.procedimiento.tipo && resultado.valores.datos.procedimiento.tipo != '') {
                                            this.mostrarMensajePersonalizado(resultado.valores.datos.procedimiento.tipo, resultado.valores.datos.procedimiento.mensaje);
                                        } else {
                                            this._servicioDatosGenerales.enviarMensaje('E', resultado.valores.datos.procedimiento.mensaje);
                                        }
                                    }
                                } else if (resultado.valores.mensaje != '') {
                                    this._servicioDatosGenerales.enviarMensaje('A', resultado.valores.mensaje);
                                } else {
                                    this._servicioDatosGenerales.enviarMensaje('A', 'Se encuentran errores en los datos del formulario ó de validación');
                                }
                            }
                        } else if (codigoPaso == '2') {//Procesamiento
                            if (resultado.valores.resultado == 0) {//Si No obtiene problemas
                                this.imprimirArchivoPlano(resultado.valores.tablaResultados);
                            } else {
                                this._servicioDatosGenerales.enviarMensaje('E', resultado.valores.mensaje);
                            }
                        }
                    }
                    this.loading = false;
                },
                error => {
                    console.log(error);
                    this.loading = false;
                    this._servicioDatosGenerales.enviarMensaje('E', this._servicioDatosGenerales.getMensajeError());
                }
            )
    }

    imprimirArchivoPlano(tablaResultados: any) {
        let datos: Array<any> = [];
        if (tablaResultados) {
            if (this.imprimeTitulos && tablaResultados.titulos && tablaResultados.titulos.length > 0) {
                datos.push(tablaResultados.titulos + '\n');
            }
            for (let i = 0; i < tablaResultados.registros.length; i++) {
                datos.push(tablaResultados.registros[i] + '\n');
            }
        }
        let blob = new Blob(datos, { type: "text/plain;charset=utf-8", endings: "native" });//Se pudo el endings para que tuviera en cuenta el formato windows al descargarse
        saveAs(blob, tablaResultados.nombreEstructura + ".txt");
    }

    eliminarRegistro() {
        this.eliminarReg.emit({ id: this.idRegistroFormulario, codigoAutorizacion: this.codigoAutorizacion });
    }

    enviarPeticionReporte(ruta: string) {
        console.log(ruta, this.rutaReporte, this.formulario.tipo, this.formulario.id)
        if (ruta) {
            let myForm = <HTMLFormElement>document.getElementById(`${this.formulario.id}00`);
            if (this.jsonFormulario.tipo == 'C' && this.idRegistroFormulario) {//LIQUIDACION
                ruta = ruta + this.rutaReporte;
            }
            myForm.action = ruta;
            myForm.submit();
        }
    }

    enviarPeticionReporteWizard(ruta: string) {
        if (ruta) {
            let myForm = <HTMLFormElement>document.getElementById(String(this.formulario.id) + String(this.paso));//Se hzo con el fin de no repetir ids de formularios en el renderizado
            myForm.action = ruta;
            myForm.submit();
        }
    }

    enviarPeticionReporteFormularioTexto() {
        if (this.jsonFormulario.salida) {
            let myForm = <HTMLFormElement>document.getElementById(this.formulario.id + '00');//Se hzo con el fin de no repetir ids de formularios en el renderizado
            let ruta = this._servicioDatosGenerales.getUrlBase();
            myForm.action = ruta + this.jsonFormulario.salida;
            myForm.submit();
        }
    }

    /**
     * Función que hace la petición a reportes para formularios tipo wizard, enviando solo los datos del paso actual
     */
    enviarPeticionReporteFormularioWizard() {
        if (this.jsonFormulario.salida) {
            console.log(String(this.formulario.id) + String(this.paso));
            let myForm = <HTMLFormElement>document.getElementById(String(this.formulario.id) + String(this.paso));//Se hzo con el fin de no repetir ids de formularios en el renderizado
            let ruta = this._servicioDatosGenerales.getUrlBase();
            myForm.action = ruta + this.jsonFormulario.salida;
            myForm.submit();
        }
    }

    //---------------Funciones de carga de archivos---------------------//
    cancelarArchivo() {
        if (this.fileInput.files.length > 0) {
            this.fileInput.files = [];
        }
        this.cargaDeshabilitado = false;
    }

    importarArchivoCargado() {
        this.tablaResultados = [];
        if (this.fileInput.files.length > 0) {
            let peso: number = this._servicioDatosGenerales.getIdentity().configuracion.tamañoArchivo;//Tomo el peso del archivo
            let pesoConv: number = peso * 1000000;//Hago la conversión de megas a bytes
            if (this.fileInput.files[0].size <= pesoConv) {//Valido que el peso sea menor o igual al permitido
                this.fileInput.upload();
            } else {
                this.cancelarArchivo();
                setTimeout(() => {
                    this._servicioDatosGenerales.enviarMensaje('A', 'El archivo seleccionado pesa más de lo permitido, el tamaño máximo es de ' + peso + ' Mb');
                    this.loading = false;
                }, 10);
            }
        } else {
            this._servicioDatosGenerales.enviarMensaje('A', 'Debe seleccionar por lo menos una archivo');
        }
    }

    //Función que arma la cabecera de la petición para enviar la información adicional
    onBeforeSend(event: any) {
        this.loading = true;
        event.formData.append('codigoSesion', this.codigoSesion);
        event.formData.append('formulario', this.formulario.id);
        event.formData.append('titulos', this.valorCampoAdicional);
        event.formData.append('campos', (JSON.stringify(this.campos)).toString());
    }

    //Función que se ejecuta al recibir la respuesta del backend
    onUploadFile(event: any) {
        let respuesta = (event.originalEvent.body);
        if (respuesta.resultado == 0) {
            this._servicioDatosGenerales.enviarMensaje('C', respuesta.mensaje);
        } else {
            this.tablaResultados = respuesta.erroresImportacion;
            this._servicioDatosGenerales.enviarMensaje('E', respuesta.mensaje);
        }
        let ruta = respuesta.reporteErrores ? respuesta.reporteErrores : null;
        if (ruta) {
            ruta = this._servicioDatosGenerales.getUrlBase() + ruta;
            this.enviarPeticionReporte(ruta);
        }
        this.cargaDeshabilitado = false;
        this.loading = false;
    }

    //---------------Funciones de carga de archivos desde campo---------------------//
    cancelarArchivoCampo() {
        if (this.fileInput2.files.length > 0) {
            this.fileInput2.files = [];
        }
        this.cargaDeshabilitado = false;
    }

    importarArchivoCargadoCampo() {
        let peso: number = this._servicioDatosGenerales.getIdentity().configuracion.tamañoArchivo;//Tomo el peso del archivo
        let pesoConv: number = peso * 1000000;//Hago la conversión de megas a bytes
        if (this.campoCarga.tipoObjeto != 'V') {
            if (this.fileInput2.files[0].size <= pesoConv) {//Valido que el peso sea menor o igual al permitido
                this.fileInput2.upload();
            } else {
                this.cancelarArchivoCampo();
                setTimeout(() => {
                    this._servicioDatosGenerales.enviarMensaje('A', 'El archivo seleccionado pesa más de lo permitido, el tamaño máximo es de ' + peso + ' Mb');
                    this.loading = false;
                }, 100);
            }
        } else { //Carga multiple, el hace la validación por longitud
            this.fileInput2.upload();
        }
    }

    //Función que arma la cabecera de la petición para enviar la información adicional
    antesEnvioCampo(event: any) {
        this.loading = true;
        event.formData.append('codigoSesion', this.codigoSesion);
        event.formData.append('codigoAutorizacion', this.codigoAutorizacion);
        event.formData.append('formulario', this.formulario.id);
        event.formData.append('identificador', this.idRegistroFormulario);
        event.formData.append('campo', this.campoCarga.nombre);
    }

    //Función que se ejecuta al recibir la respuesta del backend
    alCargarArchivoCampo(event: any) {
        let respuesta = (event.originalEvent.body);
        if (respuesta.resultado == 0) {
            this.ponerNombreArchivoCargado(respuesta.campo, respuesta.nombreArchivo);
            this._servicioDatosGenerales.enviarMensaje('C', 'Archivo cargado Correctamente');
            if (this.formulario.cerrarPersistir == 'S') {
                this.cerrarEstructuraFormulario();
            }
        } else {
            this.tablaResultados = respuesta.erroresImportacion;
            this._servicioDatosGenerales.enviarMensaje('E', respuesta.mensaje);
        }

        if (this.formulario.cerrarPersistir == 'S') {
            this.cerrarEstructuraFormulario();
        }
        this.loading = false;
        this.cargaDeshabilitado = false;
    }

    ponerMensajeErrorCarga() {
        this.loading = false;
        this.cargaDeshabilitado = false;
        this._servicioDatosGenerales.enviarMensaje('E', 'Ocurrió un error inesperado al cargar el archivo, por favor contáctese con el administrador del sistema');
    }

    bloquearCampoCarga() {
        this.cargaDeshabilitado = true;
    }

    ponerNombreArchivoCargado(campo: string, valor: string) {
        let encontro: boolean = false;
        let i = 0;
        while (!encontro && i < this.formulario.regiones.length) {
            let j = 0;
            while (!encontro && j < this.formulario.regiones[i].divisiones.length) {
                let k = 0;
                while (k < this.formulario.regiones[i].divisiones[j].parametros.length) {
                    if (this.formulario.regiones[i].divisiones[j].parametros[k].nombre == campo) {
                        this.formulario.regiones[i].divisiones[j].parametros[k].valor = valor;
                        if (this.formulario.regiones[i].divisiones[j].parametros[k].tipoObjeto == 'V') {//Archivos multiples
                            this.formulario.regiones[i].divisiones[j].parametros[k].contenido = this.formulario.regiones[i].divisiones[j].parametros[k].valor.split(",");
                        }
                        k = this.formulario.regiones[i].divisiones[j].parametros.length;
                        encontro = true;
                    }
                    k++
                }
                j++;
            }
            i++;
        }
    }

    //Función que toma los valores seleccionados de un colbus con ckecks y los asocia a los campos
    tomarValoresSeleccionados(valores: any) {
        let encontro: boolean = false;//Bandera para que no entre cuando ya encontró el campo
        let i = 0;
        while (!encontro && i < this.formulario.regiones.length) {
            let j = 0;
            while (!encontro && j < this.formulario.regiones[i].divisiones.length) {
                let k = 0;
                while (k < this.formulario.regiones[i].divisiones[j].parametros.length) {
                    if (this.formulario.regiones[i].divisiones[j].parametros[k].nombre == valores.campo) {
                        this.formulario.regiones[i].divisiones[j].parametros[k].valor = valores.seleccionados.toString();
                        this.verificarFuncionesCampo(this.formulario.regiones[i].divisiones[j].parametros[k]);
                        k = this.formulario.regiones[i].divisiones[j].parametros.length;
                        encontro = true;
                    }
                    k++
                }
                j++;
            }
            i++;
        }
    }

    enviarSenalRecargar(event) {
        this.enviarSenalRecargarFormulario.emit({ id: this.idRegistroFormulario, accion: event, editable: this.editable, eliminable: this.eliminable, tabAbierto: this.tabAbierto });
    }

    getUrlDescarga(url: string) {
        return this._servicioDatosGenerales.getUrlDescarga() + url;
    }

    tomarCampoFormulario(campoNombre: string) {
        let campo: any = null;
        if (this.camposPadre) {
            for (let i = 0; i < this.camposPadre.length; i++) {
                if (campoNombre == this.camposPadre[i].nombre) {
                    campo = this.camposPadre[i];
                    i = this.camposPadre.length;
                }
            }
        }
        if (campo == null) {
            for (let i = 0; i < this.formulario.regiones.length; i++) {
                for (let j = 0; j < this.formulario.regiones[i].divisiones.length; j++) {
                    for (let k = 0; k < this.formulario.regiones[i].divisiones[j].parametros.length; k++) {
                        if (this.formulario.regiones[i].divisiones[j].parametros[k].nombre == campoNombre) {
                            campo = this.formulario.regiones[i].divisiones[j].parametros[k];
                            k = this.formulario.regiones[i].divisiones[j].parametros[k].length;
                        }
                    }
                }
            }
        }
        return campo;
    }

    mostrarMensajePersonalizado(tipo: string, mensaje: string) {
        this._servicioDatosGenerales.mostrarMensajePersonalizado(tipo, mensaje);
    }

    abrirSiguientePaso(orden: string) {
        this.ordenGrob = orden;
        this.verificarOperacionRegistro();
    }

    abrirAnteriorPaso() {
        this.paso--;
        this.guardarInformacionPasoAtras();
    }

    /**
     * Realiza la operación de guardado cuado se da click en anterior en el wizard
     */
    guardarInformacionPasoAtras() {
        let idRegistroPadreEnviar: string;
        if (this.nombreCampoPrimario) {
            let campos: campoFormulario[] = [];
            campos.push(new campoFormulario(this.nombreCampoPrimario, '', this.idRegistroFormulario));
            campos.push(new campoFormulario(this.formulario.campoPaso, '', String(this.paso)));
            if (this.idRegistroPadre) {
                idRegistroPadreEnviar = this.jsonFormulario.lineal == 'S' ? this.idRegistroPadre : this._servicioOperaciones.tomarValorIdLineal(this.jsonFormulario.campoLineal, this.camposPadre);
            }

            this.loading = true;
            let llamado = { "accion": "adicionarActualizarWizard", "parametros": { "formulario": this.idFormulario, "codigoSesion": this.codigoSesion, "region": this.ordenGrob, "campos": campos, "identificadorPadre": idRegistroPadreEnviar } };
            this._servicioPeticiones.enviarObjetoBus(llamado)
                .subscribe( //suscribe la peticion http para estar pendiente de la respuesta, funciona como un callback
                    response => { //si la peticion fue exitosa ejecuta la funcion response
                        let respuesta = response;
                        let codigoPaso: string;
                        let resultado: any;
                        for (let i = 0; i < respuesta.length; i++) {
                            resultado = respuesta[i];
                            if (resultado.respuesta == 0) {
                                codigoPaso = resultado.codigPaso;
                                if (codigoPaso == 'I') {//Inicial, proceso de validacion
                                    if (resultado.valores.resultado == '0') {
                                        this.idRegistroFormulario = resultado.valores.identificadorRegistro;
                                    } else {
                                        i = 3;
                                        this._servicioDatosGenerales.enviarMensaje('E', resultado.valores.mensaje);
                                    }
                                } else if (codigoPaso == '2') {//Busqueda
                                    if (resultado.valores.resultado == '0') {
                                        this.registroFormulario = resultado.valores.registro;
                                        if (this.registroFormulario && this.registroFormulario != undefined) {
                                            this.tablasHijos = this.registroFormulario.datosHijos;
                                            this.llenarDatosFormulario(this.registroFormulario.campos, false, false);
                                        }
                                    } else {
                                        this._servicioDatosGenerales.enviarMensaje('E', resultado.valores.mensaje);
                                    }
                                }
                            } else {
                                this._servicioDatosGenerales.enviarMensaje('E', this._servicioDatosGenerales.getMensajeError());
                            }
                        }
                        this.loading = false;
                    },
                    error => {
                        this.loading = false;
                        this._servicioDatosGenerales.enviarMensaje('E', this._servicioDatosGenerales.getMensajeError());
                    }
                )
        }

    }

    cambiarPasoManualmente(event: any) {
    }
}
