import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';//Esto sirve para que al actualizar el navegador no salga eror 404
import { RouterModule, Routes } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';// poner si falla ReactiveFormsModule

import {servicioPeticiones} from './services/servicioPeticiones';
import {DatosGeneralesService} from './services/datosGenerales.service';
import {funcionesSesion} from './funcionesGenerales/funcionesSesion';
import {OperacionesService} from './services/operaciones.service'

import { AppComponent } from './app.component';
import { LoginComponent } from './component/login/login.component';
import { HomeComponent } from './component/home/home.component';
import { DashboardComponent } from './component/componentes-formulario/dashboard/dashboard.component';
import { MenuComponent } from './component/menu/menu/menu.component';
import { MenuItemComponent } from './component/menu/menu-item/menu-item.component';

// Componentes de prime
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message'
import {GrowlModule} from 'primeng/growl';
import {ButtonModule} from 'primeng/button';
import {InputTextModule} from 'primeng/inputtext';
import {CheckboxModule} from 'primeng/checkbox';
import {TabViewModule} from 'primeng/tabview';
import {ChartModule} from 'primeng/chart';
import {TableModule} from 'primeng/table';
import {TooltipModule} from 'primeng/tooltip';
import {PaginatorModule} from 'primeng/paginator';
import {PasswordModule} from 'primeng/password';
import {KeyFilterModule} from 'primeng/keyfilter';

import {AutoCompleteModule} from 'primeng/autocomplete';
import {CalendarModule} from 'primeng/calendar';
import {EditorModule} from 'primeng/editor';
import {SplitButtonModule} from 'primeng/splitbutton';
import {RadioButtonModule} from 'primeng/radiobutton';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {FileUploadModule} from 'primeng/fileupload';
import {DialogModule} from 'primeng/dialog';
import {InputMaskModule} from 'primeng/inputmask';
import { CurrencyMaskModule } from "ng2-currency-mask";

import {ScrollPanelModule} from 'primeng/scrollpanel';
import {TieredMenuModule} from 'primeng/tieredmenu';
import {MenuModule} from 'primeng/menu';
import {GalleriaModule} from 'primeng/galleria';
import {StepsModule} from 'primeng/steps';


import { SafePipe } from './pipes/safe.pipe'; 
import { StringCommasToArrayPipe } from './pipes/string-commas-to-array.pipe'; 

import { CamposFormularioComponent } from './component/componentes-formulario/campos-formulario/campos-formulario.component';
import { DireccionEstandarComponent } from './component/componentes-formulario/direccion-estandar/direccion-estandar.component';
import { FormularioEstructuraComponent } from './component/componentes-formulario/formulario-estructura/formulario-estructura.component';
import { FormularioTablaComponent } from './component/componentes-formulario/formulario-tabla/formulario-tabla.component';
import { VistaPreviaComponent } from './component/componentes-formulario/vista-previa/vista-previa.component';
import { ContenedorFormularioComponent } from './component/componentes-formulario/contenedor-formulario/contenedor-formulario.component';

import { UserIdleModule } from 'angular-user-idle';// Para detectar el tiempo de inactividad
import { ClipboardModule } from 'ngx-clipboard'; //Función para copiar en portapapeles
import { FormularioMultiregistroComponent } from './component/componentes-formulario/formulario-multiregistro/formulario-multiregistro.component';
import { CamposMultiRegistroComponent } from './component/componentes-formulario/campos-multi-registro/campos-multi-registro.component';
import { ListaAgregacionJsonComponent } from './component/componentes-formulario/lista-agregacion-json/lista-agregacion-json.component';
import { CamposJsonComponent } from './component/componentes-formulario/campos-json/campos-json.component';
import { FormularioConfirmacionUsuarioComponent } from './component/componentes-formulario/formulario-confirmacion-usuario/formulario-confirmacion-usuario.component';
import { FormularioBloqueoComponent } from './component/formulario-bloqueo/formulario-bloqueo.component';
import { DashboardTablaComponent } from './component/componentes-formulario/dashboard/dashboard-tabla/dashboard-tabla.component';
import { FormularioDialogoTablaComponent } from './component/componentes-formulario/formulario-dialogo-tabla/formulario-dialogo-tabla.component';
import {CaptchaModule} from 'primeng/captcha';
import { ReCaptchaModule } from 'angular2-recaptcha';
/*Configuración de las rutas */
const routes: Routes = [
    { path: '', component: LoginComponent},
    { path: 'login/:codigo', component: LoginComponent},
    { path: 'login/:codigo/:tipo', component: LoginComponent},
    { path: 'home', component: HomeComponent},
    { path: 'home/:estado', component: HomeComponent},
    { path: '**', component: LoginComponent}
  ]

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    DashboardComponent,
    MenuComponent,
    MenuItemComponent,
    CamposFormularioComponent,
    DireccionEstandarComponent,
    FormularioEstructuraComponent,
    FormularioTablaComponent,
    VistaPreviaComponent,
    ContenedorFormularioComponent,
    FormularioMultiregistroComponent,
    CamposMultiRegistroComponent,
    ListaAgregacionJsonComponent,
    CamposJsonComponent,
    FormularioConfirmacionUsuarioComponent,
    FormularioBloqueoComponent,
    DashboardTablaComponent,
    SafePipe,
    StringCommasToArrayPipe,
    FormularioDialogoTablaComponent
  ],
  imports: [
    RouterModule.forRoot(routes),
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,//Mirar si es necesario para los formularios
    HttpClientModule,
    MessagesModule,
    MessageModule,
    GrowlModule,
    ButtonModule,
    InputTextModule,
    CheckboxModule,
    TabViewModule,
    ChartModule,
    TableModule,
    TooltipModule,
    PaginatorModule,
    AutoCompleteModule,
    CalendarModule,
    EditorModule,
    SplitButtonModule,
    RadioButtonModule,
    InputTextareaModule,
    FileUploadModule,
    DialogModule,
    ClipboardModule,
    PasswordModule,
    KeyFilterModule,
    InputMaskModule,
    CurrencyMaskModule,
    ScrollPanelModule,
    TieredMenuModule,
    MenuModule,
    StepsModule,
    GalleriaModule,CaptchaModule,
    ReCaptchaModule,
    UserIdleModule.forRoot({})
  ],
  providers: [servicioPeticiones, DatosGeneralesService, funcionesSesion, OperacionesService,{provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }